import React, { Component } from "react";
import { State } from "../store";
import { connect } from "react-redux";
import { Grid, Typography, Button, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "./style.css";

interface LinksPageProps {}

const StyledButton = withStyles({
  root: {
    width: "45%",
    background: "#4769cc",
    borderRadius: "20px",
    border: 0,
    margin: "10px",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "50px",
    boxShadow: "0px 20px 20px 0px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      background: "#FFF",
      color: "#4769cc"
    },
    "&:focus": {
      background: "#FFF",
      color: "#4769cc"
    }
  },
  label: {
    textTransform: "capitalize"
  }
})(Button);

class LinksPage extends Component<LinksPageProps> {
  handleButtonClick(pezz, android, iOS, base, devices, website, backend) {
    if (android) window.location.replace("/dashboard/android");
    else if (pezz) window.location.replace("/dashboard/company");
    else if (iOS) window.location.replace("/dashboard/ios");
    else if (base) window.location.replace("/dashboard/base");
    else if (devices) window.location.replace("/dashboard/device");
    else if (website) window.location.replace("/dashboard/website");
    else if(backend) window.location.replace("/dashboard/backend");
    else window.location.replace("/dashboard/link");
  }

  render() {
    return (
      <Grid container direction="row">
        <Grid direction="column" className="buttonContainer">
          <Typography variant="h3" className="textStyle" gutterBottom>
            Pezz Dokumentation
          </Typography>
          <Typography variant="h5" className="textStyle" gutterBottom>
            Worüber möchtest du gerne mehr erfahren?
          </Typography>
          <Grid
            direction="row"
            justify="space-between"
            alignItems="stretch"
            className="buttonContainer"
          >
            <StyledButton
              onClick={() =>
                this.handleButtonClick(true, false, false, false, false, false, false)
              }
            >
              Pezz life
            </StyledButton>

            <StyledButton
              onClick={() =>
                this.handleButtonClick(false, true, false, false, false, false, false)
              }
            >
              Pezz Android
            </StyledButton>

            <StyledButton
              onClick={() =>
                this.handleButtonClick(false, false, true, false, false, false, false)
              }
            >
              Pezz iOS
            </StyledButton>
            <StyledButton
              onClick={() =>
                this.handleButtonClick(false, false, false, true, false, false, false)
              }
            >
              Pezz Base
            </StyledButton>
            <StyledButton
              onClick={() =>
                this.handleButtonClick(false, false, false, false, true, false, false)
              }
            >
              Pezz Devices
            </StyledButton>
            <StyledButton
              onClick={() =>
                this.handleButtonClick(false, false, false, false, false, true, false)
              }
            >
              Website Wordpress
            </StyledButton>
            <StyledButton
              onClick={() =>
                this.handleButtonClick(false, false, false, false, false, false, true)
              }
            >
              Pezz Backend
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    items: state.unfinishedItems,
    testValues: state.testValues,
    authToken: state.authToken
  };
};

export const LinksPageContainer = connect(mapStateToProps)(LinksPage);
