import React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles
} from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import Stepper from "@material-ui/core/Stepper";
import Hidden from "@material-ui/core/Hidden";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import {
  Slider,
  Grid,
  StepConnector,
  StepLabel,
  Paper,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { IconButton, FormControlLabel } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { StepIconProps } from "@material-ui/core/StepIcon";
import blood from "../../assets/ic_blood.svg";
import leucozytes from "../../assets/ic_leucocytes.svg";
import ketone from "../../assets/ic_ketone.svg";
import glucose from "../../assets/ic_glucose.svg";
import spezifics from "../../assets/ic_specific_gravity.svg";
import ph from "../../assets/ic_ph.svg";
import protein from "../../assets/ic_protein.svg";
import { removeUnfinisedItem } from "../../store";
import { ResultData, DBResult } from "../../data/Data";
import * as Helper from "./helper";
import { updateTestResult } from "../../api";
import leukocyteTop from "../../assets/leukocytes-top.png";
import leucozyteBottom from "../../assets/leukocytes-bottom.png";
import glucoseTop from "../../assets/glucose-top.png";
import glucoseBottom from "../../assets/glucose-bottom.png";
import specificsTop from "../../assets/specifics-top.png";
import specificsBottom from "../../assets/specifics-bottom.png";
import proteinTop from "../../assets/protein-top.png";
import proteinBottom from "../../assets/protein-bottom.png";
import hemoglobinTop from "../../assets/hemoglobin-top.png";
import hemoglobinBottom from "../../assets/hemoglobin-bottom.png";
import phTop from "../../assets/ph-top.png";
import phBottom from "../../assets/ph-bottom.png";
import ketoneTop from "../../assets/ketone-top.png";
import ketoneBottom from "../../assets/ketone-bottom.png";
import { setHistoryTestValueValue, setTestValueValue } from "../../store";
import "./style.css";
import {
  titleArray,
  leukocyteMarks,
  markArray,
  itemTitles,
  problemsArray
} from "../../data/Constants";
import { deleteItem } from "../../api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "auto"
    },
    button: {
      margin: 0
    },
    backButton: {
      margin: 0
    },
    completed: {
      display: "inline-block"
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  })
);

const theme = createMuiTheme({
  props: {
    MuiWithWidth: {
      // Initial width property
      initialWidth: "lg" // Breakpoint being globally set 🌎!
    }
  }
});

const PezzSlider = withStyles({
  root: {
    flexWrap: "nowrap",
    color: "#cecece",
    height: 5,
    width: "81%"
  },
  thumb: {
    flexWrap: "nowrap",
    [theme.breakpoints.down("sm")]: {
      height: 35,
      width: 35,
      marginTop: -14,
      marginLeft: -20,
      border: "1px"
    },
    [theme.breakpoints.up("md")]: {
      height: 50,
      width: 50,
      marginTop: -19,
      marginLeft: -25,
      border: "2px "
    },
    borderRadius: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit"
    },
    "& .bar": {
      height: "100%",
      width: "100%",
      backgroundColor: "green",
      color: "white",
      marginLeft: 1,
      marginRight: 1
    }
  },
  markLabel: {
    margin: "0",
    visibility: "hidden"
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  },
  MuiSlider: {
    markLabel: {
      margin: 0
    }
  }
})(Slider);

function getStepContent(step: number, preResults: ResultData) {
  const preR = [
    preResults.leukocyte.value,
    preResults.specifics.value,
    preResults.ph.value,
    preResults.protein.value,
    preResults.glucose.value,
    preResults.ketone.value,
    preResults.hemoglobin.value
  ];

  return (
    <Grid className="contentContainer">
      <Typography style={{ fontWeight: "bold" }} className="parameterText">
        {titleArray[step]}
      </Typography>
      <Typography className="resultText">
        Ergebnis Manuell: {results[step].parameterInterference === 2 ? "nicht benetzt - " : null} {results[step].parameterValueLabel} 
        <br />
        Ergebnis Auto: {preR[step]}
      </Typography>
    </Grid>
  );
}

const referencesTop = [
  leukocyteTop,
  specificsTop,
  phTop,
  proteinTop,
  glucoseTop,
  ketoneTop,
  hemoglobinTop
];

const referencesBottom = [
  leucozyteBottom,
  specificsBottom,
  phBottom,
  proteinBottom,
  glucoseBottom,
  ketoneBottom,
  hemoglobinBottom
];

const BlueCheckbox = withStyles({
  root: {
    color: "#4769cc",
    
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 18
  },
  active: {
    backgroundImage: "rgba(206, 206, 206, 0.2)"
  },
  completed: {
    backgroundImage: "rgba(206, 206, 206, 0.2)"
  },
  line: {
    height: 4,
    border: 0,
    backgroundColor: "rgba(206, 206, 206, 0.2)",
    borderRadius: 1
  }
})(StepConnector);

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <img src={leucozytes} alt="leucozytes" width="20px" height="20px" />,
    2: <img src={spezifics} alt="spezifics" width="20px" height="20px" />,
    3: <img src={ph} alt="ph" width="20px" height="20px" />,
    4: <img src={protein} alt="protein" width="20px" height="20px" />,
    5: <img src={glucose} alt="glucose" width="20px" height="20px" />,
    6: <img src={ketone} alt="ketone" width="20px" height="20px" />,
    7: <img src={blood} alt="blood" width="20px" height="20px" />
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const useColorlibStepIconStyles = makeStyles({
  root: {
    display: "flex",
    backgroundColor: "#B3B3B3",
    color: "#fff",
    width: 35,
    height: 35,
    zIndex: 1,
    flexWrap: "nowrap",
    borderRadius: "20%",
    justifyContent: "center",
    alignItems: "center"
  },
  active: {
    width: 50,
    height: 50,
    backgroundImage: "#B3B3B3",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
  },
  completed: {
    backgroundColor: "#4769cc"
  }
});

interface Result {
  parameterValueIndex: number;
  parameterValueLabel: string;
  parameterValueCode: string;
  parameterInterference: number;
}

const results: Result[] = [
  {
    parameterValueIndex: 0,
    parameterValueLabel: "",
    parameterValueCode: "",
    parameterInterference: 1
  },
  {
    parameterValueIndex: 0,
    parameterValueLabel: "",
    parameterValueCode: "",
    parameterInterference: 1
  },
  {
    parameterValueIndex: 0,
    parameterValueLabel: "",
    parameterValueCode: "",
    parameterInterference: 1
  },
  {
    parameterValueIndex: 0,
    parameterValueLabel: "",
    parameterValueCode: "",
    parameterInterference: 1
  },
  {
    parameterValueIndex: 0,
    parameterValueLabel: "",
    parameterValueCode: "",
    parameterInterference: 1
  },
  {
    parameterValueIndex: 0,
    parameterValueLabel: "",
    parameterValueCode: "",
    parameterInterference: 1
  },
  {
    parameterValueIndex: 0,
    parameterValueLabel: "",
    parameterValueCode: "",
    parameterInterference: 1
  }
];

interface StepperProps {
  props: any;
  preResults: ResultData;
  unfinishedItem: boolean;
  id: number;
  image: string;
  onDeleteClick: (
    event: React.MouseEvent<unknown>,
    id: number
  ) => void;
}

export default function HorizontalNonLinearAlternativeLabelStepper(
  props: StepperProps
) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [marks, setMarks] = React.useState(leukocyteMarks);
  const [activeButton, setButtonActive] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [completed, setCompleted] = React.useState(new Set<number>());
  const [currentParameterValue, setParameterValue] = React.useState(0);
  const [testProblemCheckbox, setTestProblemCheckbox] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const helper = new Helper.Helper();
  const steps = helper.getSteps();

  const pezzSliderThumbComponent = (sliderProps: any) => {
    let croppedProcessedImage = helper.getProcessedCroppedImageUrl(activeStep, props.image);

    return (
      <span {...sliderProps}>
        <img
          className="imageCrop"
          src={croppedProcessedImage}
          alt="Cropped"
          width="100%"
          height="100%"
        ></img>
      </span>
    );
  };

  const totalSteps = () => {
    return helper.getSteps().length;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const setParameterMarks = sliderPosition => {
    setMarks(markArray[sliderPosition]);
  };

  const setProblemCheck = sliderPosition => {
    if(problemsArray[sliderPosition] === 1) setTestProblemCheckbox(false)
    else setTestProblemCheckbox(true)
  }

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    setActiveStep(newActiveStep);
    setParameterMarks(newActiveStep);
    setProblemCheck(newActiveStep);
    handleStepperUI(newActiveStep);
  };

  const handleBack = () => {
    const newActiveStep = activeStep - 1;
    setActiveStep(newActiveStep);
    setParameterMarks(newActiveStep);
    setProblemCheck(newActiveStep);
    handleStepperUI(newActiveStep);
  };

  const handleReset = () => {
    results.map((result, index) =>
      results.splice(index, 1, {
        parameterValueIndex: 0,
        parameterValueLabel: "",
        parameterValueCode: "",
        parameterInterference: 1
      })
    );
    setCompleted(new Set<number>());
    setActiveStep(0);
    setParameterMarks(0);
    handleStepperUI(0);
    setTestProblemCheckbox(false);
  };

  const handleComplete = () => {
    if (currentParameterValue !== 0 || testProblemCheckbox) {
      const newCompleted = new Set(completed);
      newCompleted.add(activeStep);
      setCompleted(newCompleted);

      //save parameter value in result array
      let result: Result = helper.getResult(currentParameterValue, testProblemCheckbox, activeStep);
      
      results.splice(activeStep, 1, result);

      const { dispatch } = props.props;
      if (props.unfinishedItem) {
        dispatch(
          setHistoryTestValueValue(
            props.id,
            itemTitles[activeStep],
            result.parameterValueLabel
          )
        );
      } else {
        dispatch(
          setTestValueValue(
            props.id,
            itemTitles[activeStep],
            result.parameterValueLabel
          )
        );
      }

      if (completed.size !== totalSteps()) {
        handleNext();
      }
    } else {
      setButtonActive(false);
    }
  };

  /* Test Problem */
  const handleTestProblemChange = (event) => {
    setTestProblemCheckbox(event.target.checked);
    if (event.target.checked || results[activeStep].parameterValueIndex !== 0) setButtonActive(true);
    else setButtonActive(false);
  };

  /* hanlde demo result - delete it */
  const handleDemoResult = (event) => {
    console.log("delete item - handleDemoResult");
    //delete demo result -> show warning dialog 
    setShowDeleteDialog(true);
  };

  const handleCloseDialog = () => {
    console.log("delete item - closeDialog");
    setShowDeleteDialog(false);
  }

  const handleDeleteClicked = (event: React.MouseEvent<unknown>, id: number) => {
    console.log("delete item - handleDeleteClicked");
    props.onDeleteClick(event, id);
  }

  const handleSendResult = () => {
    setOpen(true);
    handleSaveCheck(props.id);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
    setParameterMarks(step);
    setProblemCheck(step);
    handleStepperUI(step);
  };

  const handleStepperUI = (step: number) => {
    if (results[step].parameterValueIndex !== 0 || results[step].parameterInterference !== 1) {
      setParameterValue(results[step].parameterValueIndex);
      setTestProblemCheckbox(results[step].parameterInterference !== 1);
    } else {
      setParameterValue(0);
      setTestProblemCheckbox(false);
      setButtonActive(false);
    }
  };

  const getRightOrder = () => {
    let endResult: DBResult = {
      hemoglobin: results[6].parameterValueCode,
      hemoglobin_interference: results[6].parameterInterference,
      glucose: results[4].parameterValueCode,
      glucose_interference: results[4].parameterInterference,
      ph: results[2].parameterValueCode,
      ph_interference: results[2].parameterInterference,
      ketone: results[5].parameterValueCode,
      ketone_interference: results[5].parameterInterference,
      protein: results[3].parameterValueCode,
      protein_interference: results[3].parameterInterference,
      specifics: results[1].parameterValueCode,
      specifics_interference: results[1].parameterInterference,
      leukocyte: results[0].parameterValueCode,
      leukocyte_interference: results[0].parameterInterference
    };
    return endResult;
  };

  const handleSliderChange = (event, value) => {
    setParameterValue(value);
    if (value !== 0) setButtonActive(true);
    else setButtonActive(false);
  };

  const handleSaveCheck = (id: number) => {
    let endResult: DBResult = getRightOrder();

    //save to DB
    const { dispatch } = props.props;
    if (endResult) {
      updateTestResult(id, endResult)
        .then(response => {
          dispatch(removeUnfinisedItem(id));
        })
        .then(() => {
          console.log("Success removeUnfinisedItem ");
          //Link to detail page
          window.location.replace("/dashboard/detail/" + id);
        })
        .catch(error => {
          console.log("error while saving item ", error);
        });
    } else console.log("endresult is empty: ", endResult);
    setOpen(false);
  };

  function isStepComplete(step: number) {
    return completed.has(step);
  }

  return (
    <div className={classes.root}>
      {!allStepsCompleted() ? (
        <Hidden smDown>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepButton
                    onClick={handleStep(index)}
                    completed={isStepComplete(index)}
                  >
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}{<br />}
                      {results[index].parameterValueLabel}{<br />}
                      {results[index].parameterInterference === 2 ? "nicht benetzt" : ""}
                    </StepLabel>
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
        </Hidden>
      ) : null}
      <div>
        {allStepsCompleted() ? (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Paper
              className="paper"
              style={{
                backgroundColor: "#FFF",
                color: "#FFFFFF",
                fontWeight: "bold"
              }}
            >
              <Table className="completedTable">
                <TableHead>
                  <TableRow className="tableHeader">
                    <TableCell>Parameter</TableCell>
                    <TableCell>Auswertungsparameter</TableCell>
                    <TableCell align="center">Störfaktor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="tableBody">
                  {results.map((result, index) => (
                    <TableRow key={titleArray[index]}>
                      <TableCell component="th" scope="row" >{titleArray[index]}</TableCell>
                      <TableCell align="center">{result.parameterValueLabel}</TableCell>
                      <TableCell align="center"> {results[index].parameterInterference === 2 ? "nicht benetzt" : ""} </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>            
            </Paper>
          </Grid>
        ) : (
          <div>
            <div>
              {/* show if screen size is smaller than medium */}
              <Hidden mdUp>
                <Grid
                  container
                  direction="column"
                  justify="space-between"
                  alignItems="center"
                  wrap="nowrap"
                  className="completeSliderContainer"
                >
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    style={{ marginBottom: "10px" }}
                    alignItems="center"
                  >
                    <IconButton
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      <ArrowBackIosRoundedIcon fontSize="large" />
                    </IconButton>
                    <Grid item>
                      <Grid item justify="flex-start" style={{ fontSize: "12px" }}>
                        <Typography className={classes.instructions} component={'span'} >
                          {getStepContent(activeStep, props.preResults)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <IconButton
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      <ArrowForwardIosRoundedIcon fontSize="large" />
                    </IconButton>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className="sliderContainer"
                  >
                    <img
                      src={referencesTop[activeStep]}
                      width="100%"
                      height="auto"
                      alt="Active Step - reference Top"
                    />
                    <PezzSlider
                      defaultValue={0}
                      step={1}
                      value={currentParameterValue}
                      valueLabelDisplay="off"
                      onChange={handleSliderChange}
                      marks={marks}
                      max={marks.length - 1}
                      ThumbComponent={pezzSliderThumbComponent}
                    />
                    <img
                      src={referencesBottom[activeStep]}
                      width="100%"
                      height="auto"
                      alt="Active Step - reference Bottom"
                    />
                  </Grid>
                </Grid>
              </Hidden>

              {/* show if screen size is bigger than small */}
              <Hidden smDown>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  wrap="nowrap"
                  className="completeSliderContainer"
                >
                  <IconButton
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    <ArrowBackIosRoundedIcon fontSize="large" />
                  </IconButton>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className="sliderContainer"
                  >
                    <img
                      src={referencesTop[activeStep]}
                      width="100%"
                      height="auto"
                      alt="Reference Top"
                    />
                    <PezzSlider
                      defaultValue={0}
                      step={1}
                      value={currentParameterValue}
                      valueLabelDisplay="off"
                      onChange={handleSliderChange}
                      marks={marks}
                      max={marks.length - 1}
                      ThumbComponent={pezzSliderThumbComponent}
                    />
                    <img
                      src={referencesBottom[activeStep]}
                      width="100%"
                      height="auto"
                      alt="Reference Bottom"
                    />
                  </Grid>
                  <Typography className={classes.instructions} component={'span'} >
                    {getStepContent(activeStep, props.preResults)}
                  </Typography>
                  <IconButton
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    <ArrowForwardIosRoundedIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </Hidden>
            </div>
          </div>
        )}
      </div>
      <Grid container direction="row" alignItems="center" justify="flex-end">
        {allStepsCompleted() ? (
          <Grid>
            <Button
              variant="contained"
              style={{ margin: "15px" }}
              color="primary"
              onClick={handleReset}
            >
              Zurücksetzten
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "15px" }}
              onClick={handleSendResult}
              disabled={!activeButton}
            >
              Ergebnis senden
            </Button>
            <Backdrop open={open}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center" >
            <FormControlLabel
              style={{ margin: "15px" }}
              control={
              <BlueCheckbox 
                checked={testProblemCheckbox} 
                onChange={handleTestProblemChange} 
                name="notWetted" 
              />}
              label="nicht benetzt"
            />
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "15px" }}
              onClick={handleComplete}
              disabled={!activeButton}
            >
              Speichern
            </Button>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center" >
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "15px" }}
                onClick={handleDemoResult}
              >
                <DeleteIcon /> Demo Ergebnis
              </Button>
            </Grid>
          </Grid>
          
        )}
      </Grid>
      <Dialog
            open={showDeleteDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Möchtest du den Test wirklich löschen?"}
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                NEIN
              </Button>
              <Button
                onClick={(event) => handleDeleteClicked (event, props.id)}
                color="primary"
                autoFocus
              >
                JA
              </Button>
            </DialogActions>
          </Dialog>
    </div>
  );
}
