import React, { Component } from "react";
import { State } from "../store";
import { connect } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import "./style.css";

interface ImpressumPageProps {}

class ImpressumPage extends Component<ImpressumPageProps> {
  render() {
    return (
      <Grid>
        <Typography variant="h3" className="textStyle" gutterBottom>
          Impressum
        </Typography>
        <Grid>
          <p style={{fontWeight: "bold"}}>Impressum ﻿www.pezz.life | Stand: 30.11.2018 Angaben gemäß § 5 TMG</p>
          <br />
          Pezz life GmbH
          <br />
          Heiligenstädter Straße 31
          <br />
          Stiege 1, 4. OG
          <br />
          1190 Wien
          <br />
          <br />
          Vertreten durch
          <br />
          Markus Zengerer
          <br />
          Max Melcher
          <br />
          <br />
          Kontakt E-Mail: impressum@pezz.life
          <br />
          Internetadresse: www.pezz.life
          <br />
          Registereintrag Gesellschaftsform: Gesellschaft mit beschränkter
          Haftung
          <br />
          Firmenbuchnummer: FN 475838 m<br />
          UID – Nummer: ATU72494605
          <br />
          Gerichtsstand: Handelsgericht Wien
          <br />
          <Typography
            variant="h5"
            className="textStyle"
            style={{ fontWeight: "bold", marginTop: "20px" }}
          >
            Haftungsausschluss
          </Typography>
          <Typography
            variant="h6"
            className="textStyle"
            style={{ fontWeight: "bold", marginTop: "20px" }}
          >
            Haftung für Inhalte
          </Typography>
          Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für
          die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir
          jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7
          Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
          Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
          Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
          gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
          forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.
          <br />
          <Typography
            variant="h6"
            className="textStyle"
            style={{ fontWeight: "bold", marginTop: "20px" }}
          >
            Haftung für Links
          </Typography>
          <br />
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
          permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
          konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
          <Typography
            variant="h6"
            className="textStyle"
            style={{ fontWeight: "bold", marginTop: "20px" }}
          >
            Urheberrecht
          </Typography>
          <p>
            Die durch die Seitenbetreiber erstellten bzw. verwendeten Inhalte
            und Werke auf diesen Seiten unterliegen dem österreichischen
            Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und
            jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
            bedürfen der Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
            Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
            Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
            gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
            werden wir derartige Inhalte umgehend entfernen.
          </p>
        </Grid>
      </Grid>
    );
  }
}

export const ImpressumPageContainer = ImpressumPage;
