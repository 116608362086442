import * as firebase from "firebase";
import { Breed } from "../../data/Data";

class Breeds {
  // Get all new Breeds
  async getAllBreeds() {
    var db = firebase.firestore();
    let breeds: Breed[] = [];

    await db
      .collection("breeds")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const id = doc.id;
          const breedname = doc.get("breedname");
          const created = doc.get("date")
          const dogName = doc.get("dogName");
          const pushChannel = doc.get("pushChannel");
          const userId = doc.get("userId");

          const date = (created.seconds + (created.nanoseconds/1000000000)*1000);

          breeds.push({
            id,
            breedname,
            date,
            dogName,
            pushChannel,
            userId
          });
        });
      })
      .catch(error => console.log("error: ", error));

    return breeds;
  }

  async deleteBreed(id) {
    var db = firebase.firestore();

    await db.collection("breeds")
      .doc(id)
      .delete()
      .then(function() {
        console.log("Document successfully deleted!");
      })
      .catch(function(error) {
        console.error("Error removing document: ", error);
      });
  }
}

export default new Breeds();
