export const Constants = {
  API: process.env.API_URL || process.env.REACT_APP_API_URL,
  QUERY_DEVELOP: "/mode/develop?",
  QUERY_TESTVALUES: "/testvalues",
  QUERY_TEST: "/test/",
  QUERY_UNFINISHED: "/unfinished",
  QUERY_ANAMNESIS: "/anamnesis/",
  QUERY_HISTORY_TEST: "/history/test/",
  QUERY_ALL_ITEMS: "/alltests",
  QUERY_SPECIFIC_ITEM: "/test/",

  AUTH_API: process.env.GATEKEEPER_URL || process.env.REACT_APP_GATEKEEPER_URL,
  AUTH_QUERY_TOKEN: "/oauth/token",
  AUTH_SCOPE:
    process.env.GATEKEEPER_SCOPE ||
    process.env.REACT_APP_GATEKEEPER_SCOPE ||
    "",
  AUTH_CLIENT_ID:
    process.env.GATEKEEPER_CLIENT_ID ||
    process.env.REACT_APP_GATEKEEPER_CLIENT_ID ||
    "",
  AUTH_CLIENT_SECRET:
    process.env.GATEKEEPER_CLIENT_SECRET ||
    process.env.REACT_APP_GATEKEEPER_CLIENT_SECRET ||
    "",
  STORAGE_AUTH_TOKEN_ACCESS: "STORAGE_AUTH_TOKEN_ACCESS",
  STORAGE_AUTH_TOKEN_REFRESH: "STORAGE_AUTH_TOKEN_REFRESH"
};

export const itemTitles: Map<string, string> = new Map<string, string>()
  .set("leukocyte", "Leukocyte")
  .set("specifics", "Specifics")
  .set("ph", "pH")
  .set("protein", "Protein")
  .set("glucose", "Glucose")
  .set("ketone", "Ketone")
  .set("hemoglobin", "Hemoglobin");

// Constants for ParameterDetailComponent
export const titleArray = [
  "Leukozyten: ",
  "spez. Gewicht: ",
  "Ph-Wert: ",
  "Protein: ",
  "Glukose: ",
  "Ketone: ",
  "Hemoglobin: "
];

export const parameterId = [0, 1, 2, 3, 4, 5, 6];

export const parameterArray = [
  "(leu/µl)",
  "",
  "(leu/µl)",
  "(mg/dl)",
  "(mg/dl)",
  "(mg/dl)",
  "(Ery/µl): "
];

export const analysisValueArray = [
  "negativ/ 10-25/ 75/ 500",
  "1.000/ 1.005/ 1.010/ 1.015/ 1.020/ 1.025/ 1.030",
  "5/6/7/8/9",
  "negativ/ 30/ 100/ 500",
  "negativ/ 50/ 100/ 300/ 1000",
  "16/ 52/ 156",
  "negativ/trace/ca. 10/ca. 50/ ca. 250"
];

export const normalValueArray = [
  "negativ - 25",
  "1.010 - 1.030",
  "5 - 7",
  "negativ - 30",
  "negativ",
  "negativ",
  "negativ"
];

export const iconArray = [
  "../../assets/ic_leucocytes.svg",
  "../../assets/ic_specific_gravity.svg",
  "../../assets/ic_ph.svg",
  "../../assets/ic_protein.svg",
  "../../assets/ic_glucose.svg",
  "../../assets/ic_ketone.svg",
  "../../assets/ic_blood.svg"
];

export const leukocyteMarks = [
  {
    value: 0,
    label: "",
    code: ""
  },
  {
    value: 1,
    label: "negativ",
    code: "00"
  },
  {
    value: 2,
    label: "10-25",
    code: "01"
  },
  {
    value: 3,
    label: "75",
    code: "02"
  },
  {
    value: 4,
    label: "500",
    code: "03"
  }
];

export const specificsMarks = [
  {
    value: 0,
    label: "",
    code: ""
  },
  {
    value: 1,
    label: "1.000",
    code: "10"
  },
  {
    value: 2,
    label: "1.005",
    code: "11"
  },
  {
    value: 3,
    label: "1.010",
    code: "12"
  },
  {
    value: 4,
    label: "1.015",
    code: "13"
  },
  {
    value: 5,
    label: "1.020",
    code: "14"
  },
  {
    value: 6,
    label: "1.025",
    code: "15"
  },
  {
    value: 7,
    label: "1.030",
    code: "16"
  }
];

export const phMarks = [
  {
    value: 0,
    label: "",
    code: ""
  },
  {
    value: 1,
    label: "5",
    code: "20"
  },
  {
    value: 2,
    label: "6",
    code: "21"
  },
  {
    value: 3,
    label: "7",
    code: "22"
  },
  {
    value: 4,
    label: "8",
    code: "23"
  },
  {
    value: 5,
    label: "9",
    code: "24"
  }
];

export const proteinMarks = [
  {
    value: 0,
    label: "",
    code: ""
  },
  {
    value: 1,
    label: "negativ",
    code: "30"
  },
  {
    value: 2,
    label: "30",
    code: "31"
  },
  {
    value: 3,
    label: "100",
    code: "32"
  },
  {
    value: 4,
    label: "500",
    code: "33"
  }
];

export const glucoseMarks = [
  {
    value: 0,
    label: "",
    code: ""
  },
  {
    value: 1,
    label: "negativ",
    code: "40"
  },
  {
    value: 2,
    label: "50",
    code: "41"
  },
  {
    value: 3,
    label: "100",
    code: "42"
  },
  {
    value: 4,
    label: "300",
    code: "43"
  },
  {
    value: 5,
    label: "1000",
    code: "44"
  }
];

export const ketoneMarks = [
  {
    value: 0,
    label: "",
    code: ""
  },
  {
    value: 1,
    label: "negativ",
    code: "50"
  },
  {
    value: 2,
    label: "16",
    code: "51"
  },
  {
    value: 3,
    label: "52",
    code: "52"
  },
  {
    value: 4,
    label: "156",
    code: "53"
  }
];

export const hemoglobinMarks = [
  {
    value: 0,
    label: "",
    code: ""
  },
  {
    value: 1,
    label: "negativ",
    code: "60"
  },
  {
    value: 5,
    label: "trace",
    code: "67"
  },
  {
    value: 2,
    label: "ca. 10",
    code: "64"
  },
  {
    value: 3,
    label: "ca. 50",
    code: "65"
  },
  {
    value: 4,
    label: "ca. 250",
    code: "66"
  }
];

export const markArray = [
  leukocyteMarks,
  specificsMarks,
  phMarks,
  proteinMarks,
  glucoseMarks,
  ketoneMarks,
  hemoglobinMarks
];

export const problemsArray = [ 1,1,1,1,1,1,1 ]

export const anamnesisQuestionsDog = [
  "Tier hat Verhalten geändert: ",
  "Tier trinkt mehr als üblich: ",
  "Tier trinkt weniger als üblich: ",
  "Tier setzt häufiger Harn ab:  ",
  "Tier setzt mehr Harn ab: ",
  "Harn des Tieres ist dunkler: ",
  "Tier frisst weniger: ",
  "Tier hat Gewicht verloren: "
];

export const anamnesisQuestionsCat = [
  "Tier hat Verhalten geändert: ",
  "Tier trinkt mehr als üblich: ",
  "Tier trinkt weniger als üblich: ",
  "Tier setzt häufiger Harn ab:  ",
  "Tier setzt mehr Harn ab: ",
  "Harn des Tieres ist dunkler: ",
  "Tier frisst weniger: ",
  "Tier hat Gewicht verloren: ",
  "Tier schleckt Bauch kahl: "
];

export const imageLibraryNames = [
  "4b467690-97e8-458a-b12d-401392ef0a82/classify/iterations/leu_v1/url",
  "cca1693e-744f-4f47-821a-32831ff2ec65/classify/iterations/sg_v1/url",
  "690efc2b-c255-48ac-b55e-c7fadbc00d4a/classify/iterations/ph_v1/url",
  "0b2ff691-1636-4e59-a0ab-2b81552a82b2/classify/iterations/pro_v1/url",
  "d2d9fcd2-54c0-45af-aa07-fea8908e27dc/classify/iterations/glu_v1/url",
  "2cd3bcec-6b96-49b7-ba5b-03a0edc02037/classify/iterations/ket_v1/url",
  "1dcf33e4-f099-4781-8f1f-7982c776f568/classify/iterations/hem_v1/url"
];

export const trashholdValue = [
  0.97,
  0.95,
  0.68,
  0.96,
  0.90,
  0.92,
  0.94
];