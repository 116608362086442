import React, { Component } from "react";
import "./style.css";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  withStyles,
  Grid
} from "@material-ui/core";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import BarChartRoundedIcon from "@material-ui/icons/BarChartRounded";

interface StatisticCardProps {
  card: number;
  smallHeader: string;
  header: string;
  buttonText: string;
  buttonLink: string;
  smallText: string;
  checks: number;
}

const StyledButtonOne = withStyles({
  root: {
    background: "#e6eeff",
    borderRadius: "22px",
    border: 0,
    color: "#21305e",
    padding: "5px 30px",
    boxShadow:
      "0px 1px 16px 0px rgba(0, 0, 0, 0.1), 0px 2px 5px 0px rgba(0, 0, 0, 0.07)"
  },
  label: {
    textTransform: "capitalize"
  }
})(Button);

const StyledButtonTwo = withStyles({
  root: {
    background: "#e6eeff"
  }
})(StyledButtonOne);

const StyledButtonThree = withStyles({
  root: {
    background: "#e6eeff"
  }
})(StyledButtonOne);

const CardContentOne = withStyles({
  root: {
    background: "#7e98e6",
    color: "#fff"
  }
})(CardContent);

const CardContentTwo = withStyles({
  root: {
    background: "#4769cc"
  }
})(CardContent);

const CardContentThree = withStyles({
  root: {
    background: "#21305e",
    color: "#fff"
  }
})(CardContent);

class StatisticCardComponent extends Component<StatisticCardProps> {
  state = {
    week: Date.now
  };

  handleButtonOneClick = () => {
    window.location.replace("/dashboard/history");
  };
  
  handleButtonTwoClick = () => {
    window.location.replace("/dashboard/statistics");
  };

  handleButtonThreeClick = () => {
    window.location.replace("/dashboard/links");
  };

  render() {
    let cardToRender;

    const CardOne = (
      <CardContentOne className="cardOne">
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="baseline"
          >
            <Typography
              className="smallHeader"
              color="textSecondary"
              gutterBottom
            >
              {this.props.smallHeader}
            </Typography>
            <CheckCircleOutlineRoundedIcon color="primary" fontSize="large" />
          </Grid>

          <Typography
            variant="h5"
            color="textPrimary"
            className="cardHeader"
            component="h2"
          >
            {this.props.header}
          </Typography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
          >
            <CardActions>
              <StyledButtonOne
                size="small"
                onClick={() => this.handleButtonOneClick()}
              >
                {this.props.buttonText}
              </StyledButtonOne>
            </CardActions>
            <Typography variant="body2" color="textSecondary" component="p">
              {this.props.smallText}
            </Typography>
          </Grid>
        </Grid>
      </CardContentOne>
    );

    const CardTwo = (
      <CardContentTwo className="cardTwo">
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="baseline"
          >
            <Typography
              className="smallHeader"
              color="textSecondary"
              gutterBottom
            >
              {this.props.smallHeader}
            </Typography>
            <Typography className="week" color="textSecondary" gutterBottom>
              {this.state.week}
            </Typography>
            <BarChartRoundedIcon color="primary" fontSize="large" />
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="baseline"
          >
            <Typography
              className="cardHeader"
              variant="h2"
              color="textSecondary"
            >
              {this.props.checks}
            </Typography>
            <Typography
              className="cardHeader"
              variant="h5"
              color="textSecondary"
              component="h2"
            >
              {this.props.header}
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
          >
            <CardActions>
              <StyledButtonTwo
                size="small"
                onClick={() => this.handleButtonTwoClick()}
              >
                {this.props.buttonText}
              </StyledButtonTwo>
              <Typography variant="body2" color="textSecondary" component="p">
                {this.props.smallText}
              </Typography>
            </CardActions>
          </Grid>
        </Grid>
      </CardContentTwo>
    );

    const CardThree = (
      <CardContentThree className="cardThree">
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="baseline"
          >
            <Typography
              className="smallHeader"
              color="textSecondary"
              gutterBottom
            >
              {this.props.smallHeader}
            </Typography>
            <ShareOutlinedIcon color="secondary" fontSize="large" />
          </Grid>
          <Typography
            variant="h5"
            className="cardHeader"
            color="textSecondary"
            component="h2"
          >
            {this.props.header}
          </Typography>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="flex-end"
          >
            <CardActions>
              <StyledButtonThree
                size="small"
                onClick={() => this.handleButtonThreeClick()}
              >
                {this.props.buttonText}
              </StyledButtonThree>
            </CardActions>
            <Typography variant="body2" color="textSecondary" component="p">
              {this.props.smallText}
            </Typography>
          </Grid>
        </Grid>
      </CardContentThree>
    );

    if (this.props.card == 1) cardToRender = CardOne;
    else if (this.props.card == 2) cardToRender = CardTwo;
    else if (this.props.card == 3) cardToRender = CardThree;

    return <Card className="card">{cardToRender}</Card>;
  }
}

export default StatisticCardComponent;
