/**
 * TableCode from https://material-ui.com/components/tables/
 *
 **/

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button
} from "@material-ui/core";
import { Formik } from "formik";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import SaveAltRoundedIcon from "@material-ui/icons/SaveAltRounded";
import Breeds from "../../services/firebase/breeds";
import moment from "moment";
import "./style.css";

interface Data {
  id: string;
  date: Date;
  breedname: string;
  dogName: string;
  pushChannel: string;
  action: string;
}

function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

type Order = "asc" | "desc";

function getSorting<K extends keyof any>(
  order: Order,
  orderBy: K
): (
  a: { [key in K]: number | string },
  b: { [key in K]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: "date", numeric: true, label: "Datum" },
  { id: "breedname", numeric: false, label: "Rassenname" },
  { id: "dogName", numeric: false, label: "Hundename" },
  { id: "pushChannel", numeric: false, label: "push\u00a0Channel" },
  { id: "action", numeric: false, label: "" }
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: "darkgrey" }}>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={"center"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontWeight: "bold", fontSize: "12pt" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

export default function BreedTable(props) {
  const { breeds, onDelete } = props;
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("date");
  //dialog hooks
  const [errorDialog, setErrorDialog] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [saveDialog, setSaveDialog] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState({
    id: "",
    breedName: "",
    dogName: ""
  });
  const [saveData, setSaveData] = React.useState({
    breedName: ""
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const deleteFromFirebase = (event, id) => {
    event.stopPropagation();

    const breeds = Breeds.deleteBreed(id)
      .then(() => {
        setDeleteDialog(false);
        onDelete();
      })
      .catch(error => {
        setDeleteDialog(false);
        setErrorDialog(true);
      });
  };

  const handleSaveClicked = values => {
    //event.stopPropagation();
  };

  const handleDeleteDialogOpen = (event, id, breed, name) => {
    event.stopPropagation();
    setDeleteDialog(true);
    setDeleteData({
      id: id,
      breedName: breed,
      dogName: name
    });
  };

  const handleDeleteDialogClose = event => {
    event.stopPropagation();
    setDeleteDialog(false);
  };

  const handleSaveDialogOpen = (event, id, breed, name) => {
    event.stopPropagation();
    setSaveDialog(true);

    setSaveData({
      breedName: breed
    });
  };

  const handleSaveDialogClose = event => {
    event.stopPropagation();
    setSaveDialog(false);
  };

  const handleErrorDialogClose = event => {
    event.stopPropagation();
    setErrorDialog(false);
  };

  const handleBreedChange = () => {};

  return (
    <Grid>
      <Paper className={classes.root}>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={breeds.length}
          />
          <TableBody>
            {breeds.length != 0 ? (
              stableSort(breeds, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // const date = row.date.toString().substring(18, 28);

                  return (
                    <TableRow key={index}>
                      <TableCell padding="none" align={"center"}>
                        {/* {row.date.toString()} */}
                        {new Date(row.date).toLocaleDateString()}
                        {/* {new Intl.DateTimeFormat("de-AT", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit"
                      }).format(Date.parse(row.date))} */}
                      </TableCell>
                      <TableCell padding="none" align={"center"}>
                        {row.breedname}
                      </TableCell>
                      <TableCell padding="none" align={"center"}>
                        {row.dogName}
                      </TableCell>
                      <TableCell padding="none" align={"center"}>
                        {row.pushChannel}
                      </TableCell>
                      <TableCell padding="none" align={"center"}>
                        {/* <Tooltip title="in die Live DB speichern">
                        <IconButton
                          onClick={event =>
                            handleSaveDialogOpen(
                              event,
                              row.id,
                              row.breedname,
                              row.dogName
                            )
                          }
                        >
                          <SaveAltRoundedIcon />
                        </IconButton>
                      </Tooltip> */}
                        <Tooltip title="aus Firebase löschen">
                          <IconButton
                            onClick={event =>
                              handleDeleteDialogOpen(
                                event,
                                row.id,
                                row.breedname,
                                row.dogName
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>
                <TableCell padding="none" align="center"></TableCell>
                <TableCell padding="none" align="center"></TableCell>
                <TableCell align="center">
                  Keine neuen Rassen
                </TableCell>
                <TableCell padding="none" align="center"></TableCell>
                <TableCell padding="none" align="center"></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={breeds.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {deleteDialog ? (
        <Dialog
          open={true}
          onClose={event => handleDeleteDialogClose(event)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Möchtest du die neu hinzugefügte Rasse "{deleteData.breedName}" von{" "}
            {deleteData.dogName} wirklich löschen?
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={event => handleDeleteDialogClose(event)}
              color="primary"
            >
              NEIN
            </Button>
            <Button
              onClick={event => deleteFromFirebase(event, deleteData.id)}
              color="primary"
            >
              JA, löschen
            </Button>
          </DialogActions>
        </Dialog>
      ) : saveDialog ? (
        <Dialog
          open={true}
          onClose={event => handleSaveDialogClose(event)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Rasse: {saveData.breedName}
          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{ german: "", english: "" }}
              onSubmit={values => handleSaveClicked(values)}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {props => {
                const { values, handleChange, handleSubmit } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid item xs={12}>
                      <TextField
                        id="german"
                        value={values.german}
                        label="German"
                        name="german"
                        onChange={handleChange}
                        margin="normal"
                        variant="outlined"
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="english"
                        label="English"
                        value={values.english}
                        type="english"
                        name="english"
                        autoComplete="current-english"
                        onChange={handleChange}
                        margin="normal"
                        variant="outlined"
                        fullWidth={true}
                      />
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={event => handleSaveDialogClose(event)}
              color="primary"
            >
              Abbrechen
            </Button>
            <Button onClick={event => handleSaveClicked(event)} color="primary">
              Speichern
            </Button>
          </DialogActions>
        </Dialog>
      ) : errorDialog ? (
        <Dialog
          open={true}
          onClose={event => handleErrorDialogClose(event)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Shoops..</DialogTitle>
          <DialogContent>
            Da ist ein fehler passiert. Versuche es erneut.
          </DialogContent>
        </Dialog>
      ) : null}
    </Grid>
  );
}
