import React, { Component } from "react";
import { State } from "../store";
import { connect } from "react-redux";
import {
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import "./style.css";

interface ContactPageProps {}

class ContactPage extends Component<ContactPageProps> {
  render() {
    return (
      <Grid container direction="column">
          <Typography variant="h4">Kontakt</Typography>
          <TextField
            id="email"
            label="Deine Email Adresse"
            placeholder="hunde@liebhaber.at"
            margin="normal"
            color="primary"
            variant="outlined"
          />
          <TextField
            id="betreff"
            label="Betreff"
            placeholder="Betreff"
            margin="normal"
            color="primary"
            variant="outlined"
          />
          <TextField
            id="text"
            label="Nachricht"
            placeholder="Deine Nachricht an Pezz..."
            margin="normal"
            color="primary"
            multiline
            variant="outlined"
          />
      </Grid>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {};
};

export const ContactPageContainer = connect(mapStateToProps)(ContactPage);
