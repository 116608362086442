import React, { FunctionComponent } from "react";
import { InfoData } from "../../data/Data";
import {
  Grid,
  Hidden
} from "@material-ui/core";
import "./style.css";
import moment from "moment";
import { getActiveElement } from "formik";

interface DogInfoComponentProps {
  info: InfoData;
}

const getDate = date => {
  const formattedDate = new Intl.DateTimeFormat("de-AT", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  }).format(Date.parse(date));
  return formattedDate;
};

const getAge = date => {
  const age = moment().diff(moment(date, "YYYYMMDD"), "years");
  if (age == 0) {
    return moment().diff(moment(date, "YYYYMMDD"), "months") + " Monate";
  } else {
    return age + " Jahre";
  }
};

export const DogInfoComponent: FunctionComponent<DogInfoComponentProps> = ({
  info
}) => (
  <Grid container direction="row" className="dogInfoContainer">
    <Hidden smDown>
    <Grid item xs={4}>
      <p className="dogInfoListItemText">
        <strong>Testtype: </strong>
        {info.position}
      </p>
      <p className="dogInfoListItemText">
        <strong>Test-Datum: </strong>
        {getDate(info.created)}
      </p>
      <p className="dogInfoListItemText">
        <strong>Email: </strong>
        {info.mail}
      </p>
      <p className="dogInfoListItemText">
        <strong>Bearbeitet am: </strong>
        {getDate(info.updated)}
      </p>
    </Grid>
    <Grid item xs={4}>
      <p className="dogInfoListItemText">
        <strong>Tier: </strong> {info.petId == 1 ? "Katze" : "Hund"}
      </p>
      <p className="dogInfoListItemText">
        <strong>Pet-Id: </strong> {info.dogId}
      </p>
      <p className="dogInfoListItemText"> 
        <strong>{info.petId == 1 ? "Katzenname: " : "Hundename: "} </strong>
        {info.dogName}
      </p>
      <p className="dogInfoListItemText">
        <strong>Alter: </strong>
        {getAge(info.birthdate)}
      </p>
    </Grid>
    <Grid item xs={4}>
      <p className="dogInfoListItemText">
        <strong>Geschlecht: </strong>
        {info.sex == 1 ? " männlich" : " weiblich"}
      </p>
      <p className="dogInfoListItemText">
        <strong>Vorerkrankungen: </strong>{" "}
        {info.illnesses.length == 0
          ? "Keine"
          : info.illnesses.map(item => {
              return item + ", ";
            })}
      </p>
      <p className="dogInfoListItemText">
        <strong> Rasse: </strong>{" "}
        {info.breeds.length < 2
          ? info.breeds
          : "Mischling (" +
            info.breeds.map(breed => {
              return breed;
            }) +
            ")"}
      </p>
      <p className="dogInfoListItemText">
        <strong>Kastriert:</strong>
        {info.castrated ? " ja" : " nein"}
      </p>
    </Grid>
    </Hidden>
    <Hidden mdUp>
    <Grid item xs={12}>
      <p className="dogInfoListItemText">
        <strong>Testtype: </strong>
        {info.position}
      </p>
      <p className="dogInfoListItemText">
        <strong>Test-Datum: </strong>
        {getDate(info.created)}
      </p>
      <p className="dogInfoListItemText">
        <strong>Email: </strong>
        {info.mail}
      </p>
      <p className="dogInfoListItemText">
        <strong>Bearbeitet am: </strong>
        {getDate(info.updated)}
      </p>
      <p className="dogInfoListItemText">
        <strong>Tier: </strong> {info.petId == 1 ? "Katze" : "Hund"}
      </p>
      <p className="dogInfoListItemText">
        <strong>Pet-Id: </strong> {info.dogId}
      </p>
      <p className="dogInfoListItemText"> 
        <strong>{info.petId == 1 ? "Katzenname: " : "Hundename: "} </strong>
        {info.dogName}
      </p>
      <p className="dogInfoListItemText">
        <strong>Alter: </strong>
        {getAge(info.birthdate)}
      </p>
      <p className="dogInfoListItemText">
        <strong>Geschlecht: </strong>
        {info.sex == 1 ? " männlich" : " weiblich"}
      </p>
      <p className="dogInfoListItemText">
        <strong>Vorerkrankungen: </strong>{" "}
        {info.illnesses.length == 0
          ? "Keine"
          : info.illnesses.map(item => {
              return item + ", ";
            })}
      </p>
      <p className="dogInfoListItemText">
        <strong> Rasse: </strong>{" "}
        {info.breeds.length < 2
          ? info.breeds
          : "Mischling (" +
            info.breeds.map(breed => {
              return breed;
            }) +
            ")"}
      </p>
      <p className="dogInfoListItemText">
        <strong>Kastriert:</strong>
        {info.castrated ? " ja" : " nein"}
      </p>
    </Grid>
    </Hidden>
  </Grid>
);
