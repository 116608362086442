import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      height: "100%",
      color: "primary"
    }
  })
);

interface LoadProps {
  isLoading: boolean;
}

export default function LoadComponent(props: LoadProps) {
  const classes = useStyles();
  const timerRef = React.useRef<number>();

  React.useEffect(
    () => () => {
      clearTimeout(timerRef.current);
    },
    []
  );

  return (
    <div className={classes.root}>
      <Fade
        in={props.isLoading}
        style={{
          visibility: props.isLoading ? "visible" : "hidden"
        }}
        unmountOnExit
      >
        <CircularProgress />
      </Fade>
    </div>
  );
}
