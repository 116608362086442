import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

interface TablePageSkeletonComponentProps {}

class TablePageSkeletonComponent extends Component<
  TablePageSkeletonComponentProps
> {
  render() {
    return (
      <Grid>
        <Skeleton
          width="100%"
          variant={"rect"}
          style={{ marginBottom: 6 }}
          height={50}
        />
        <Skeleton
          width="100%"
          variant={"rect"}
          style={{ marginBottom: 6 }}
          height={50}
        />
        <Skeleton
          width="100%"
          variant={"rect"}
          style={{ marginBottom: 6 }}
          height={60}
        />
        <Skeleton
          width="100%"
          variant={"rect"}
          style={{ marginBottom: 6 }}
          height={60}
        />
        <Skeleton width="100%" variant={"rect"} height={50} />
      </Grid>
    );
  }
}

export default TablePageSkeletonComponent;
