import React, { FunctionComponent } from "react";
import { Redirect, RouteProps } from "react-router";
import { Route } from "react-router-dom";

interface ProtectedRouteProps {
  isAuthenticated: boolean;
}

export const ProtectedRoute: FunctionComponent<
  ProtectedRouteProps & RouteProps
> = props =>
  props.isAuthenticated ? <Route {...props} /> : <Redirect to={"/login"} />;
