import React, { Component } from "react";
import { DevelopItem, TestValueItem } from "../data/Data";
import { fetchDevelopItems, fetchTestValues, deleteItem } from "../api";
import { setDevelopItems, setHistoryTestValue, State } from "../store";
import CheckListComponentFilter from "../components/CheckListComponent/CheckListComponentFilter";
import TableComponentSkeleton from "../components/LoadComponents/TableComponentSkeleton";
import { connect } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import harald from "../assets/harald.png";
import "./style.css";
import Hidden from "@material-ui/core/Hidden";
import * as StatisticsFile from "../data/Statistics";

interface DevelopPageProps {
  developItems: DevelopItem[];
  testValues: TestValueItem[];
  authToken: any;
  dispatch: any;
}

let timer;

class DevelopPage extends Component<DevelopPageProps> {
  state = {
    limit: 10000,
    offset: 0,
    allItems: [],
    year: new Date().getFullYear(),
    month: new Date().getUTCMonth(),
    monthChecks: 0,
    yearChecks: 0,
    isFetching: false
  };

  getMonth(month: number): String {
    switch (month) {
      case 0:
        return "Jänner";
      case 1:
        return "Februar";
      case 2:
        return "Mai";
      case 3:
        return "April";
      case 4:
        return "Mai";
      case 5:
        return "Juni";
      case 6:
        return "Juli";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "Oktober";
      case 10:
        return "November";
      case 11:
        return "Dezember";
      default:
        return "Jänner";
    }
  }

  handleDeleteClick() {
    this.fetchData();
  }

  componentDidMount(): void {
    this.fetchData();
    //fetch data every 10 Minutes
    timer = setInterval(() => this.fetchData(), 600000);
  }

  componentWillUnmount() {
    clearInterval(timer);
    timer = null;
  }

  fetchData() {
    fetchDevelopItems(this.state.limit, this.state.offset).then(data => {
      // @ts-ignore
      this.props.dispatch(setDevelopItems(data.items));
      this.setState({
        allItems: data.items
      });
    });
  }

  getStatistics() {
    const statistics: StatisticsFile.Statistics = new StatisticsFile.Statistics();
    const year = statistics.getChecksOfYear(this.state.allItems);
    const month = statistics.geChecksOfMonth(this.state.allItems);

    this.setState({
      yearChecks: year,
      monthChecks: month
    });
  }

  render() {
    return (
      <Grid container direction="column">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className="developListContainer"
        >
          <Hidden smDown>
            <Grid className="newChecksDiv">
              <Typography component="p">
                <span className="text">
                  Pezz Testergebnisse <br />
                </span>
                <Grid container direction="row" justify="space-between">
                  <span className="detailText">
                    Gesamt: {this.state.allItems.length} Check Ergebnisse
                  </span>
                  <span className="detailText">
                    Im Jahr {this.state.year}: {this.state.yearChecks} Check
                    Ergebnisse
                  </span>
                  <span className="detailText">
                    Im Monat {this.getMonth(this.state.month)}:{" "}
                    {this.state.monthChecks} Check Ergebnisse
                  </span>
                </Grid>
              </Typography>
            </Grid>

            <Grid item>
              <img src={harald} width="200px" height="200px" />
            </Grid>
          </Hidden>
        </Grid>
        {this.state.allItems.length != 0 ? (
          <CheckListComponentFilter
            items={this.state.allItems}
            showResult={true}
            authToken={this.props.authToken}
            handleDeleteClicked={id => this.handleDeleteClick()}
          />
        ) : (
          <TableComponentSkeleton />
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    items: state.developItems,
    testValues: state.testValues,
    authToken: state.authToken
  };
};

export const DevelopPageContainer = connect(mapStateToProps)(DevelopPage);
