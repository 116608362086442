import React, { Component } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image
} from "@react-pdf/renderer";
import "./style.css";
import { DevelopItem } from "../../data/Data";
import blood from "../../assets/ic_blood.svg";
import leucozytes from "../../assets/ic_leucocytes.svg";
import ketone from "../../assets/ic_ketone.svg";
import glucose from "../../assets/ic_glucose.svg";
import spezifics from "../../assets/ic_specific_gravity.svg";
import ph from "../../assets/ic_ph.svg";
import protein from "../../assets/ic_protein.svg";
import {
  titleArray,
  analysisValueArray,
  normalValueArray
} from "../../data/Constants";
import moment from "moment";

// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    color: "#4769cc",
    margin: "30px"
  },
  sectionOne: {
    flexGrow: 1,
    flexDirection: "row",
    color: "#4769cc"
  },
  sectionTwo: {
    flexGrow: 3,
    flexDirection: "row",
    color: "#4769cc"
  },
  sectionVertikal: {
    flexWrap: "wrap",
    flexDirection: "column",
    margin: "20px",
    padding: "10px"
  },
  header: {
    fontWeight: "bold",
    textAlign: "center",
    color: "rgb(0, 191, 255)",
    fontSize: "20pt"
  },
  textBlue: {
    fontWeight: "bold",
    color: "white",
    padding: "2px",
    fontSize: "14pt"
  },
  textSmall: {
    fontWeight: "bold",
    marginLeft: "5px",
    fontSize: "10pt",
    padding: "2px"
  },
  text: {
    fontWeight: "bold",
    fontSize: "12pt",
    padding: "2px"
  },
  dogInfoContainer: {
    marginTop: "20px",
    marginBottom: "20px",
    color: "#4769cc",
    backgroundColor: "white"
  },
  dogInfoListItemText: {
    color: "#4769cc",
    fontSize: "14px",
    paddingTop: "0px",
    paddingBottom: "0px",
    backgroundColor: "white"
  },
  resultInfoContainer: {
    width: "100%",
    padding: "2px",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: "40px"
  },
  resultInnerSection: {
    padding: "2px",
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  imageGreen: {
    backgroundColor: "#a0c864",
    height: 30,
    width: 30
  },
  imageOrange: {
    backgroundColor: "#f2c736",
    height: 30,
    width: 30
  },
  imageRed: {
    backgroundColor: "#ec6f6e",
    height: 30,
    width: 30
  },
  resultIconSize: {
    width: "30px",
    height: "30px",
    alignItems: "center",
    textAlign: "center",
    padding: "1px"
  }
});

const icons: { [index: string]: React.ReactElement } = {
  0: <Image src={leucozytes} style={styles.resultIconSize} />,
  1: <Image src={spezifics} style={styles.resultIconSize} />,
  2: <Image src={ph} style={styles.resultIconSize} />,
  3: <Image src={protein} style={styles.resultIconSize} />,
  4: <Image src={glucose} style={styles.resultIconSize} />,
  5: <Image src={ketone} style={styles.resultIconSize} />,
  6: <Image src={blood} style={styles.resultIconSize} />
};

interface PDFDocumentComponentProps {
  item: DevelopItem;
}

// Create Document Component
class PDFDocumentComponent extends Component<PDFDocumentComponentProps> {
  getAge = date => {
    const age = moment().diff(moment(date, "YYYYMMDD"), "years");
    if (age == 0) {
      return moment().diff(moment(date, "YYYYMMDD"), "months") + " Monate";
    } else {
      return age + " Jahr/e";
    }
  };

  render() {
    return (
      <Document>
        <Page orientation="landscape" style={styles.page} size="A4">
          <Text style={styles.header}>PEZZ URINTESTERGEBNIS</Text>
          <View style={styles.sectionOne}>
            <View style={styles.sectionVertikal}>
              <Text style={[styles.textBlue, { backgroundColor: "#e6eeff" }]}>
                TEST ID: {this.props.item.info.id}
              </Text>
              <Text style={styles.text}>
                Ergebnis:{" "}
                {this.props.item.result.color == "GREEN" ? (
                  <Text style={styles.text}>alles ok </Text>
                ) : this.props.item.result.leukocyte.color == "ORANGE" ? (
                  <Text style={styles.text}>leicht abnormal </Text>
                ) : this.props.item.result.leukocyte.color == "RED" ? (
                  <Text style={styles.text}>abnormal</Text>
                ) : null}
              </Text>
            </View>
            <View style={styles.sectionVertikal}>
              <Text style={styles.text}>
                Test-Datum:{" "}
                <Text style={styles.textSmall}>
                  {new Intl.DateTimeFormat("de-AT", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                  }).format(Date.parse(this.props.item.info.created))}
                </Text>
              </Text>
              <Text style={styles.text}>
                Email:{" "}
                <Text style={styles.textSmall}>
                  {this.props.item.info.mail}
                </Text>
              </Text>
              <Text style={styles.text}>
                Bearbeitet am:{" "}
                <Text style={styles.textSmall}>
                  {new Intl.DateTimeFormat("de-AT", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                  }).format(Date.parse(this.props.item.info.updated))}
                </Text>
              </Text>
              <Text style={styles.text}>
                Name des Tiers:{" "}
                <Text style={styles.textSmall}>
                  {this.props.item.info.dogName}
                </Text>
              </Text>
              <Text style={styles.text}>
                Alter:{" "}
                <Text style={styles.textSmall}>
                  {this.getAge(this.props.item.info.birthdate)}
                </Text>
              </Text>
            </View>
            <View style={styles.sectionVertikal}>
              <Text style={styles.text}>
                Geschlecht:{" "}
                <Text style={styles.textSmall}>
                  {this.props.item.info.sex == 1 ? " männlich" : " weiblich"}
                </Text>
              </Text>
              <Text style={styles.text}>
                Vorerkrankungen:{" "}
                <Text style={styles.textSmall}>
                  {this.props.item.info.illnesses.length == 0
                    ? "Keine"
                    : this.props.item.info.illnesses.map(item => {
                        return "\n * " + item;
                      })}
                </Text>
              </Text>
              <Text style={styles.text}>
                Rasse/n:{" "}
                <Text style={styles.textSmall}>
                  {this.props.item.info.breeds.map(breed => {
                    return "\n * " + breed;
                  })}
                </Text>
              </Text>
              <Text style={styles.text}>
                Kastriert:{" "}
                <Text style={styles.textSmall}>
                  {this.props.item.info.castrated ? " ja" : " nein"}
                </Text>
              </Text>
            </View>
          </View>
          <View style={styles.sectionTwo}>
            <View style={styles.resultInnerSection}>
              <View style={styles.resultInfoContainer}>
                {this.props.item.result.leukocyte.color == "GREEN" ? (
                  <View style={styles.imageGreen}>{icons[0]}</View>
                ) : this.props.item.result.leukocyte.color == "ORANGE" ? (
                  <View style={styles.imageOrange}>{icons[0]}</View>
                ) : this.props.item.result.leukocyte.color == "RED" ? (
                  <View style={styles.imageRed}>{icons[0]}</View>
                ) : null}
                <View style={styles.resultInnerSection}>
                  <Text style={styles.textSmall}>
                    <Text style={styles.text}>{titleArray[0]}</Text>
                    {this.props.item.result.leukocyte.color == "GREEN"
                      ? "negativ"
                      : this.props.item.result.leukocyte.color == "ORANGE"
                      ? "positiv"
                      : this.props.item.result.leukocyte.color == "RED"
                      ? "positiv"
                      : null}
                  </Text>
                  <Text style={styles.textSmall}>
                    Annäherungswert: {this.props.item.result.leukocyte.value}
                  </Text>
                  <Text style={styles.textSmall}>
                    Analysebereich:
                    {analysisValueArray[0]}
                  </Text>
                  <Text style={styles.textSmall}>
                    Normalbereich:
                    {normalValueArray[0]}
                  </Text>
                </View>
              </View>
              <View style={styles.resultInfoContainer}>
                {this.props.item.result.specifics.color == "GREEN" ? (
                  <View style={styles.imageGreen}>{icons[1]}</View>
                ) : this.props.item.result.specifics.color == "ORANGE" ? (
                  <View style={styles.imageOrange}>{icons[1]}</View>
                ) : this.props.item.result.specifics.color == "RED" ? (
                  <View style={styles.imageRed}>{icons[1]}</View>
                ) : null}
                <View style={styles.resultInnerSection}>
                  <Text style={styles.textSmall}>
                    <Text style={styles.text}>{titleArray[1]}</Text>
                    {this.props.item.result.specifics.color == "GREEN"
                      ? "negativ"
                      : this.props.item.result.specifics.color == "ORANGE"
                      ? "positiv"
                      : this.props.item.result.specifics.color == "RED"
                      ? "positiv"
                      : null}
                  </Text>
                  <Text style={styles.textSmall}>
                    Annäherungswert: {this.props.item.result.specifics.value}
                  </Text>
                  <Text style={styles.textSmall}>
                    Analysebereich:
                    {analysisValueArray[1]}
                  </Text>
                  <Text style={styles.textSmall}>
                    Normalbereich:
                    {normalValueArray[1]}
                  </Text>
                </View>
              </View>
              <View style={styles.resultInfoContainer}>
                {this.props.item.result.ph.color == "GREEN" ? (
                  <View style={styles.imageGreen}>{icons[2]}</View>
                ) : this.props.item.result.ph.color == "ORANGE" ? (
                  <View style={styles.imageOrange}>{icons[2]}</View>
                ) : this.props.item.result.ph.color == "RED" ? (
                  <View style={styles.imageRed}>{icons[2]}</View>
                ) : null}
                <View style={styles.resultInnerSection}>
                  <Text style={styles.textSmall}>
                    <Text style={styles.text}>{titleArray[2]}</Text>
                    {this.props.item.result.ph.color == "GREEN"
                      ? "negativ"
                      : this.props.item.result.ph.color == "ORANGE"
                      ? "positiv"
                      : this.props.item.result.ph.color == "RED"
                      ? "positiv"
                      : null}
                  </Text>
                  <Text style={styles.textSmall}>
                    Annäherungswert: {this.props.item.result.ph.value}
                  </Text>
                  <Text style={styles.textSmall}>
                    Analysebereich:
                    {analysisValueArray[2]}
                  </Text>
                  <Text style={styles.textSmall}>
                    Normalbereich:
                    {normalValueArray[2]}
                  </Text>
                </View>
              </View>
              <View style={styles.resultInfoContainer}>
                {this.props.item.result.protein.color == "GREEN" ? (
                  <View style={styles.imageGreen}>{icons[3]}</View>
                ) : this.props.item.result.protein.color == "ORANGE" ? (
                  <View style={styles.imageOrange}>{icons[3]}</View>
                ) : this.props.item.result.protein.color == "RED" ? (
                  <View style={styles.imageRed}>{icons[3]}</View>
                ) : null}
                <View style={styles.resultInnerSection}>
                  <Text style={styles.textSmall}>
                    <Text style={styles.text}>{titleArray[3]}</Text>
                    {this.props.item.result.protein.color == "GREEN"
                      ? "negativ"
                      : this.props.item.result.protein.color == "ORANGE"
                      ? "positiv"
                      : this.props.item.result.protein.color == "RED"
                      ? "positiv"
                      : null}
                  </Text>
                  <Text style={styles.textSmall}>
                    Annäherungswert: {this.props.item.result.protein.value}
                  </Text>
                  <Text style={styles.textSmall}>
                    Analysebereich:
                    {analysisValueArray[3]}
                  </Text>
                  <Text style={styles.textSmall}>
                    Normalbereich:
                    {normalValueArray[3]}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.resultInnerSection}>
              <View style={styles.resultInfoContainer}>
                {this.props.item.result.glucose.color == "GREEN" ? (
                  <View style={styles.imageGreen}>{icons[4]}</View>
                ) : this.props.item.result.glucose.color == "ORANGE" ? (
                  <View style={styles.imageOrange}>{icons[4]}</View>
                ) : this.props.item.result.glucose.color == "RED" ? (
                  <View style={styles.imageRed}>{icons[4]}</View>
                ) : null}
                <View style={styles.resultInnerSection}>
                  <Text style={styles.textSmall}>
                    <Text style={styles.text}>{titleArray[4]}</Text>
                    {this.props.item.result.glucose.color == "GREEN"
                      ? "negativ"
                      : this.props.item.result.glucose.color == "ORANGE"
                      ? "positiv"
                      : this.props.item.result.glucose.color == "RED"
                      ? "positiv"
                      : null}
                  </Text>
                  <Text style={styles.textSmall}>
                    Annäherungswert: {this.props.item.result.glucose.value}
                  </Text>
                  <Text style={styles.textSmall}>
                    Analysebereich:
                    {analysisValueArray[4]}
                  </Text>
                  <Text style={styles.textSmall}>
                    Normalbereich:
                    {normalValueArray[4]}
                  </Text>
                </View>
              </View>
              <View style={styles.resultInfoContainer}>
                {this.props.item.result.ketone.color == "GREEN" ? (
                  <View style={styles.imageGreen}>{icons[5]}</View>
                ) : this.props.item.result.ketone.color == "ORANGE" ? (
                  <View style={styles.imageOrange}>{icons[5]}</View>
                ) : this.props.item.result.ketone.color == "RED" ? (
                  <View style={styles.imageRed}>{icons[5]}</View>
                ) : null}
                <View style={styles.resultInnerSection}>
                  <Text style={styles.textSmall}>
                    <Text style={styles.text}>{titleArray[5]}</Text>
                    {this.props.item.result.ketone.color == "GREEN"
                      ? "negativ"
                      : this.props.item.result.ketone.color == "ORANGE"
                      ? "positiv"
                      : this.props.item.result.ketone.color == "RED"
                      ? "positiv"
                      : null}
                  </Text>
                  <Text style={styles.textSmall}>
                    Annäherungswert: {this.props.item.result.ketone.value}
                  </Text>
                  <Text style={styles.textSmall}>
                    Analysebereich:
                    {analysisValueArray[5]}
                  </Text>
                  <Text style={styles.textSmall}>
                    Normalbereich:
                    {normalValueArray[5]}
                  </Text>
                </View>
              </View>
              <View style={styles.resultInfoContainer}>
                {this.props.item.result.hemoglobin.color == "GREEN" ? (
                  <View style={styles.imageGreen}>{icons[6]}</View>
                ) : this.props.item.result.hemoglobin.color == "ORANGE" ? (
                  <View style={styles.imageOrange}>{icons[6]}</View>
                ) : this.props.item.result.hemoglobin.color == "RED" ? (
                  <View style={styles.imageRed}>{icons[6]}</View>
                ) : null}
                <View style={styles.resultInnerSection}>
                  <Text style={styles.textSmall}>
                    <Text style={styles.text}>{titleArray[6]}</Text>
                    {this.props.item.result.hemoglobin.color == "GREEN"
                      ? "negativ"
                      : this.props.item.result.hemoglobin.color == "ORANGE"
                      ? "positiv"
                      : this.props.item.result.hemoglobin.color == "RED"
                      ? "positiv"
                      : null}
                  </Text>
                  <Text style={styles.textSmall}>
                    Annäherungswert: {this.props.item.result.hemoglobin.value}
                  </Text>
                  <Text style={styles.textSmall}>
                    Analysebereich:
                    {analysisValueArray[6]}
                  </Text>
                  <Text style={styles.textSmall}>
                    Normalbereich:
                    {normalValueArray[6]}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  }
}

export default PDFDocumentComponent;
