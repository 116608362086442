import React, { Component } from "react";
import { Grid, Typography, IconButton } from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

interface BackendDokuPageComponentProps {}

class BackendDokuPage extends Component<BackendDokuPageComponentProps> {
  handleButtonClick() {
    window.location.replace("/dashboard/links");
  }

  render() {
    return (
      <Grid container direction="row" className="buttonContainer">
        <Grid item xs={1}>
          <IconButton
            onClick={() => this.handleButtonClick()}
            style={{ marginTop: "20px" }}
          >
            <ArrowBackIosRoundedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11}>
          <Typography variant="h3" className="textStyle" gutterBottom>
            BackendDocu
          </Typography>
          <Typography variant="h6">
            Die BackendDocu über die Pezz API und alles was dazu gehört findest
            du in OneDrive/Product/Dokumente/Software/Backend/Doku
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export const BackendDokuPageContainer = BackendDokuPage;
