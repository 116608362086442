import React, { Props, useEffect } from "react";
import { DevelopItem } from "../data/Data";
import moment from "moment";

export class Statistics {
  //get number of dogs in db
  getNumberOfDogs(items: DevelopItem[]) {
    const uniqueItems = this.getUniqueIds(items);
    return uniqueItems.length;
  }

  //get percentage of female dogs in DB
  getFemaleDogs(items: DevelopItem[]) {
    const uniqueItems = this.getUniqueIds(items);
    const femaleDogs = uniqueItems.filter(item => item.info.sex === 2);
    const percentage = (femaleDogs.length * 100) / uniqueItems.length;
    return percentage;
  }

  //get percentage of male dogs in DB
  getMaleDogs(items: DevelopItem[]) {
    const uniqueItems = this.getUniqueIds(items);
    const maleDogs = uniqueItems.filter(item => item.info.sex === 1);
    const percentage = (maleDogs.length * 100) / uniqueItems.length;
    return percentage;
  }

  getChecksOfYear(items: DevelopItem[]) {
    const created = items.map(item => item.info.created);
    const result = created.filter(
      date => moment(date).year() === moment().year()
    );
    return result.length;
  }

  getChecksOfLastYear(items: DevelopItem[]) {
    const created = items.map(item => item.info.created);
    const result = created.filter(
      date => moment(date).year() === moment().year() - 1
    );
    return result.length;
  }

  geChecksOfMonth(items: DevelopItem[]) {
    const created = items.map(item => item.info.created);
    const result = created.filter(
      date => moment(date).month() === moment().month()
    );
    return result.length;
  }

  getChecksOfWeek(items: DevelopItem[]) {
    const created = items.map(item => item.info.created);
    const result = created.filter(
      date => moment(date).weekYear() === moment().weekYear()
    );
    return result.length;
  }

  //get average time between created and updated check
  getTimeBetweenCreatedAndUpdated(items: DevelopItem[]) {
    const created = items.map(item => moment(item.info.created));
    const updated = items.map(item => moment(item.info.updated));

    let sum = 0;
    const difference = created.map((item, index) => {
      var duration = moment.duration(updated[index].diff(item));
      sum = sum + duration.seconds();
    });

    let avg = sum / difference.length;
    return Math.round(avg * 100) / 100;
  }

  getDogAverageAge(items: DevelopItem[]) {
    const uniqueItems = this.getUniqueIds(items);
    const dogs = uniqueItems.filter(item => item.info.birthdate);
    const now = moment();
    let sum = 0;
    const difference = dogs.map(item => {
      const birthdate = moment(item.info.birthdate).format();
      var duration = now.diff(moment(item.info.birthdate), "days");
      sum = sum + duration;
    });

    let avg = sum / difference.length;
    return Math.round((avg / 365) * 100) / 100;
  }

  //get the percantage of all castrated dogs
  getCastratedPercentage(items: DevelopItem[]) {
    const uniqueItems = this.getUniqueIds(items);
    const castratedDogs = uniqueItems.filter(item => item.info.castrated);
    const percentage = (castratedDogs.length * 100) / uniqueItems.length;
    return percentage;
  }

  // get the name and number of the most used breed
  getMostBreed(items: DevelopItem[]) {
    const uniqueItems = this.getUniqueIds(items);
    const breeds = uniqueItems.map(item => item.info.breeds);
  }

  //get the most common illness
  getMostIllness(items: DevelopItem[]) {
    const uniqueItems = this.getUniqueIds(items);
    const illness = uniqueItems.map(item => item.info.illnesses);
  }

  //get the dogs with illness
  getDogsWithIllness(items: DevelopItem[]) {
    const uniqueItems = this.getUniqueIds(items);
    const illness = uniqueItems.filter(
      item => item.info.illnesses.length === 1
    );
    const percentage = (illness.length * 100) / items.length;
    return percentage;
  }

  getDogsWithOutIllness(items: DevelopItem[]) {
    const uniqueItems = this.getUniqueIds(items);
    const illness = uniqueItems.filter(
      item => item.info.illnesses.length === 0
    );
    const percentage = (illness.length * 100) / items.length;
    return percentage;
  }

  getDogsWithMoreThanOneIllness(items: DevelopItem[]) {
    const uniqueItems = this.getUniqueIds(items);
    const illness = uniqueItems.filter(item => item.info.illnesses.length > 1);
    const percentage = (illness.length * 100) / items.length;
    return percentage;
  }

  getDogAnzPerUser(items: DevelopItem[]) {}

  // get the name and number of the most used dogName
  getDogName(items: DevelopItem[]) {
    const uniqueItems = this.getUniqueIds(items);
    const names = uniqueItems.map(item => item.info.dogName);
  }

  //get percentage of green check results
  getChecksGreen(items: DevelopItem[]) {
    const greenChecks = items.filter(item => item.info.status === 1);
    const percentage = (greenChecks.length * 100) / items.length;
    return percentage;
  }

  //get percentage of orange check results
  getCheckOrange(items: DevelopItem[]) {
    const orangeChecks = items.filter(item => item.info.status === 2);
    const percentage = (orangeChecks.length * 100) / items.length;
    return percentage;
  }

  //get percentage of red check results
  getChecksRed(items: DevelopItem[]) {
    const redChecks = items.filter(item => item.info.status === 3);
    const percentage = (redChecks.length * 100) / items.length;
    return percentage;
  }

  //get percentage of 5 check results - user has a dog but no check done
  getCheckStatus5(items: DevelopItem[]) {
    const status5Checks = items.filter(item => item.info.status === 5);
    const percentage = (status5Checks.length * 100) / items.length;
    return percentage;
  }

  getCheckFirst(items: DevelopItem[]) {
    const firstChecks = items.filter(item => item.info.position === 1);
    const percentage = (firstChecks.length * 100) / items.length;
    return percentage;
  }

  getCheckZero(items: DevelopItem[]) {
    const zeroChecks = items.filter(item => item.info.position === 0);
    const percentage = (zeroChecks.length * 100) / items.length;
    return percentage;
  }

  getCheckTwo(items: DevelopItem[]) {
    const secondChecks = items.filter(item => item.info.position === 2);
    const percentage = (secondChecks.length * 100) / items.length;
    return percentage;
  }

  getCheckGreenZero(items: DevelopItem[]) {
    const greenChecks = items.filter(item => item.info.status === 1);
    const greenFirst = greenChecks.filter(item => item.info.position === 0);
    return greenFirst.length;
  }

  getCheckOrangeZero(items: DevelopItem[]) {
    const orangeChecks = items.filter(item => item.info.status === 2);
    const orangeZero = orangeChecks.filter(item => item.info.position === 0);
    return orangeZero.length;
  }

  getCheckRedZero(items: DevelopItem[]) {
    const redChecks = items.filter(item => item.info.status === 3);
    const redZero = redChecks.filter(item => item.info.position === 0);
    return redZero.length;
  }

  getCheckGreenFirst(items: DevelopItem[]) {
    const greenChecks = items.filter(item => item.info.status === 1);
    const greenFirst = greenChecks.filter(item => item.info.position === 1);
    return greenFirst.length;
  }
  getCheckOrangeFirst(items: DevelopItem[]) {
    const orangeChecks = items.filter(item => item.info.status === 2);
    const orangeFirst = orangeChecks.filter(item => item.info.position === 1);
    return orangeFirst.length;
  }

  getCheckRedFirst(items: DevelopItem[]) {
    const redChecks = items.filter(item => item.info.status === 3);
    const redFirst = redChecks.filter(item => item.info.position === 1);
    return redFirst.length;
  }

  getCheckGreenSecond(items: DevelopItem[]) {
    const greenChecks = items.filter(item => item.info.status === 1);
    const greenSecond = greenChecks.filter(item => item.info.position === 2);
    return greenSecond.length;
  }

  getCheckOrangeSecond(items: DevelopItem[]) {
    const orangeChecks = items.filter(item => item.info.status === 2);
    const orangeSecond = orangeChecks.filter(item => item.info.position === 2);
    return orangeSecond.length;
  }
  getCheckRedSecond(items: DevelopItem[]) {
    const redChecks = items.filter(item => item.info.status === 3);
    const redSecond = redChecks.filter(item => item.info.position === 2);
    return redSecond.length;
  }

  getPercentageOfUserWithVaccination(items: DevelopItem[]) {
    const uniqueItems = this.getUniqueIds(items);
  }

  getPercentageOfUserWithVet(items: DevelopItem[]) {
    const uniqueItems = this.getUniqueIds(items);
  }

  getPercentageOfDogHybrid(items: DevelopItem[]) {
    const uniqueItems = this.getUniqueIds(items);

    const hybrids = uniqueItems.filter(item => item.info.breeds.length > 1);
    const percentage = (hybrids.length * 100) / uniqueItems.length;
    return percentage;
  }

  getPercentageOfDogWithOneBreed(items: DevelopItem[]) {
    const uniqueItems = this.getUniqueIds(items);
  }

  getUniqueIds(items: DevelopItem[]) {
    const unique = items.filter(
      (value, index, self) =>
        self.map(x => x.info.dogId).indexOf(value.info.dogId) == index
    );
    return unique;
  }
}
