import { Constants } from "../data/Constants";


interface Items {
  item: []
}

export const fetchJSON = (
  endpoint: string,
  method: string,
  body?: string
) => {
  const authToken=localStorage.getItem(Constants.STORAGE_AUTH_TOKEN_ACCESS);

  const uri = `${Constants.API}${endpoint}`;
  let options: RequestInit = {
      // mode: 'no-cors',
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken
      },
  };

  if (body) {
    options.body = body
  }
  
  return new Promise<any>(((resolve, reject) => {
    fetch(uri, options)
      .then(response => {
        if (response.ok) {
          if(response.status === 204){
            return resolve()
          }
          if (!body) {
            return resolve(response.json())
          }
        } else if(response.status === 401){
            const refreshToken=localStorage.getItem(Constants.STORAGE_AUTH_TOKEN_REFRESH)||'';
            return resolve(fetchAuthRefreshToken(refreshToken)
                .then(token => {
                  // @ts-ignore
                  localStorage.setItem(Constants.STORAGE_AUTH_TOKEN_ACCESS, token.access_token);
                  // @ts-ignore
                  localStorage.setItem(Constants.STORAGE_AUTH_TOKEN_REFRESH, token.refresh_token);
                  return fetchJSON(endpoint, method, body)
                })
                .catch(() => {
                  localStorage.clear();
                  // window.location.href = '/dashboard/';
                  return resolve()
                }))
        } 
      })
  }))
};

export const fetchAuthToken = (username: string, password: string) => {
  const body = new URLSearchParams({
    grant_type: "password",
    scope: Constants.AUTH_SCOPE,
    username: username,
    password: password,
    client_id: Constants.AUTH_CLIENT_ID,
    client_secret: Constants.AUTH_CLIENT_SECRET
  });
  return fetch(Constants.AUTH_API + Constants.AUTH_QUERY_TOKEN, {
    method: "POST",
    body: body.toString(),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
};

export const fetchAuthRefreshToken = (refreshToken: string) => {
  const body = new URLSearchParams({
    grant_type: "refresh_token",
    scope: Constants.AUTH_SCOPE,
    refresh_token: refreshToken,
    client_id: Constants.AUTH_CLIENT_ID,
    client_secret: Constants.AUTH_CLIENT_SECRET
  });
  return fetch(Constants.AUTH_API + Constants.AUTH_QUERY_TOKEN, {
    method: "POST",
    body: body.toString(),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  }).then(response => {return response.json()});
};
