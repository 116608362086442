import { Constants } from "../data/Constants";
import { fetchAuthToken, fetchJSON } from "../utilities/fetchJSON";

export const fetchAllItems = () => {
  return fetchJSON(Constants.QUERY_ALL_ITEMS, "GET");
};

export const fetchSpecificItem = (id: number) => {
  return fetchJSON(`${Constants.QUERY_SPECIFIC_ITEM}${id}`, "GET");
};

export const fetchDevelopItems = (
  limit: number,
  offset: number
) => {
  const params = { limit: limit.toString(), offset: offset.toString() };
  const urlSearchParams = new URLSearchParams(Object.entries(params));
  return fetchJSON(
    `${Constants.QUERY_DEVELOP}${urlSearchParams}`,
    "GET"
  );
};

export const fetchUnfinishedItems = () => {
  return fetchJSON(Constants.QUERY_UNFINISHED, "GET");
};

export const fetchTestValues = () => {
  return fetchJSON(Constants.QUERY_TESTVALUES, "GET");
};

export const fetchAnamnesisItems  = (id: number, pet: number) => {
  const params = { petId: pet.toString()};
  const urlSearchParams = new URLSearchParams(Object.entries(params));
  return fetchJSON(`${Constants.QUERY_ANAMNESIS}${id}?${urlSearchParams}` , "GET");
};

export const updateTestResult = (id: number, results: any) => {
  return fetchJSON(
      `${Constants.QUERY_TEST}${id}`,
      "PATCH",
      JSON.stringify(results)
  );
};

export const updateHistoryTestResult = (id: number, results: any) => {
  return fetchJSON(
      `${Constants.QUERY_HISTORY_TEST}${id}`,
      "PATCH",
      JSON.stringify(results)
  );
};

export const deleteItem = (id: number, authToken: any) => {
  return fetchJSON(
    `${Constants.QUERY_TEST}${id}`,
    "DELETE",
    authToken.access_token
  );
};

export const authenticateUser = (username: string, password: string) => {
  return fetchAuthToken(username, password).then(response => {
    if (!response.ok) {
      throw "Falsches Passwort";
    }
    return response.json();
  });
};


export const fetchAIResultImage = (imageUrl : string, parameterUrl: string) => {
  console.log(imageUrl);
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Prediction-Key", "ad91a52ef277430e8ef2430f692b6c48");

  var raw = JSON.stringify({
    "Url": imageUrl
  });

  var requestOptions : RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  var requestUrl = "https://urine.cognitiveservices.azure.com/customvision/v3.0/Prediction/" + parameterUrl;
  console.log(requestUrl);

  return fetch(requestUrl, requestOptions)
}
