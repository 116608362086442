/**
 * TableCode from https://material-ui.com/components/tables/
 *
 **/

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Paper,
  Grid
} from "@material-ui/core";
import "./style.css";

interface Column {
  id: "brand" | "device" | "os" | "number" | "notes";
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "brand", label: "Device\u00a0Brand", minWidth: 70, align: "center" },
  { id: "device", label: "Device", minWidth: 100, align: "center" },
  {
    id: "os",
    label: "Android/iOS",
    minWidth: 70,
    align: "center"
  },
  {
    id: "number",
    label: "PEZZ\u00a0Device\u00a0Number",
    minWidth: 170,
    align: "center"
  },
  {
    id: "notes",
    label: "Notizen",
    minWidth: 170,
    align: "center"
  }
];

interface Data {
  brand: string;
  device: string;
  os: string;
  number: string;
  notes: string;
}

function createData(
  brand: string,
  device: string,
  os: string,
  number: string,
  notes: string
): Data {
  return { brand, device, os, number, notes };
}

function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

type Order = "asc" | "desc";

function getSorting<K extends keyof any>(
  order: Order,
  orderBy: K
): (
  a: { [key in K]: number | string },
  b: { [key in K]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {id: "brand", numeric: false, label: "Device\u00a0Brand"},
  { id: "device", numeric: true, label: "Device" },
  { id: "os", numeric: true, label: "Android/iOS" },
  { id: "number", numeric: true, label: "PEZZ\u00a0Device\u00a0Number"},
  { id: "notes", numeric: true, label: "Notizen" }
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{backgroundColor: "darkgrey"}}>
      <TableRow >
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={"center"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontWeight: "bold", fontSize: "12pt" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const rows = [
  createData("Huawei", "P8 lite", "Android 6", "PEZZ 01", "Leihgabe von Anna"),
  createData("Apple", "SE", "", "PEZZ 02", ""),
  createData("Apple", "6", "iOS 12.4.3", "PEZZ 03", ""),
  createData("Apple", "7", "", "PEZZ 04", ""),
  createData("Apple", "", "", "PEZZ 05", "Privatgerät von Marlene"),
  createData("Honor", "10", "Android 9", "PEZZ 06", "Privatgerät von Anna")
];

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

export default function DeviceTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("brand");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Paper className={classes.root}>
      <Grid className={classes.container}>
        <Table stickyHeader aria-label="sticky table" >
          <EnhancedTableHead 
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.number}
                  >
                    {columns.map(column => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Grid>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}