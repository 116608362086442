import React, { Fragment, Component } from "react";
import { Grid, AppBar, Tabs, Hidden, Tab } from "@material-ui/core";
import PetsRoundedIcon from "@material-ui/icons/PetsRounded";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import AssessmentRoundedIcon from "@material-ui/icons/AssessmentRounded";
import ShareRoundedIcon from "@material-ui/icons/ShareRounded";
import MailOutlineRoundedIcon from "@material-ui/icons/MailOutlineRounded";
import LoginRoundedIcon from '@material-ui/icons/LockOpenRounded';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CopyrightRoundedIcon from "@material-ui/icons/CopyrightRounded";
import SecurityIcon from "@material-ui/icons/Security";
import logo from "../../../assets/logo_dark.png";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { State } from "../../../store";
import { connect } from "react-redux";
import { Constants } from "../../../data/Constants";

interface AppBarProps {
  isAuthenticated: boolean;
}

interface CustomAppBarState {
  value: string;
}

class CustomAppBar extends Component<
  AppBarProps & RouteComponentProps,
  CustomAppBarState
> {
  state = {
    value: "/unfinished"
  };

  componentWillMount() {
    this.setState({ value: this.props.location.pathname });
  }

  handleChange = (event, value) => {
    this.setState({ value });
    this.props.history.push(value);
  };

  render() {
    return (
      <Fragment>
        <AppBar position="static" className="menuContent">
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={11}>
              <Tabs
                orientation="vertical"
                className="tabs"
                variant="standard"
                value={this.state.value}
                onChange={this.handleChange}
                TabIndicatorProps={{
                  style: {
                    background: "rgba(14, 56, 70, 0.0)",
                    color: "white"
                  }
                }}
              >
                <Hidden mdUp>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className="logoContainer"
                    style={{marginBottom: "20px" }}
                  >
                    <img
                      src={logo}
                      alt="Logo"
                      width="40px"
                      height="40px"
                      className="logo"
                    />
                    <p className="baseHeaderSmall">Base</p>
                  </Grid>
                </Hidden>
                <Tab
                  label="Overview"
                  value="/unfinished"
                  icon={<HomeRoundedIcon fontSize="small" />}
                  textColor="secondary"
                />
                <Tab
                  label="History"
                  value="/history"
                  icon={<CheckCircleOutlineRoundedIcon fontSize="small" />}
                  textColor="secondary"
                />
                <Tab
                  label="Hunderassen"
                  value="/breeds"
                  icon={<PetsRoundedIcon fontSize="small" />}
                  textColor="secondary"
                />
                <Tab
                  label="Statistics"
                  value="/statistics"
                  icon={<AssessmentRoundedIcon fontSize="small" />}
                  textColor="secondary"
                />
                <Tab
                  label="Links"
                  value="/links"
                  icon={<ShareRoundedIcon fontSize="small" />}
                  textColor="secondary"
                />
                <Tab
                  label="Impressum"
                  value="/impressum"
                  icon={<InfoOutlinedIcon fontSize="small" />}
                  textColor="secondary"
                />
                <Tab
                  label="Datenschutz"
                  value="/data"
                  icon={<SecurityIcon fontSize="small" />}
                  textColor="secondary"
                />
                <Tab
                  label="Kontakt"
                  value="/contact"
                  icon={<MailOutlineRoundedIcon fontSize="small" />}
                  textColor="secondary"
                /> 
                <Tab
                  label="Login"
                  value="/login"
                  icon={<LoginRoundedIcon fontSize="small" />}
                  textColor="secondary"
              />
              </Tabs>
            </Grid>

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ marginBottom: "50px" }}
            >
              <CopyrightRoundedIcon fontSize="large" />
              <p className="textBold">
                <strong>Pezz Backoffice</strong>
                <br />
                by Pezz life GmbH
              </p>
            </Grid>
          </Grid>
        </AppBar>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: State) => {
  const token = localStorage.getItem(Constants.STORAGE_AUTH_TOKEN_ACCESS);
  return {
    isAuthenticated: token !== null && token !== ""
  };
};

export const CustomAppBarWithRouter = connect(mapStateToProps)(
  withRouter(CustomAppBar)
);
