import React, { Component } from "react";
import {
  Grid,
  Typography,
  IconButton,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

interface WebsiteDokuPageComponentProps {}

class WebsiteDokuPage extends Component<WebsiteDokuPageComponentProps> {
  handleButtonClick() {
    window.location.replace("/dashboard/links");
  }

  render() {
    return (
      <Grid container direction="row" className="buttonContainer">
        <Grid item xs={1}>
          <IconButton
            onClick={() => this.handleButtonClick()}
            style={{ marginTop: "20px" }}
          >
            <ArrowBackIosRoundedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11}>
          <Typography variant="h3" className="textStyle" gutterBottom>
            WebsiteDocu
          </Typography>
          <Typography variant="h6">
            Die neue Website wurde mit Wordpress und einer mySql Datenbank
            erstellt.
          </Typography>

          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5" className="textStyle">
                Wordpress
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                <a
                  href="https://developer.wordpress.org/themes/getting-started/"
                  target="_blank"
                >
                  Getting started{" "}
                </a>
                with Wordpress
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Grid>
    );
  }
}

export const WebsitePageContainer = WebsiteDokuPage;
