import React, { FunctionComponent, useEffect } from "react";
import { Grid, IconButton, Badge } from "@material-ui/core";
import { fetchUnfinishedItems } from "../../../api";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import PetsRoundedIcon from "@material-ui/icons/PetsRounded";
import ReplayRoundedIcon from "@material-ui/icons/ReplayRounded";
import NotificationsRoundedIcon from "@material-ui/icons/NotificationsRounded";
import Breeds from "../../../services/firebase/breeds";

interface HeaderBarComponentProps {}

const handleReload = () => {
  window.location.reload();
};

const handleBreedsClick = () => {
  window.location.replace("/dashboard/breeds");
};

const handleChecksClick = () => {
  window.location.replace("/dashboard/unfinished");
};

export default function HeaderBarComponent(props: HeaderBarComponentProps) {
  const [newBreeds, setNewBreeds] = React.useState(0);
  const [newChecks, setNewChecks] = React.useState(0);

  //get length of new breeds
  useEffect(() => {
    const breeds = Breeds.getAllBreeds()
      .then(data => {
        setNewBreeds(data.length);
      })
      .catch(error => console.log("error: ", error));

    fetchUnfinishedItems()
      .then(unf => {
        setNewChecks(unf.items.length);
      })
      .catch(error => console.log("error: ", error));
  });

  return (
    <Grid>
      <IconButton onClick={() => handleReload()}>
        <ReplayRoundedIcon color="primary" />
      </IconButton>
      <IconButton onClick={() => handleBreedsClick()}>
        <Badge badgeContent={newBreeds} color="secondary">
          <PetsRoundedIcon color="primary" />
        </Badge>
      </IconButton>
      <IconButton onClick={() => handleChecksClick()}>
        <Badge badgeContent={newChecks} color="secondary">
          <NotificationsRoundedIcon color="primary" />
        </Badge>
      </IconButton>
      {/* <IconButton>
        <AccountCircleRoundedIcon color="primary" />
      </IconButton> */}
    </Grid>
  );
}
