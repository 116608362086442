import React, { Component } from "react";
import { anamnesisQuestionsDog, anamnesisQuestionsCat } from "../../data/Constants";
import { Grid } from "@material-ui/core";
import "./style.css";
import {AnamnesisData} from "../../data/Data";

interface AnamnesisInfoComponentProps {
  anamnesis: AnamnesisData;
  petId: number;
}

class AnamnesisInfoComponent extends Component<AnamnesisInfoComponentProps> {
  render() { 

    const data = [
      this.props.anamnesis.changesBehavior,
      this.props.anamnesis.drinksMore,
      this.props.anamnesis.drinksLess,
      this.props.anamnesis.peesMoreOften,
      this.props.anamnesis.peesMore,
      this.props.anamnesis.urineDarker,
      this.props.anamnesis.eatsLess,
      this.props.anamnesis.losesWeight,
      this.props.anamnesis.licksBellyBald
    ];

    return(
      <Grid container direction="row" className="dogInfoContainer">
        <Grid item xs={12}>
          {this.props.petId == 1 ? 
            <Grid> 
              {anamnesisQuestionsCat.map((question, index) => 
                <p className="dogInfoListItemText">{question}
                  {data[index] == 2 ?
                    <span style={{color: "#ec6f6e", fontWeight: 700 }}> JA</span> :
                    <span style={{color: "#a0c864", fontWeight: 700 }}> NEIN</span>
                  }
                </p>
              )}
            </Grid> : 
            <Grid>
              {anamnesisQuestionsDog.map((question, index) => 
                <p className="dogInfoListItemText">
                  {question}
                  {data[index] == 2 ? 
                    <span style={{color: "#ec6f6e", fontWeight: 700 }}> JA</span> :
                    <span style={{color: "#a0c864", fontWeight: 700 }}> NEIN</span>
                  }
                </p> 
              )}
            </Grid>
          }
        </Grid>
      </Grid>
    )
  }
}
  
export default AnamnesisInfoComponent;