import React, { Component } from "react";
import { State, removeUnfinisedItem } from "../store";
import { connect } from "react-redux";
import {
  Grid,
  Typography,
  Button,
  withStyles,
  IconButton,
  Hidden,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Dialog,
  DialogActions,
  DialogTitle
} from "@material-ui/core";
import emailjs from "emailjs-com";
import dogSmall from "../assets/img_dog_small.svg";
import catSmall from "../assets/img_cat_small.svg";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import DetailPageSkeletonComponent from "../components/LoadComponents/DetailPageSkeleton";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import MailOutlineRoundedIcon from "@material-ui/icons/MailOutlineRounded";
import CachedRoundedIcon from "@material-ui/icons/CachedRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import ParameterDetailComponent from "../components/ParameterDetailComponent/ParameterDetailComponent";
import { DogInfoComponent } from "../components/DogInfoComponent/DogInfoComponent";
import AnamnesisInfoComponent from "../components/AnamnesisInfoComponent/AnamnesisInfoComponent";
import "./style.css";
import { AnamnesisData, DevelopItem, TestValueItem } from "../data/Data";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  fetchDevelopItems,
  fetchAnamnesisItems,
  deleteItem,
  fetchUnfinishedItems,
  fetchSpecificItem
} from "../api";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFDocumentComponent from "../components/PDFDocument/PDFDocument";

interface DetailPageProps {
  items: DevelopItem[];
  item: DevelopItem;
  testValues: TestValueItem[];
  authToken: any;
  dispatch: any;
}

const StyledButtonEdit = withStyles({
  root: {
    width: "auto",
    background: "#7e98e6",
    borderRadius: "22px",
    border: 0,
    color: "white",
    padding: "5px 50px",
    boxShadow:
      "0px 1px 16px 0px rgba(0, 0, 0, 0.1), 0px 2px 5px 0px rgba(0, 0, 0, 0.07)"
  },
  label: {
    textTransform: "capitalize"
  }
})(Button);

const StyledButtonCheck = withStyles({
  root: {
    width: "auto",
    background: "#4070ff",
    borderRadius: "22px",
    border: 0,
    color: "white",
    padding: "5px 50px",
    boxShadow:
      "0px 1px 16px 0px rgba(0, 0, 0, 0.1), 0px 2px 5px 0px rgba(0, 0, 0, 0.07)"
  },
  label: {
    textTransform: "capitalize"
  }
})(Button);

class DetailPage extends Component<DetailPageProps> {
  state = {
    id: 0,
    nextEditId: 0,
    nextDetailId: 0,
    limit: 10000,
    offset: 0,
    allItems: [],
    unfinishedItems: [],
    fromHistory: false,
    ready: true,
    openEmailDialog: false,
    openErrorDialog: false,
    anamnesisItems: {
      id: 0,
      created: "",
      updated: "",
      resultId: 1,
      changesBehavior: 1,
      drinksMore: 1,
      drinksLess: 1, 
      peesMoreOften: 1,
      peesMore: 1, 
      urineDarker: 1, 
      eatsLess: 1,
      losesWeight: 1,
      licksBellyBald: 1,
      anamnesisState: 1,
    }
  };

  componentDidMount() {
    const splitPathname = window.location.pathname.split("/", 5);
    const id = parseInt(splitPathname[3]);

    this.getItemForDetails(id);
    this.getNextItems(id);
  }

  openErrorDialog() {
    this.setState({
      openErrorDialog: true
    });
  }

  closeErrorDialog() {
    this.setState({
      openErrorDialog: false
    });
  }

  handleSendEmailDialogClose() {
    this.setState({
      openEmailDialog: false
    });
  }

  handleSendEmailDialogOpen() {
    this.setState({
      openEmailDialog: true
    });
  }

  errorLog(error) {
    console.log("error of pdf rendering: ", error);
  }

  handleSendMailClicked = (item: DevelopItem) => {
    let templateParams = {
      from: "pezz@support.life",
      to: item.info.mail,
      testID: item.info.id,
      leukozyten: item.result.leukocyte.status === 1 ? " negativ" : " positiv",
      leukozytenValue: item.result.leukocyte.value.toString(),
      specifics: item.result.specifics.status === 1 ? " negativ" : " positiv",
      specificsValue: item.result.specifics.value.toString(),
      ph: item.result.ph.status === 1 ? " negativ" : " positiv",
      phValue: item.result.ph.value.toString(),
      protein: item.result.protein.status === 1 ? " negativ" : " positiv",
      proteinValue: item.result.protein.value.toString(),
      glucose: item.result.glucose.status === 1 ? " negativ" : " positiv",
      glucoseValue: item.result.glucose.value.toString(),
      ketone: item.result.ketone.status === 1 ? " negativ" : " positiv",
      ketoneValue: item.result.ketone.value.toString(),
      hemoglobin: item.result.hemoglobin.status === 1 ? " negativ" : " positiv",
      hemoglobinValue: item.result.hemoglobin.value.toString(),
      dogName: item.info.dogName,
      subject: "Dein Pezz Testergebnis"
    };

    emailjs
      .send(
        "smtp_server",
        "template_a8D0yPAx",
        templateParams,
        "user_AEePfkSyX6TMZ82A7ljo2"
      )
      .then(
        response => {
          console.log("email send successful ", response.text, response.status);
          this.handleSendEmailDialogClose();
        },
        err => {
          console.log("FAILED...", err);
          this.handleSendEmailDialogClose();
          this.openErrorDialog();
        }
      );
  };

  handleDeleteClicked = (id: number) => {
    console.log("delete check ");
    const { dispatch, authToken } = this.props;
    deleteItem(id, authToken)
      .then(() => {
        dispatch(removeUnfinisedItem(id));
      })
      .then(() => {
        window.location.replace("/dashboard/history");
      })
      .catch(error => {
        console.log("Error while deleting check: ", error);
      });
  };

  handleNextCheckClick() {
    window.location.replace("/dashboard/edit/" + this.state.nextEditId);
  }

  handleBackToHistory() {
    window.location.replace("/dashboard/history");
  }

  handleNextDetail() {
    window.location.replace("/dashboard/detail/" + this.state.nextDetailId);
  }

  handleEditCheckClick = (event, id) => {
    window.location.replace("/dashboard/edit/" + id);
  };

  getItemForDetails(id: number) {
    fetchSpecificItem(id)
      .then(item => {
        this.setState({
          allItems: item.items,
          fromHistory: item.items[0].info.develop === "2"
        });

        fetchAnamnesisItems(item.items[0].info.anamnesisId, item.items[0].info.petId )
          .then(anamnesis => {
            this.setState({
              anamnesisItems: {
                id: anamnesis.id,
                created: anamnesis.created,
                updated: anamnesis.updated,
                resultId: anamnesis.resultId,
                changesBehavior: anamnesis.changesBehavior, 
                drinksMore: anamnesis.drinksMore, 
                drinksLess: anamnesis.drinksLess, 
                peesMoreOften: anamnesis.peesMoreOften, 
                peesMore: anamnesis.peesMore, 
                urineDarker: anamnesis.urineDarker, 
                eatsLess: anamnesis.eatsLess, 
                losesWeight: anamnesis.losesWeight, 
                licksBellyBald: anamnesis.licksBellyBald,
                anamnesisState: anamnesis.anamnesisState,
              }
            });
          })
      })
      .catch(error => {
        console.log("error: ", error);
      });
  }

  getNextItems(id) {
    fetchUnfinishedItems()
      .then(nextEditItems => {
        if (nextEditItems.items.length !== 0) {
          this.setState({
            nextEditId: nextEditItems.items[0].info.id
          });
        }
        fetchDevelopItems(10000, 0).then(nextDetailItems => {
          if (nextDetailItems.items.length !== 0) {
            //index of current-1 is next item
            const index = nextDetailItems.items.findIndex(
              i => i.info.id === id
            );
            if (index !== nextDetailItems.items.length - 1) {
              this.setState({
                nextDetailId: nextDetailItems.items[index + 1].info.id
              });
            } else {
              this.setState({
                nextDetailId: nextDetailItems.items[0].info.id
              });
            }
          }
        });
      })
      .catch(error => console.log(error));
  }

  render() {
    const item: DevelopItem = this.state.allItems[0];
    const anamnesisData: AnamnesisData = this.state.anamnesisItems;

    return this.state.allItems.length !== 0 ? (
      <Grid container direction="column">
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography component="p">
              <span className="text">Test ID: {item.info.id}</span><br/>
              {item.info.anamnesisId !== null ? <span className="text">Anamnesis ID: {item.info.anamnesisId}</span>: null }
              <span className="detailText">Manuell überprüft</span><br/>
                  <span className="text">Phone: {item.result.device}</span><br/>
                  <span className="text">OS: {item.result.os}</span>
            </Typography>
            {item.info.petId === 1 ? <img width="50px" height="50px" src={catSmall} alt="Cat"/> : <img width="50px" height="50px" src={dogSmall} alt="Dog"/> }
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center">
            <Grid>
              <span className="text">Ergebnis:</span>
              {item.info.status === 1 ? (
                <span className="smallText">
                  <CheckRoundedIcon
                    style={{ color: "#ffffff", padding: "3px" }}
                    className="resultColorGreen"
                  />
                  {" alles ok"}
                </span>
              ) : item.info.status === 2 ? (
                <span className="smallText">
                  <ClearRoundedIcon
                    style={{ color: "#ffffff", padding: "3px" }}
                    className="resultColorOrange"
                  />
                  {" leicht abnormal"}
                </span>
              ) : item.info.status === 3 ? (
                <span className="smallText">
                  <ErrorOutlineRoundedIcon
                    style={{ color: "#ffffff", padding: "3px" }}
                    className="resultColorRed"
                  />
                  {" abnormal"}
                </span>
              ) : null}
            </Grid>
            <Grid>
              <IconButton
                color="primary"
                onClick={() => {
                  this.handleSendEmailDialogOpen();
                }}
              >
                <MailOutlineRoundedIcon />
              </IconButton>
              <IconButton color="primary">
                <PDFDownloadLink
                  document={<PDFDocumentComponent item={item} />}
                  fileName={
                    item.info.dogName.toLowerCase() +
                    "_result_" +
                    item.info.created.substring(0, 10) +
                    ".pdf"
                  }
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <CachedRoundedIcon style={{ color: "#b71c1c" }} />
                    ) : error ? (
                      <Button onClick={error => this.errorLog(error)}>
                        <ErrorRoundedIcon style={{ color: "#b71c1c" }} />
                      </Button>
                    ) : (
                      <GetAppRoundedIcon
                        style={{
                          color: "#4769cc"
                        }}
                      />
                    )
                  }
                </PDFDownloadLink>
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  this.handleDeleteClicked(this.state.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Hidden smDown>
          <DogInfoComponent info={item.info} />
        </Hidden>
        <Hidden mdUp>
          <ExpansionPanel className="infoCard">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className="boldTextInfos">
                Alle Infos über {item.info.dogName}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <DogInfoComponent info={item.info} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Hidden>
        
        {item.info.anamnesisId !== null ? 
          <ExpansionPanel className="infoCard">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              {/* check for status of anamnesis */}
              {anamnesisData.anamnesisState === 1 ? 
                <Grid 
                  className="anamnesisDott" 
                  style={{backgroundColor: "#a0c864"}}></Grid> :
                <Grid 
                  className="anamnesisDott" 
                  style={{backgroundColor: "#ec6f6e"}}></Grid>
              }              
              <Typography className="boldTextInfos">
                Anamnesedaten über {item.info.dogName}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {/* get real anamnesis data */}
              <AnamnesisInfoComponent 
                anamnesis={anamnesisData}
                petId={item.info.petId} />
            </ExpansionPanelDetails>
          </ExpansionPanel> 
          :  
          <Grid container direction="row" alignItems="center" style={{marginTop: "20px", marginBottom: "20px"}}>
            <ErrorRoundedIcon style={{ color: "#b71c1c" }} />
            <strong>keine Anamnese Daten!</strong>
          </Grid>  
        }

            {item.info.hasAlkalineUrine ? 
              <Grid container direction="row" alignItems="center" style={{marginTop: "20px", marginBottom: "20px"}}>
                <ErrorRoundedIcon style={{ color: "#b71c1c" }} />
                <strong>alkalischer Urin</strong>
              </Grid> : null }
          
        <Grid container direction="row" className="nonflex">
          <Grid item xs={12} sm={6} className="nonflex">
            <ParameterDetailComponent
              parameterId={0}
              resultValue={item.result.leukocyte.value}
              resultColor={item.result.leukocyte.color}
              resultNotWetted={item.result.leukocyte.interference === 2}
            />
            <ParameterDetailComponent
              parameterId={1}
              resultValue={item.result.specifics.value}
              resultColor={item.result.specifics.color}
              resultNotWetted={item.result.specifics.interference === 2}
            />
            <ParameterDetailComponent
              parameterId={2}
              resultValue={item.result.ph.value}
              resultColor={item.result.ph.color}
              resultNotWetted={item.result.ph.interference === 2}
            />
            <ParameterDetailComponent
              parameterId={3}
              resultValue={item.result.protein.value}
              resultColor={item.result.protein.color}
              resultNotWetted={item.result.protein.interference === 2}
            />
            <Hidden mdUp>
              <ParameterDetailComponent
                parameterId={4}
                resultValue={item.result.glucose.value}
                resultColor={item.result.glucose.color}
                resultNotWetted={item.result.glucose.interference === 2}
              />
              <ParameterDetailComponent
                parameterId={5}
                resultValue={item.result.ketone.value}
                resultColor={item.result.ketone.color}
                resultNotWetted={item.result.ketone.interference === 2}
              />
              <ParameterDetailComponent
                parameterId={6}
                resultValue={item.result.hemoglobin.value}
                resultColor={item.result.hemoglobin.color}
                resultNotWetted={item.result.hemoglobin.interference === 2}
              />
            </Hidden>
          </Grid>
          <Hidden smDown>
            <Grid item sm={6} className="nonflex">
              <ParameterDetailComponent
                parameterId={4}
                resultValue={item.result.glucose.value}
                resultColor={item.result.glucose.color}
                resultNotWetted={item.result.glucose.interference === 2}
              />
              <ParameterDetailComponent
                parameterId={5}
                resultValue={item.result.ketone.value}
                resultColor={item.result.ketone.color}
                resultNotWetted={item.result.ketone.interference === 2}
              />
              <ParameterDetailComponent
                parameterId={6}
                resultValue={item.result.hemoglobin.value}
                resultColor={item.result.hemoglobin.color}
                resultNotWetted={item.result.hemoglobin.interference === 2}
              />
            </Grid>
          </Hidden>
        </Grid>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-end"
        >
          <StyledButtonEdit
            onClick={event => this.handleEditCheckClick(event, item.info.id)}
            color="primary"
            style={{ marginBottom: "10px" }}
          >
            Ergebnis bearbeiten{"  "}
            <EditRoundedIcon color="secondary" fontSize="small" />
          </StyledButtonEdit>
          {this.state.fromHistory ? (
            <Grid
              container
              direction="column"
              justify="flex-end"
              alignItems="flex-end"
            >
              <StyledButtonCheck
                onClick={() => this.handleNextDetail()}
                color="inherit"
                style={{ marginBottom: "10px" }}
              >
                <Typography>Details von {this.state.nextDetailId} </Typography>
                {"  "}
                <ArrowForwardRoundedIcon color="secondary" fontSize="small" />
              </StyledButtonCheck>
              <StyledButtonCheck onClick={() => this.handleBackToHistory()}>
                <Typography>zurück zur History</Typography>
              </StyledButtonCheck>
            </Grid>
          ) : this.state.nextEditId !== 0 ? (
            <StyledButtonCheck onClick={() => this.handleNextCheckClick()}>
              <Typography>
                Nächsten Check auswerten
                <EditRoundedIcon color="secondary" fontSize="small" />
              </Typography>
            </StyledButtonCheck>
          ) : null}
        </Grid>
        {this.state.openEmailDialog ? (
          <Dialog
            open={this.state.openEmailDialog}
            onClose={event => this.handleSendEmailDialogClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Möchtest du das Testergebnis wirklich an {item.info.mail} senden?
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={() => this.handleSendEmailDialogClose()}
                color="primary"
              >
                NEIN
              </Button>
              <Button
                onClick={() => this.handleSendMailClicked(item)}
                color="primary"
              >
                JA, EMAIL SENDEN
              </Button>
            </DialogActions>
          </Dialog>
        ) : this.state.openErrorDialog ? (
          <Dialog
            open={this.state.openEmailDialog}
            onClose={event => this.closeErrorDialog()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Shoops.. Beim versenden von einer Email an {item.info.mail} ist
              ein fehler passiert. <br />
              Versuch es bitte noch einmal.
            </DialogTitle>
            <DialogActions>
              <Button onClick={() => this.closeErrorDialog()} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </Grid>
    ) : (
      <DetailPageSkeletonComponent />
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    items: state.developItems,
    testValues: state.testValues,
    authToken: state.authToken
  };
};

export const DetailPageContainer = connect(mapStateToProps)(DetailPage);
