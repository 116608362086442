import React, { Component } from "react";
import { DevelopItem, TestValueItem } from "../data/Data";
import { setUnfinishedItems, State } from "../store";
import { connect } from "react-redux";
import { fetchUnfinishedItems, deleteItem, fetchDevelopItems } from "../api";
import CheckListComponentFilter from "../components/CheckListComponent/CheckListComponentFilter";
import TableComponentSkeleton from "../components/LoadComponents/TableComponentSkeleton";
import { Grid, Typography } from "@material-ui/core";
import StatisticCardComponent from "../components/StatisticCardComponent/StatisticCardComponent";
import "./style.css";
import Hidden from "@material-ui/core/Hidden";
import * as StatisticsFile from "../data/Statistics";

interface UnfinishedPageProps {
  items: DevelopItem[];
  testValues: TestValueItem[];
  authToken: any;
  dispatch: any;
}

let timer;

class UnfinishedPage extends Component<UnfinishedPageProps> {
  state = {
    limit: 100,
    offset: 0,
    allItems: [],
    testItems: [],
    unfinishedItems: [],
    unfinishedChecksWeek: 0,
    week: 0,
    allChecks: 0,
    averageWeek: 0,
    successful: false,
  };

  handleDeleteClick() {
      this.fetchUnfinishedData();
  }

  getStatistics = () => {
    const statistics: StatisticsFile.Statistics = new StatisticsFile.Statistics();

    if (this.state.allItems.length != 0) {
      const thisWeek = statistics.getChecksOfWeek(this.state.allItems);
      const averageWeek =
        Math.round((this.state.allItems.length / 52) * 100) / 100;

      this.setState({
        week: thisWeek,
        allChecks: this.state.allItems.length,
        averageWeek: averageWeek
      });
    }
  };

  componentDidMount(): void {
    this.fetchUnfinishedData();
    //fetch data every 10 Minutes
    timer = setInterval(() => this.fetchUnfinishedData(), 600000);
  }

  componentWillUnmount() {
    clearInterval(timer);
    timer = null;
  }

  fetchUnfinishedData() {
    const { testValues, authToken } = this.props;
    if (testValues && !testValues.length) {
      fetchUnfinishedItems()
        .then(unf => {
          this.props.dispatch(setUnfinishedItems(unf.items));
          this.setState({
            successful: true,
            unfinishedItems: unf.items,
            allItems: unf.items
          });
        })
        .then(data => {
          // @ts-ignore
          fetchDevelopItems(this.state.limit, this.state.offset).then(dev => {
            dev.items.map(item => {
              this.setState({
                allItems: [...this.state.allItems, item]
              });
            });

            this.getStatistics();
          });
        }).catch((error) => {
          this.setState({
            successful: false,
          })
        });
    }
  }

  render() {
    return (
      <Grid>
        <Grid container direction="row" justify="center" alignItems="stretch" >
          <Hidden mdDown>
            <StatisticCardComponent
              card={1}
              header={"Alle Ergebnisse im Überblick"}
              smallHeader="Pezz Check History"
              buttonText="zu den Ergebnissen"
              buttonLink="\history"
              smallText={this.state.allItems.length + " gesamt"}
              checks={0}
            />
            <StatisticCardComponent
              card={2}
              header="Checks diese Woche"
              smallHeader="Analytics"
              buttonText="Analysen zeigen"
              buttonLink="\statistics"
              smallText={"ø " + this.state.averageWeek + " pro Woche"}
              checks={this.state.week}
            />
            <StatisticCardComponent
              card={3}
              header="Organisation im Arbeitsalltag"
              smallHeader="Interne Links"
              buttonText="zur Doku"
              buttonLink="\links"
              smallText={"Entdecke das Pezz Wiki"}
              checks={0}
            />
          </Hidden>
        </Grid>
        <div className="newChecksDiv"></div>
        <div className="newChecksDiv">
          <Typography component="p">
            <span className="text">Neu eingetroffene Pezz Tests </span>
            <span className="blueText">
              {this.props.items.length}
              <br />
            </span>
            <span className="detailText">
              Die noch nicht ausgewertet wurden.
            </span>
          </Typography>
        </div>
        <div className="list">
          {/* {this.state.unfinishedItems.length != 0 ? ( */}
          {this.state.successful ? (
            <CheckListComponentFilter
              items={this.state.unfinishedItems}
              showResult={false}
              authToken={this.props.authToken}
              handleDeleteClicked={id => this.handleDeleteClick()}
            />
          ) : (
            <TableComponentSkeleton/>
          )}
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    items: state.unfinishedItems,
    testValues: state.testValues,
    authToken: state.authToken
  };
};

export const UnfinishedPageContainer = connect(mapStateToProps)(UnfinishedPage);
