import React, { Component } from "react";
import {
  Grid,
  Hidden
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

interface DetailPageSkeletonComponentProps {
}

class DetailPageSkeletonComponent extends Component<
DetailPageSkeletonComponentProps
> {
  render() {
    return (
        <Grid>
        <Hidden smDown>
          <Grid container style={{ width: "100%" }}>
            <Skeleton
              variant={"rect"}
              width="100%"
              height={60}
              style={{ marginBottom: 10 }}
            />

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              style={{ marginTop: "30px" }}
            >
              <Grid item xs={6}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ marginBottom: 10 }}
                >
                  <Grid item xs={1}>
                    <Skeleton variant={"rect"} width={45} height={45} />
                  </Grid>
                  <Grid item xs={10}>
                    <Skeleton variant="text" height={35} />
                    <Skeleton variant="text" height={25} />
                    <Skeleton variant="text" height={25} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ marginBottom: 10 }}
                >
                  <Grid item xs={1}>
                    <Skeleton variant={"rect"} width={45} height={45} />
                  </Grid>
                  <Grid item xs={10}>
                    <Skeleton variant="text" height={35} />
                    <Skeleton variant="text" height={25} />
                    <Skeleton variant="text" height={25} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ marginBottom: 10 }}
                >
                  <Grid item xs={1}>
                    <Skeleton variant={"rect"} width={45} height={45} />
                  </Grid>
                  <Grid item xs={10}>
                    <Skeleton variant="text" height={35} />
                    <Skeleton variant="text" height={25} />
                    <Skeleton variant="text" height={25} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ marginBottom: 10 }}
                >
                  <Grid item xs={1}>
                    <Skeleton variant={"rect"} width={45} height={45} />
                  </Grid>
                  <Grid item xs={10}>
                    <Skeleton variant="text" height={35} />
                    <Skeleton variant="text" height={25} />
                    <Skeleton variant="text" height={25} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ marginBottom: 10 }}
                >
                  <Grid item xs={1}>
                    <Skeleton variant={"rect"} width={45} height={45} />
                  </Grid>
                  <Grid item xs={10}>
                    <Skeleton variant="text" height={35} />
                    <Skeleton variant="text" height={25} />
                    <Skeleton variant="text" height={25} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ marginBottom: 10 }}
                >
                  <Grid item xs={1}>
                    <Skeleton variant={"rect"} width={45} height={45} />
                  </Grid>
                  <Grid item xs={10}>
                    <Skeleton variant="text" height={35} />
                    <Skeleton variant="text" height={25} />
                    <Skeleton variant="text" height={25} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid container style={{ width: "100%" }}>
            <Skeleton
              variant={"rect"}
              width="100%"
              height={60}
              style={{ marginBottom: 10 }}
            />
            <Skeleton
              variant={"rect"}
              width="100%"
              height={60}
              style={{ marginBottom: 10 }}
            />
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ marginBottom: 10 }}
            >
              <Grid item xs={1}>
                <Skeleton variant={"rect"} width={45} height={45} />
              </Grid>
              <Grid item xs={10}>
                <Skeleton variant="text" height={35} />
                <Skeleton variant="text" height={25} />
                <Skeleton variant="text" height={25} />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ marginBottom: 10 }}
            >
              <Grid item xs={1}>
                <Skeleton variant={"rect"} width={45} height={45} />
              </Grid>
              <Grid item xs={10}>
                <Skeleton variant="text" height={35} />
                <Skeleton variant="text" height={25} />
                <Skeleton variant="text" height={25} />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ marginBottom: 10 }}
            >
              <Grid item xs={1}>
                <Skeleton variant={"rect"} width={45} height={45} />
              </Grid>
              <Grid item xs={10}>
                <Skeleton variant="text" height={35} />
                <Skeleton variant="text" height={25} />
                <Skeleton variant="text" height={25} />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ marginBottom: 10 }}
            >
              <Grid item xs={1}>
                <Skeleton variant={"rect"} width={45} height={45} />
              </Grid>
              <Grid item xs={10}>
                <Skeleton variant="text" height={35} />
                <Skeleton variant="text" height={25} />
                <Skeleton variant="text" height={25} />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ marginBottom: 10 }}
            >
              <Grid item xs={1}>
                <Skeleton variant={"rect"} width={45} height={45} />
              </Grid>
              <Grid item xs={10}>
                <Skeleton variant="text" height={35} />
                <Skeleton variant="text" height={25} />
                <Skeleton variant="text" height={25} />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ marginBottom: 10 }}
            >
              <Grid item xs={1}>
                <Skeleton variant={"rect"} width={45} height={45} />
              </Grid>
              <Grid item xs={10}>
                <Skeleton variant="text" height={35} />
                <Skeleton variant="text" height={25} />
                <Skeleton variant="text" height={25} />
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    );
  }
}

export default DetailPageSkeletonComponent;
