import React, { Component } from "react";
import { Grid, Typography, IconButton } from "@material-ui/core";
import DeviceTable from "../../DeviceTable/DeviceTable";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

interface DevicePageComponentProps {}

class DevicePage extends Component<DevicePageComponentProps> {
  handleButtonClick() {
    window.location.replace("/dashboard/links");
  }

  render() {
    return (
      <Grid container direction="row">
        <Grid item xs={1}>
          <IconButton onClick={() => this.handleButtonClick()}>
            <ArrowBackIosRoundedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} style={{marginTop: "20px"}}>
          <Typography variant="h5" className="textStyle" gutterBottom>
            Hier findest du alle Geräte die bei PEZZ vorhanden sind.
          </Typography>
          <DeviceTable />
        </Grid>
      </Grid>
    );
  }
}

export const DevicePageContainer = DevicePage;
