import React, { Component } from "react";
import {
  Grid,
  IconButton,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import androidLifecycle from "../../../assets/androidLifecycle.png";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./style.css";

interface AndroidDokuComponentProps {}

class AndroidPage extends Component<AndroidDokuComponentProps> {
  handleButtonClick() {
    window.location.replace("/dashboard/links");
  }

  render() {
    return (
      <Grid container direction="row" className="buttonContainer">
        <Grid item xs={1}>
          <IconButton
            onClick={() => this.handleButtonClick()}
            style={{ marginTop: "20px" }}
          >
            <ArrowBackIosRoundedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11}>
          <Typography variant="h3" style={{ marginTop: "20px" }}>
            Pezz Android
          </Typography>
          <Typography variant="h4" style={{ marginTop: "30px" }}>
            Android Dokumentation
          </Typography>
          <p>
            Hier findest du alle wichtigen Informationen über Android selbst und
            über unser Projekt.
          </p>

          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5" className="textStyle">
                Die Android Pezz App
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid direction="column">
                <p>
                  Das Android Pezz Projekt verwendet folgende Technologien:
                  <ul>
                    <li>Kotlin als Programmiersprache</li>
                    <li>
                      Firebase für den Login, Push Notifications und Storage
                    </li>
                    <li>Dagger 2 als Dependency Injection</li>
                    <li>Retrofit/OkHttp/Gson for networking</li>
                    <li>Realm für local data storage</li>
                    <li>MVVM als Architekturpattern</li>
                    <li>Swagger als API Dokumentation</li>
                    <li>Zepplin für Design und Content</li>
                  </ul>
                </p>
                <p>
                  Veröffentlicht wird die App über den Google Play Store: <br />
                  <ul>
                    <li>
                      <a href="https://play.google.com/apps/publish/">
                        Google Play Console
                      </a>
                    </li>
                    <li>
                      <a href="https://play.google.com/store/apps/details?id=com.pezzapp.android">
                        Pezz Play Store Eintrag
                      </a>
                    </li>
                  </ul>
                </p>
                <p>
                  Durch einen Fehler in der App Beschreibung wurde unsere erste
                  App (Packagename: com.pezz.android) von Google gesperrt.
                  Deswegen haben wir eine neue eingereicht (Packagename:
                  com.pezzapp.android). Diese wurde ohne Probleme angenommen.
                </p>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5">Android General Guide</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid direction="column">
                <a href="https://developer.android.com/guide/">
                  Alles über Android
                </a>
                <p>
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    <a href="https://developer.android.com/jetpack">
                      Android Jetpack
                    </a>
                  </Typography>
                  <br />
                  ist eine Sammlung von Bibliotheken und Werkzeugen, mit denen
                  die Entwicklung von Android-Apps laut offizieller
                  Dokumentation leichter gelingen soll. Android Jetpack besteht
                  aus vier Kategorien:
                  <ul>
                    <li>
                      Foundation: Das Fundament der App-Entwicklung wird u. a.
                      durch Werkzeuge zur Sicherung der Abwärtskompatibilität
                      und erweiterte Kotlin-Sprachunterstützung mit Android KTX
                      gelegt.
                    </li>
                    <li>
                      Architecture: Die wichtigen Architekturkomponenten wie
                      ViewModel, LiveData und Room für die Strukturierung
                      moderner Android-Apps sind hier enthalten und werden durch
                      die neuen Ansätze zur Navigation ergänzt.
                    </li>
                    <li>
                      Behavior: In dieser Kategorie befinden sich Bibliotheken,
                      die das Zusammenspiel mit unterschiedlichen
                      Android-Diensten regeln (z. B. für Benachrichtigungen).
                    </li>
                    <li>
                      UI: Die UI-Komponenten umfassen die verschiedenen Layouts
                      und weitere Ansätze zur Gestaltung attraktiver
                      Android-Apps.
                    </li>
                  </ul>
                  <p>
                    Intro Video:{" "}
                    <a href="https://www.youtube.com/watch?v=LmkKFCfmnhQ&feature=youtu.be">
                      Youtube
                    </a>
                  </p>
                  Quelle:
                  <a href="https://www.informatik-aktuell.de/entwicklung/programmiersprachen/mit-android-jetpack-die-app-entwicklung-beschleunigen.html">
                    {" "}
                    Informatik Aktuell
                  </a>
                </p>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5" className="textStyle">
                Android Lifecycle
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid direction="column">
                <a
                  href="https://developer.android.com/guide/components/activities/activity-lifecycle"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Erklärung
                </a>
                <img src={androidLifecycle} alt="Android Lifecycle"/>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5" className="textStyle">
                Android Architektur
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid direction="column">
                Ein Artikel über Android Architecture findest du{" "}
                <a
                  href="https://www.vogella.com/tutorials/AndroidArchitecture/article.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  hier
                </a>
                .
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5" className="textStyle">
                TA Template
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid direction="column">
                <p>
                  Das Projekt basiert auf dem{" "}
                  <a href="https://github.com/tailoredmedia/AndroidTemplate">
                    Template
                  </a>
                  .
                </p>
                <p>
                  verwendete Technologien
                  <ul>
                    <li>Kotlin </li>
                    <li>Dagger 2 for dependency injection</li>
                    <li>Retrofit/OkHttp/Gson for networking</li>
                    <li>Realm for local data storage</li>
                    <li>MVVM as architectural pattern</li>
                  </ul>
                </p>
                <p>
                  App Strucktur
                  <ul>
                    <li>
                      Dagger components, modules, scopes and qualifiers for
                      dependency injection are located in the injection package.{" "}
                    </li>
                    <li>
                      Model classes are located in the data.model package.
                    </li>
                    <li>
                      Network related classes and interfaces (e.g. Retrofit
                      services) are located in the data.remote package.
                    </li>
                    <li>
                      Local storage related classes (e.g. repositories) are
                      located in the data.local package.
                    </li>
                    <li>
                      UI related classes (Activities, Fragments, Views, ...) are
                      located in the ui package. For each aspect of an app
                      create a new subpackage. Use the interfaces and abstract
                      classes from the base subpackage.
                    </li>
                    <li>Util classes are located in the util package.</li>
                  </ul>
                </p>
                <p>
                  empfohlenes Lesematerial:
                  <ul>
                    <li>
                      {" "}
                      <a href="https://nullpointer.wtf/android/mvvm-architecture-data-binding-library/">
                        MVVM architecture with the data binding library
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a href="https://nullpointer.wtf/android/using-retrofit-realm-parceler/">
                        Using Retrofit with Realm and Parceler
                      </a>
                    </li>
                  </ul>
                </p>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5" className="textStyle">
                Kotlin
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid direction="column">
                <ul>
                  <li>
                    <a href="https://proandroiddev.com/modern-android-development-with-kotlin-september-2017-part-1-f976483f7bd6">
                      Kotlin mit Android und MVVM
                    </a>
                  </li>
                  <li>
                    <a href="https://play.kotlinlang.org/" target="_blank" rel="noopener noreferrer">
                      Kotlin Playground
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://kotlinlang.org/docs/reference/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kotlinreferenzen
                    </a>
                  </li>
                  <li>
                    <a href="https://typealias.com/guides/" target="_blank" rel="noopener noreferrer">
                      Koltin Guide
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.oreilly.com/library/view/kotlin-in-action/9781617293290/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kotlin in Action
                    </a>
                  </li>
                </ul>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5">Material Design</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <p>
                Material Design ist eine vom Unternehmen Google Inc. entwickelte
                Designsprache. Mehr Infos{" "}
                <a href="https://material.io/">hier</a>.
              </p>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5" className="textStyle">
                LeakCanary
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                <a href="https://square.github.io/leakcanary/" target="_blank" rel="noopener noreferrer">
                  LeakCanary{" "}
                </a>
                is a powerful tool to detect{" "}
                <a
                  href="https://square.github.io/leakcanary/fundamentals/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  memory leaks
                </a>
                .
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5" className="textStyle">
                RX
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid>
                <ul>
                  <li>
                    <a href="https://www.toptal.com/android/functional-reactive-android-rxjava">
                      RXJava
                    </a>
                  </li>
                  <li>
                    <a href="http://reactivex.io/intro.html" target="_blank" rel="noopener noreferrer">
                      Reactive Extensions
                    </a>
                  </li>
                  <li>
                    <a href="https://rxmarbles.com/" target="_blank" rel="noopener noreferrer">
                      rxmarbles
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ayusch.com/the-complete-rxjava-roadmap/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      RX Roadmap
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.vogella.com/tutorials/RxJava/article.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      RXJava Tutorial
                    </a>
                  </li>
                </ul>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5" className="textStyle">
                General Programming
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid direction="column">
                <ul>
                  <li>
                    <a
                      href="https://publicobject.com/2019/06/10/value-objects-service-objects-and-glue/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Object
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://kea.nu/files/textbooks/new/Effective%20Java%20%282017%2C%20Addison-Wesley%29.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Effective Java
                    </a>
                  </li>
                </ul>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5" className="textStyle">
                Git
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid direction="column">
                Als Versions-Kontroll-System verwenden wir git.
                <ul>
                  <li>
                    <a href="https://git-scm.com/videos" target="_blank" rel="noopener noreferrer">
                      Video intro
                    </a>
                  </li>
                  <li>
                    <a href="https://rogerdudler.github.io/git-guide/index.de.html" target="_blank" rel="noopener noreferrer">
                      Git guide
                    </a>
                  </li>
                  <li>
                    <a href="https://git-scm.com/docs" target="_blank" rel="noopener noreferrer">
                      Reference docs
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://github.github.com/training-kit/downloads/github-git-cheat-sheet.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Git cheat sheet
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://ndpsoftware.com/git-cheatsheet.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visual cheat sheet
                    </a>
                  </li>
                </ul>
                Reading material for Git
                <ul>
                  <li>Git flow</li>
                  <ul>
                    <li>
                      <a
                        href="https://www.atlassian.com/git/tutorials/comparing-workflows/gitflow-workflow"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Workflow
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.innoq.com/de/blog/continuous-integration-contradicts-feature-branches/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Feature Branches
                      </a>
                    </li>
                  </ul>
                  <li>
                    Advanced
                    <ul>
                      <li>
                        <a
                          href="https://medium.com/retailmenot-engineering/better-code-review-part-one-ae3d4ff0494d"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Better Code Review Part One
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://medium.com/retailmenot-engineering/better-code-review-part-two-92f17ee42c56"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Better Code Review Part Two{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://medium.com/retailmenot-engineering/better-code-review-part-3-4efb568885"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Better Code Review Part Three{" "}
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5" className="textStyle">
                Networking
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid direction="column">
                <Typography
                  variant="h6"
                  className="textStyle"
                  style={{ fontWeight: "bold" }}
                >
                  Retrofit
                </Typography>
                <ul>
                  <li>
                    Vogella:{" "}
                    <a
                      href="https://www.vogella.com/tutorials/Retrofit/article.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Retrofit Tutorial
                    </a>
                  </li>
                  <li>
                    General:{" "}
                    <a
                      href="https://github.com/square/retrofit/wiki"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Retrofit Wiki
                    </a>
                  </li>
                </ul>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5" className="textStyle">
                Storage
              </Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
              <Grid direction="column">
                <Typography
                  variant="h6"
                  className="textStyle"
                  style={{ fontWeight: "bold" }}
                >
                  Room
                </Typography>
                <ul>
                  <li>
                    <a
                      href="https://developer.android.com/topic/libraries/architecture/room"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Android Room
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://codelabs.developers.google.com/codelabs/android-room-with-a-view/#0"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Room with a View
                    </a>
                  </li>
                  <li>
                    A little older, but still good for basics:
                    <ul>
                      <li>
                        <a
                          href="https://medium.com/androiddevelopers/7-steps-to-room-27a5fe5f99b2"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          7 Steps to Room
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://medium.com/androiddevelopers/7-pro-tips-for-room-fbadea4bfbd1"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          7 pro Tips for room
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://medium.com/androiddevelopers/understanding-migrations-with-room-f01e04b07929"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          understanding migrations with room
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5" className="textStyle">
                Firebase
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid direction="column">
                <ul>
                  <li>
                    <a
                      href="https://proandroiddev.com/firebase-android-series-learning-firebase-from-zero-to-hero-3bacbdf8e048"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      General Firebase
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://proandroiddev.com/firebase-android-series-firestore-17e8951c574e"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Firebase Firestore
                    </a>
                  </li>
                </ul>
                <ExpansionPanel className="infoCard">
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    Firebase Doku
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>firebase things</ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5" className="textStyle">
                Crashlytics
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid direction="column">
                <p>
                  <code>CrashlyticsTree</code> tracks errors and warnings that
                  are logged with Timber as non-fatals on Fabric or Firebase
                  Crashlytics.
                </p>
                <h2 id="requirement">Requirement</h2>
                <ul>
                  <li>
                    <a
                      href="https://github.com/JakeWharton/timber"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Timber
                    </a>
                  </li>
                  <li>
                    <a href="https://fabric.io/" target="_blank" rel="noopener noreferrer">
                      Fabric
                    </a>{" "}
                    or{" "}
                    <a
                      href="https://firebase.google.com/docs/crashlytics/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Firebase Crashlytics
                    </a>
                  </li>
                </ul>
                <h2 id="usage">Usage</h2>
                <p>
                  Add <code>CrashlyticsTree</code> to your project.
                </p>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5" className="textStyle">
                Facebook
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <p>Als zweite Login-Möglichkeit verwenden wir Facebook.</p>
              <a href="https://developers.facebook.com/apps/264174884188188/settings/basic/">
                {" "}
                Facebook Developer
              </a>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5" className="textStyle">
                Swagger
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid direction="column">
                <p>
                  Swagger hilft das BAckend zu verstehen und die richtigen API
                  anfragen abzusenden. <br />
                  Es ist eine Dokumentation für alle API-Calls für die
                  unterschiedlichen Bachendanbindungen:{" "}
                </p>
                <ul>
                  <li>
                    <a href="https://pezz-dev.tailored-apps.com/api/v1/docs/swagger.yml">
                      DEV
                    </a>
                  </li>
                  <li>
                    <a href="https://pezz-stage.tailored-apps.com/api/v1/docs/swagger.yml">
                      STAGE
                    </a>
                  </li>
                  <li>
                    <a href="https://pezz.tailored-apps.com/api/v1/docs/swagger.yml">
                      LIVE
                    </a>
                  </li>
                </ul>
                <p>
                  Unter v1 findet man alle API Calls die für die App wichtig
                  sind.
                </p>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5" className="textStyle">
                Zeplin
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid direction="column">
                <p>
                  In Zeplin wird das Design für die App dargestellt. Durch
                  Codesnippets und Color variablen, kann man schnell und einfach
                  Designvorlagen nachbauen. Damit jeder das Design einhaltet.
                </p>

                <a href="https://zpl.io/bJqZmjD">
                  Unser Android Design auf Zeplin
                </a>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Grid>
    );
  }
}

export const AndroidPageContainer = AndroidPage;
