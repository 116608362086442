import React, { Component } from "react";
import { State } from "../store";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { DevelopItem, TestValueItem } from "../data/Data";
import * as StatisticsFile from "../data/Statistics";
import { Doughnut, Bar } from "react-chartjs-2";
import "./style.css";
import CountUp from "react-countup";
import { fetchDevelopItems, fetchUnfinishedItems } from "../api";

let sexData = {};
let castratedData = {};
let checkData = {};
let barCheckData = {};
let breedData = {};
let dogHybridData = {};
let illnessData = {};
let nameData = {};
let dogIllnessData = {};
let uniqueItems;
let numberOfDogs;
let checksThisYear;
let checksLastYear;
let checksThisMonth;
let checksLastMonth;
let checksThisWeek;
let checksLastWeek;
let averageTime;
let avergaeAge;
let mostBreed;
let mostCommonName;
let illnessCount;

interface StatisticsPageProps {
  items: DevelopItem[];
  item: DevelopItem;
  testValues: TestValueItem[];
  authToken: any;
  dispatch: any;
}

class StatisticsPage extends Component<StatisticsPageProps> {
  state = {
    limit: 10000,
    offset: 0,
    allItems: [],
    unfinishedItems: [],
    developItems: [],
    allData: false
  };

  getItemForStatistics() {
    fetchDevelopItems(this.state.limit, this.state.offset).then(
      (dataDevelop: any) => {
        dataDevelop.items.map(item => {
          this.setState({
            allItems: [...this.state.allItems, item],
            developItems: [...this.state.developItems, item]
          });
        });

        fetchUnfinishedItems()
          .then((dataUnfinished: any) => {
            dataUnfinished.items.map((item: DevelopItem) => {
              this.setState({
                allItems: [...this.state.allItems, item],
                unfinishedItems: [...this.state.unfinishedItems, item]
              });
            });
          })
          .then(data => {
            this.getStatisticData();
          });
      }
    );
  }

  getStatisticData() {
    const statistics: StatisticsFile.Statistics = new StatisticsFile.Statistics();

    uniqueItems = statistics.getUniqueIds(this.state.allItems);

    numberOfDogs = statistics.getNumberOfDogs(this.state.allItems);
    const female = statistics.getFemaleDogs(this.state.allItems);
    const male = statistics.getMaleDogs(this.state.allItems);
    sexData = {
      datasets: [
        {
          data: [female, male],
          backgroundColor: ["#ff80bf", "#4ddbff"]
        }
      ],
      labels: ["Weiblich", "Männlich"]
    };

    const greenCheck = statistics.getChecksGreen(this.state.allItems);
    const orangeCheck = statistics.getCheckOrange(this.state.allItems);
    const redCheck = statistics.getChecksRed(this.state.allItems);
    const status5Check = statistics.getCheckStatus5(this.state.allItems);
    checkData = {
      datasets: [
        {
          data: [greenCheck, orangeCheck, redCheck, status5Check],
          backgroundColor: ["#2eb82e", "#e67300", "#b30000"]
        }
      ],
      labels: ["Green", "Orange", "Red"]
    };

    const castratedPercentage = statistics.getCastratedPercentage(
      this.state.allItems
    );
    const notCastrated = 100 - castratedPercentage;
    castratedData = {
      datasets: [
        {
          data: [castratedPercentage, notCastrated],
          backgroundColor: ["#c21ddb"]
        }
      ],
      labels: ["kastriert", "nicht kastriert"]
    };

    const hybridsCount = statistics.getPercentageOfDogHybrid(
      this.state.allItems
    );
    const noHybrids = 100 - hybridsCount;
    dogHybridData = {
      datasets: [
        {
          data: [hybridsCount, noHybrids],
          backgroundColor: ["#660066"]
        }
      ],
      labels: ["Mischlingshunde", "Rassenhunde"]
    };

    mostBreed = statistics.getMostBreed(this.state.allItems);
    mostCommonName = statistics.getDogName(this.state.allItems);

    const illnessCount = statistics.getDogsWithIllness(this.state.allItems);
    const withoutIllness = statistics.getDogsWithOutIllness(this.state.allItems);
    const moreThanOneIllness = statistics.getDogsWithMoreThanOneIllness(
      this.state.allItems
    );
    const noIllness = withoutIllness - illnessCount - moreThanOneIllness;
    dogIllnessData = {
      datasets: [
        {
          data: [illnessCount, moreThanOneIllness, withoutIllness],
          backgroundColor: ["#003366", "#007a99"]
        }
      ],
      labels: [
        "eine Vorerkrankung",
        "mehr als eine Vorerkrankung",
        "keine Vorerkrankung"
      ]
    };

    averageTime = statistics.getTimeBetweenCreatedAndUpdated(
      this.state.allItems
    );
    avergaeAge = statistics.getDogAverageAge(this.state.allItems);

    //get all checks of year, month, week
    checksThisYear = statistics.getChecksOfYear(this.state.allItems);
    checksLastYear = statistics.getChecksOfLastYear(this.state.allItems);
    checksThisMonth = statistics.geChecksOfMonth(this.state.allItems);
    checksThisWeek = statistics.getChecksOfWeek(this.state.allItems);

    //all green checks green, orange, red with different positions
    const greenZero = statistics.getCheckGreenZero(this.state.allItems);
    const greenFirst = statistics.getCheckGreenFirst(this.state.allItems);
    const greenSecond = statistics.getCheckGreenSecond(this.state.allItems);

    const orangeZero = statistics.getCheckOrangeZero(this.state.allItems);
    const orangeFirst = statistics.getCheckOrangeFirst(this.state.allItems);
    const orangeSecond = statistics.getCheckOrangeSecond(this.state.allItems);

    const redZero = statistics.getCheckRedZero(this.state.allItems);
    const redFirst = statistics.getCheckRedFirst(this.state.allItems);
    const redSecond = statistics.getCheckRedSecond(this.state.allItems);

    barCheckData = {
      labels: [
        "Grün 0",
        "Grün 1",
        "Grün 2",
        "Orange 0",
        "Orange 1",
        "Orange 2",
        "Red 0",
        "Red 1",
        "Red 2"
      ],
      datasets: [
        {
          backgroundColor: [
            "#00cc00",
            "#009900",
            "#006600",
            "#ffb84d",
            "#ff9900",
            "#e68a00",
            "#ff4000",
            "#cc3300",
            "#992600"
          ],
          label: "",
          borderColor: "#FFFFFF",
          borderWidth: 1,
          data: [
            greenZero,
            greenFirst,
            greenSecond,
            orangeZero,
            orangeFirst,
            orangeSecond,
            redZero,
            redFirst,
            redSecond
          ]
        }
      ]
    };

    this.setState({
      allData: true
    });
  }

  componentDidMount() {
    this.getItemForStatistics();
  }

  render() {
    return (
      <Grid container direction="column">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className="statisticsContainer"
        >
          <Grid item xs={8} >
            <span className="text">
              Tests in Ergebnis und Testposition unterteilt
            </span>
            <Bar
              data={barCheckData}
              legend={{ position: "bottom" }}
              options={{
                maintainAspectRatio: false
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className="statisticsContainer"
        >
          <Grid item xs={8} sm={4}>
            <span className="textStatistics">Hudegeschlecht</span>
            <Doughnut
              data={sexData}
              legend={{ position: "bottom" }}
              options={{
                maintainAspectRatio: false
              }}
            />
          </Grid>
          <Grid item xs={8} sm={4}>
            <span className="textStatistics">Check Ergebnisse</span>
            <Doughnut
              data={checkData}
              legend={{ position: "bottom" }}
              options={{
                maintainAspectRatio: false
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className="statisticsContainer"
        >
          <Grid
            item
            xs={8} sm={4}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <span className="textStatistics">Kastrierten Hunde</span>
            <Doughnut
              data={castratedData}
              legend={{ position: "bottom" }}
              options={{
                maintainAspectRatio: false
              }}
            />
          </Grid>
          <Grid item xs={8} sm={4}>
            <span className="textStatistics">
              Hunde mit einer Vorerkrankung
            </span>
            <Doughnut
              data={dogIllnessData}
              legend={{ position: "bottom" }}
              options={{
                maintainAspectRatio: false
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className="statisticsContainer"
        >
          <Grid item xs={8} sm={4}>
            <span className="textStatistics">Mischlingshunde</span>
            <Doughnut
              data={dogHybridData}
              legend={{ position: "bottom" }}
              options={{
                maintainAspectRatio: false
              }}
            />
          </Grid>
          <Grid item xs={8} sm={4}></Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className="statisticsContainer"
        >
          <Grid item xs={6}>
            <span className="textStatistics">Hundeanzahl: {numberOfDogs}</span>
          </Grid>
          <Grid item xs={6}>
            <span className="textStatistics">
              Durchschnittliche Dauer der Auswertung {averageTime} Sekunden
            </span>
          </Grid>
          <Grid item xs={6}>
            <span className="textStatistics">
              Tests in diesem Jahr: {checksThisYear}
            </span>
          </Grid>
          <Grid item xs={6}>
            <span className="textStatistics">
              Tests im letzten Jahr: {checksLastYear}
            </span>
          </Grid>
          <Grid item xs={6}>
            <span className="textStatistics">
              Tests in diesem Monat: {checksThisMonth}
            </span>
          </Grid>
          <Grid item xs={6}>
            <span className="textStatistics">
              Tests in dieser Woche: {checksThisWeek}
            </span>
          </Grid>
          <Grid item xs={6}>
            <span className="textStatistics">
              Durchschnittliches Alter der Hunde: {avergaeAge}
            </span>
          </Grid>
        </Grid>
        {/* <Grid container direction="row" justify="center" alignItems="center" className="statisticsContainer">
    <Grid item xs={8} sm={4}><span className="text">Die häufigste Rasse {mostBreed}</span><Doughnut data={breedData} /></Grid>
        <Grid item xs={8} sm={4}><span className="text">Die häufigste Krankheit</span><Doughnut data={illnessData} /></Grid>
    <Grid item xs={8} sm={4}><span className="text">Der häufigste Name {mostCommonName}</span><Doughnut data={nameData} /></Grid>
      </Grid> */}
      </Grid>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    items: state.unfinishedItems,
    testValues: state.testValues,
    authToken: state.authToken
  };
};

export const StatisticsPageContainer = connect(mapStateToProps)(StatisticsPage);
