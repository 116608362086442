import React, { FunctionComponent } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import { DevelopPageContainer } from "./develop/DevelopPage";
import { UnfinishedPageContainer } from "./unfinished/UnfinishedPage";
import { EditPageContainer } from "./edit/EditPage";
import { DetailPageContainer } from "./detail/DetailPage";
import { LinksPageContainer } from "./links/LinksPage";
import { StatisticsPageContainer } from "./statistics/StatisticsPage";
import { ImpressumPageContainer } from "./impressum/ImpressumPage";
import { DataPageContainer } from "./dataprotectioninfos/DataPage";
import { ContactPageContainer } from "./contact/ContactPage";
import { AndroidPageContainer } from "./components/docu/AndroidDoku/AndroidDokuComponent";
import { BasePageContainer } from "./components/docu/BASEDoku/BASEDoku";
import { IOSPageContainer } from "./components/docu/iOSDoku/iOSDokuComponent";
import { CompanyPageContainer } from "./components/docu/CompanyPage/CompanyPage";
import { DevicePageContainer } from "./components/docu/DevicePage/DevicePage";
import { BackendDokuPageContainer } from "./components/docu/BackendDoku/BackendDokuComponent";
import { BreedPageContainer } from "./breeds/BreedPageComponent";
import { WebsitePageContainer } from "./components/docu/WebsiteDocu/WebsiteDocuPage";
import HeaderBarComponent from "./components/navigation/HeaderBarComponent/HeaderBarComponent";
import { CustomAppBarWithRouter } from "./components/navigation/SideTabsComponent/SideTabsComponent";
import {
  AppBar,
  MuiThemeProvider,
  Grid,
  Toolbar,
  IconButton,
  Drawer,
  Hidden
} from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { LoginPageContainer } from "./authentication/LoginPage";
import { ProtectedRoute } from "./authentication/ProtectedRoute";
import MenuIcon from "@material-ui/icons/Menu";
import { State } from "./store";
import { connect } from "react-redux";
import { Constants } from "./data/Constants";
import darkLogo from "./assets/logo_dark.png";
import * as firebase from "firebase";
import { firebaseConfig } from "./services/firebase/config";

firebase.initializeApp(firebaseConfig);

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#4769cc",
      light: "#0070ff",
      dark: "#4769cc"
    },
    secondary: {
      main: "#7e98e6",
      light: "#0070ff",
      dark: "rgba(170, 176, 182, 0.3)"
    },
    text: {
      primary: "#21305e",
      secondary: "#ffffff",
      disabled: "#cecece",
      hint: "#cecece"
    },
    error: {
      main: "#b71c1c"
    }
  }
});

interface AppProps {
  isAuthenticated: boolean;
}

const App: FunctionComponent<AppProps> = props => {

  const [mobileOpen, setMobileOpen] = React.useState(false);
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className="menuContentSmall">
      <CustomAppBarWithRouter />
    </div>
  );

  return (
    <MuiThemeProvider theme={appTheme}>
      <Router basename={"/dashboard"}>
        <Grid container direction="column" justify="space-between">
          <Hidden smDown>
            <AppBar position="static" color="inherit">
              <Toolbar>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <div>
                    <Grid
                      item
                      className="logoContainer"
                      container
                      direction="row"
                      alignItems="center"
                    >
                      <img
                        src={darkLogo}
                        alt="Logo"
                        width="45px"
                        height="40px"
                        className="logo"
                      />
                      <p className="baseHeader">Base</p>
                    </Grid>
                  </div>
                  <HeaderBarComponent />
                </Grid>
              </Toolbar>
            </AppBar>
            <Grid container direction="row">
              <Grid item xs={2} style={{ gridArea: "nav" }}>
                <CustomAppBarWithRouter />
              </Grid>
              <Grid item xs={10} style={{ padding: "20px" }}>
                <Switch>
                  <Route 
                    exact 
                    path="/login" 
                    component={LoginPageContainer} 
                  />
                  <ProtectedRoute
                    exact
                    path="/history"
                    component={DevelopPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/unfinished"
                    component={UnfinishedPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/statistics"
                    component={StatisticsPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/links"
                    component={LinksPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/edit/:id"
                    component={EditPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/detail"
                    component={DetailPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/impressum"
                    component={ImpressumPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/contact"
                    component={ContactPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/data"
                    component={DataPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/android"
                    component={AndroidPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/ios"
                    component={IOSPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/base"
                    component={BasePageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/company"
                    component={CompanyPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/device"
                    component={DevicePageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/website"
                    component={WebsitePageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/backend"
                    component={BackendDokuPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/breeds"
                    component={BreedPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                </Switch>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid container direction="column" className="pageHeaderSmall">
              <div className="appBarContent">
                <AppBar position="fixed" color="inherit">
                  <Toolbar>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid>
                        <IconButton
                          edge="start"
                          color="primary"
                          aria-label="open drawer"
                          onClick={handleDrawerToggle}
                        >
                          <MenuIcon color="primary" />
                        </IconButton>
                      </Grid>
                      <HeaderBarComponent />
                    </Grid>
                  </Toolbar>
                </AppBar>
                <Drawer
                  variant="temporary"
                  anchor={"left"}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </div>
              <div className="pageSmall">
                <Switch>
                  <Route exact path="/login" component={LoginPageContainer} />
                  <ProtectedRoute
                    exact
                    path="/history"
                    component={DevelopPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/unfinished"
                    component={UnfinishedPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/statistics"
                    component={StatisticsPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/links"
                    component={LinksPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/edit/:id"
                    component={EditPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/detail"
                    component={DetailPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/impressum"
                    component={ImpressumPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/contact"
                    component={ContactPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/data"
                    component={DataPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/android"
                    component={AndroidPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/ios"
                    component={IOSPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/base"
                    component={BasePageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/company"
                    component={CompanyPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/device"
                    component={DevicePageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/website"
                    component={WebsitePageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/backend"
                    component={BackendDokuPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                  <ProtectedRoute
                    path="/breeds"
                    component={BreedPageContainer}
                    isAuthenticated={props.isAuthenticated}
                  />
                </Switch>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Router>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state: State) => {
  const token = localStorage.getItem(Constants.STORAGE_AUTH_TOKEN_ACCESS);
  return {
    isAuthenticated: token !== null && token !== ""
  };
};

export const AppContainer = connect(mapStateToProps)(App);
