import React, { Component } from "react";
import { State } from "../store";
import { connect } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import "./style.css";

interface DataPageProps {}

class DataPage extends Component<DataPageProps> {
  render() {
    return (
      <Grid>
        <Typography variant="h4" style={{ fontWeight: "bold" }}>
          Datenschutz
        </Typography>
        <p>www.pezz.life | Stand: 30.11.2018</p>
        <p>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Datenschutz
          </Typography>
          <p>
            Wir, die PEZZ life GmbH, FN 475838 m, Heiligenstädter Straße 31,
            1180 Wien, Österreich (siehe Impressum), verarbeitet Ihre
            personenbezogenen Daten, wenn Sie die Anwendungen/Apps, die Hardware
            und/oder die Website - kurz die “Produkte” - nutzen. Die
            Verarbeitung der personenbezogenen Daten erfolgt in Erfüllung der
            Datenschutzgrundverordnung („DSGVO“) und der österreichischen
            Datenschutzverordnung in ihrer aktuellen Form.
          </p>
          <p>
            Unser Online Shop wird von Shopify Inc. (Shopify Inc., 126 York
            Street, Suite 200, Ottawa, ON, Kanada, K1N 5T5; + 1-888-329-0139)
            gehostet. Shopify ist die Online-Handelsplattform, mit der wir
            unsere Produkte online anbieten. Deine Daten werden auf
            Shopify-Servern gespeichert und durch eine Firewall geschützt. Beim
            Zugriff auf www.pezz.life werden folgende Zugangsdaten in Shopify
            gespeichert: Die Seite, von der aus die Datei angefordert wurde Der
            Namen der aufgerufenen Datei Datum und Uhrzeit der Anfrage Die
            übertragene Datenmenge Den Zugriffsstatus (Dateiübertragung, Datei
            nicht gefunden usw.) Die Beschreibung des Webbrowsertyps oder des
            verwendeten Browsers Betriebssysteme Die IP-Adresse des anfordernden
            Computers Diese Daten werden nur zu technischen oder statistischen
            Zwecken benötigt. Ein Vergleich mit anderen Datenbanken oder eine
            Weitergabe an Dritte, auch in Auszügen, findet nicht statt.
          </p>
          <p>
            Personenbezogene Daten Personenbezogene Daten werden von uns nur
            erhoben, wenn der Kunde dies freiwillig mitteilt. Eine solche
            Benachrichtigung erfolgt insbesondere im Rahmen einer Bestellung. Um
            eine Bestellung aufzugeben, muss du folgenden persönlichen Daten in
            ein Kontaktformular eingeben: Name Adresse E-Mail-Adresse
            Telefonnummer Wie speichern diese Daten in unserer Datenbank und
            verwendet sie ausschließlich zur individuellen Kommunikation mit
            unseren Kunden oder zur Vertragsabwicklung. Dies umfasst die
            Einleitung, den Abschluss, die Abwicklung, die Gewährleistung und
            die Rückzahlung. Die Daten werden nur bis zum Abschluss des
            Vertrages gespeichert. Soweit Handels- und Steuereinlagerungszeiten
            bestehen, kann die Lagerung bis zu 10 Jahre betragen.
          </p>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Datenübertragung
          </Typography>
          <p>
            Die Weitergabe von Kundendaten erfolgt nur in dem für den Vertrag
            erforderlichen Umfang. Eine Übertragung der Daten erfolgt
            insbesondere. In folgenden Fällen: Adressdaten an Versandunternehmen
            zur Zustellung Zahlungsdaten an Kreditinstitute oder
            Zahlungsvermittler zur Abwicklung von Zahlungen. Verantwortung
          </p>
          <p>
            Der Verantwortliche für die Verarbeitung von personenbezogenen Daten
            im Sinne der DSGVO ist PEZZ. Bei Anliegen in Hinblick auf unsere
            Datenschutzbestimmungen können Sie uns per E-Mail (help@pezz.com)
            kontaktieren.
          </p>
          <p>
            Unseren Datenschutzbeauftragten im Sinne der DSGVO können Sie per
            E-Mail kontaktieren. Sollten Sie Fragen bzgl. der Verarbeitung Ihrer
            personenbezogenen Daten haben, wenden Sie sich bitte vertrauensvoll
            an unseren Datenschutzbeauftragten.
          </p>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Datenweitergabe zur Vertragserfüllung
          </Typography>
          <p>
            Zur Vertragserfüllung geben wir Ihre Daten an das mit der Lieferung
            beauftragte Versandunternehmen weiter, soweit dies zur Lieferung
            bestellter Waren erforderlich ist. Je nach dem, welchen
            Zahlungsdienstleister Sie im Bestellprozess auswählen, geben wir zur
            Abwicklung von Zahlungen die hierfür erhobenen Zahlungsdaten an das
            mit der Zahlung beauftragte Kreditinstitut und ggf. von uns
            beauftragte Zahlungsdienstleister weiter bzw. an den ausgewählten
            Zahlungsdienst. Zum Teil erheben die ausgewählten
            Zahlungsdienstleister diese Daten auch selbst, soweit Sie dort ein
            Konto anlegen. In diesem Fall müssen Sie sich im Bestellprozess mit
            Ihren Zugangsdaten bei dem Zahlungsdienstleister anmelden. Es gilt
            insoweit die Datenschutzerklärung des jeweiligen
            Zahlungsdienstleisters.
          </p>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Datenverwendung bei Anmeldung zum E-Mail-Newsletter
          </Typography>
          <p>
            Wenn Sie sich zu unserem Newsletter anmelden, verwenden wir die
            hierfür erforderlichen oder gesondert von Ihnen mitgeteilten Daten,
            um Ihnen regelmäßig unseren E-Mail-Newsletter entsprechend Ihrer
            Einwilligung zuzusenden. Die Abmeldung vom Newsletter ist jederzeit
            möglich und kann entweder durch eine Nachricht an die unten
            beschriebene Kontaktmöglichkeit oder über einen dafür vorgesehenen
            Link im Newsletter erfolgen.
          </p>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Verwendung von Cookies
          </Typography>
          <p>
            Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung
            bestimmter Funktionen zu ermöglichen, verwenden wir auf
            verschiedenen Seiten sogenannte Cookies. Hierbei handelt es sich um
            kleine Textdateien, die auf Ihrem Endgerät gespeichert werden.
            Einige der von uns verwendeten Cookies werden nach Ende der
            Browser-Sitzung, also nach Schließen Ihres Browsers, wieder gelöscht
            (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem
            Endgerät und ermöglichen uns, Ihren Browser beim nächsten Besuch
            wiederzuerkennen (persistente Cookies). Sie können Ihren Browser so
            einstellen, dass Sie über das Setzen von Cookies informiert werden
            und einzeln über deren Annahme entscheiden oder die Annahme von
            Cookies für bestimmte Fälle oder generell ausschließen. Bei der
            Nichtannahme von Cookies kann die Funktionalität unserer Website
            eingeschränkt sein.
          </p>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Einsatz von Google (Universal) Analytics zur Webanalyse
          </Typography>
          <p>
            Diese Website benutzt Google (Universal) Analytics, einen
            Webanalysedienst der Google Inc. (www.google.de). Google (Universal)
            Analytics verwendet Methoden, die eine Analyse der Benutzung der
            Website durch Sie ermöglichen, wie zum Beispiel sog. „Cookies“,
            Textdateien, die auf Ihrem Computer gespeichert werden. Die
            erzeugten Informationen über Ihre Benutzung dieser Website werden in
            der Regel an einen Server von Google in den USA übertragen und dort
            gespeichert. Durch die Aktivierung der IP-Anonymisierung auf dieser
            Webseite wird dabei die IP-Adresse vor der Übermittlung innerhalb
            der Mitgliedstaaten der Europäischen Union oder in anderen
            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
            gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen
            Server von Google in den USA übertragen und dort gekürzt. Die im
            Rahmen von Google Analytics von Ihrem Browser übermittelte
            anonymisierte IP-Adresse wird nicht mit anderen Daten von Google
            zusammengeführt. Sie können die Erfassung der durch das Cookie
            erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl.
            Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten
            durch Google verhindern, indem Sie das unter dem folgenden Link
            verfügbare Browser-Plugin herunterladen und installieren:
            https://goo.gl/xhxi25 Google+ wird betrieben von der Google Inc.,
            1600 Amphitheatre Parkway, Mountain View, CA 94043, USA („Google“).
            Eine Übersicht über die Plugins von Google und deren Aussehen finden
            Sie hier: https://goo.gl/xhrxc1 Twitter wird betrieben von der
            Twitter Inc., 1355 Market St, Suite 900, San Francisco, CA 94103.
            Eine Übersicht über die Twitter-Buttons und deren Aussehen finden
            Sie hier: https://goo.gl/wbGDai Instagram wird betrieben von der
            Instagram LLC., 1601 Willow Road, Menlo Park, CA 94025, USA
            („Instagram“). Eine Übersicht über die Instagram-Buttons und deren
            Aussehen finden Sie hier: https://goo.gl/dniN8H. Wenn Sie eine Seite
            unseres Webauftritts aufrufen, die ein solches Plugin enthält,
            stellt Ihr Browser eine direkte Verbindung zu den Servern von
            Facebook, Google, Twitter oder Instagram her. Der Inhalt des Plugins
            wird vom jeweiligen Anbieter direkt an Ihren Browser übermittelt und
            in die Seite eingebunden. Durch die Einbindung der Plugins erhalten
            die Anbieter die Information, dass Ihr Browser die entsprechende
            Seite unseres Webauftritts aufgerufen hat, auch wenn Sie kein Profil
            besitzen oder gerade nicht eingeloggt sind. Diese Information
            (einschließlich Ihrer IP-Adresse) wird von Ihrem Browser direkt an
            einen Server des jeweiligen Anbieters in die USA übermittelt und
            dort gespeichert. Sind Sie bei einem der Dienste eingeloggt, können
            die Anbieter den Besuch unserer Website Ihrem Profil auf Facebook,
            Google+, Twitter bzw. Instagram unmittelbar zuordnen. Wenn Sie mit
            den Plugins interagieren, zum Beispiel den „Gefällt mir“-, den
            „+1“-, den „Twittern“- bzw. den „Instagram“-Button betätigen, wird
            die entsprechende Information ebenfalls direkt an einen Server der
            Anbieter übermittelt und dort gespeichert. Die Informationen werden
            außerdem in dem sozialen Netzwerk, auf Ihrem Twitterbzw.
            Instagram-Account veröffentlicht und dort Ihren Kontakten angezeigt.
            Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und
            Nutzung der Daten durch die Anbieter sowie Ihre diesbezüglichen
            Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre
            entnehmen Sie bitte den Datenschutzhinweisen der Anbieter.
            Datenschutzhinweise von Facebook Datenschutzhinweise von Google
            Datenschutzhinweise von Twitter Datenschutzhinweise von Instagram
            Wenn Sie nicht möchten, dass Google, Facebook, Twitter oder
            Instagram die über unseren Webauftritt gesammelten Daten unmittelbar
            Ihrem Profil in dem jeweiligen Dienst zuordnen, müssen Sie sich vor
            Ihrem Besuch unserer Website bei dem entsprechenden Dienst
            ausloggen. Sie können das Laden der Plugins auch mit Add-Ons für
            Ihren Browser komplett verhindern, z. B. mit dem Skript-Blocker
            „NoScript“ (http://noscript.net/).
          </p>
          <Typography
            variant="h6"
            className="textStyle"
            style={{ fontWeight: "bold" }}
          >
            imeo Video Plugins
          </Typography>
          <p>
            Auf dieser Webseite werden Inhalte dritter Anbieter eingebunden.
            Diese Inhalte werden von Vimeo LLC zur Verfügung gestellt
            („Anbieter“). Vimeo wird betrieben von Vimeo LLC, 555 West 18th
            Street, New York 10011, USA („Vimeo“). Bei Videos von Vimeo, die auf
            unserer Seite eingebunden sind, ist das Trackingtool Google
            Analytics automatisch integriert. Auf die Einstellungen des
            Trackings sowie die hierüber erhobenen Analyse-Ergebnisse haben wir
            keinen Einfluss und können diese auch nicht einsehen. Daneben werden
            über die Einbettung von Vimeo-Videos bei den Webseitenbesuchern
            Web-Beacons gesetzt. Um das Setzen der Google Analytics Tracking
            Cookies zu verhindern, können Sie die Speicherung der Cookies durch
            eine entsprechende Einstellung Ihrer Browser-Software verhindern;
            wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
            gegebenenfalls nicht sämtliche Funktionen dieser Website
            vollumfänglich werden nutzen können. Sie können darüber hinaus die
            Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
            Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
            Verarbeitung dieser Daten durch Google verhindern, indem sie das
            unter dem folgenden Link verfügbare Browser-Plugin herunterladen und
            installieren: https://goo.gl/6jcNuy Alternativ zum Browser-Plugin
            können Sie Datenschutzhinweisen von Vimeo
          </p>
          <Typography
            variant="h6"
            className="textStyle"
            style={{ fontWeight: "bold" }}
          >
            Auskunftsrecht und Kontaktmöglichkeit{" "}
          </Typography>
          <p>
            Sie haben ein Recht auf unentgeltliche Auskunft über die bei uns zu
            Ihrer Person gespeicherten Daten sowie ggf. ein Recht auf
            Berichtigung, Sperrung oder Löschung dieser Daten. Bei Fragen zur
            Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten,
            bei Auskünften, Berichtigung, Sperrung oder Löschung von Daten sowie
            Widerruf erteilter Einwilligungen oder Widerspruch gegen eine
            bestimmte Datenverwendung wenden Sie sich bitte direkt an uns über
            die Kontaktdaten in unserem Impressum.
          </p>
        </p>

        <p>
          <Typography
            variant="h4"
            className="textStyle"
            style={{ fontWeight: "bold", marginTop: "20px" }}
          >
            Allgemeine Geschäftsbedingungen (AGB)
          </Typography>
          <p style={{ fontWeight: "bold" }}>
            Stand: 04.12.2018 | PEZZ life GmbH
          </p>

          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            1. Geltungsbereich
          </Typography>
          <p>
            1.1. Die Allgemeinen Geschäftsbedingungen, welche ausgehängt, sowie
            auf der Website www.pezz.life und in der mobilen App ersichtlich
            sind, gelten für sämtliche Produkte und Dienstleistungen der PEZZ
            life GmbH.
            <br /> <br/>
            1.2. Sofern nichts anderes vereinbart, gelten diese
            Geschäftsbedingungen als maßgeblicher Vertragsbestandteil des
            zwischen PEZZ life GmbH (Unternehmer), und dem Kunden
            (Verbraucher) im Sinne des § 1 KSchG, sowie anderen Unternehmern
            geschlossenen Auftrags. Verbraucher im Sinne dieser AGB ist jede
            natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die
            überwiegend weder ihrer gewerblichen noch ihrer selbständigen
            beruflichen Tätigkeit zugerechnet werden können. Unternehmer im
            Sinne dieser AGB ist eine natürliche oder juristische Person oder
            eine rechtsfähige Personengesellschaft, die bei Abschluss eines
            Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen
            beruflichen Tätigkeit handelt.
          </p>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {" "}
            2. Vertragsschluss
          </Typography>
          <p>
            2.1. Die im Online-Shop, der mobilen App oder in Verkaufsstellen des
            Verkäufers enthaltenen Produktbeschreibungen stellen keine
            verbindlichen Angebote seitens der PEZZ life GmbH dar, sondern
            dienen zur Abgabe eines verbindlichen Angebots durch den Kunden.
            <br /> <br/>
            2.2. Die Vertragssprache ist deutsch.
          </p>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {" "}
            3. Preise und Zahlungsbestimmungen
          </Typography>
          <p>
            3.1. Sofern sich aus der Produktbeschreibung der PEZZ life GmbH
            nichts anderes ergibt, ist bei den angegebenen Preisen die
            gesetzliche Umsatzsteuer von 20 Prozent enthalten. Gegebenenfalls
            zusätzlich anfallende Liefer- und Versandkosten werden in der
            jeweiligen Produktbeschreibung gesondert angegeben. Zur Zahlung
            stehen dem Kunden die Sofortüberweisung, Kreditkarte
            (Master-card/Visa), Klarna, PayPal und die Barzahlung vor Ort zu
            Verfügung. Hier sind die besonderen Vorschriften der Betreiber zu
            beachten. Andere Zahlungsarten sind nur nach Absprache mit der PEZZ
            life GmbH möglich. Die Zahlungsmöglichkeiten werden dem Kunden auch
            im Online-Shop, der mobilen App oder an den Verkaufsstellen der PEZZ
            life GmbH mitgeteilt. Ist Vorauskasse per Banküberweisung
            vereinbart, ist die Zahlung sofort nach Vertragsabschluss fällig,
            sofern die Parteien keinen späteren Fälligkeitstermin vereinbart
            haben.
            <br /> <br/>
            3.2. Sämtliche Rechnungen werden per E-Mail an den Kunden versendet.
            <br /> <br/>
            3.3. Bei Zahlungsverzug kann der Online Account des Kunden ohne
            vorhergehende Mahnung von der PEZZ life GmbH gesperrt werden.
          </p>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            4. Liefer- und Versandbedingungen
          </Typography>
          <p>
            4.1. Die Lieferung von Waren erfolgt auf dem Versandweg an die vom
            Kunden angegebene Lieferanschrift, sofern nichts anderes
            vereinbart ist. Bei der Abwicklung der Transaktion ist die in der
            Bestellabwicklung des Verkäufers angegebene Lieferanschrift
            maßgeblich. Abweichend hiervon kann bei Auswahl der Zahlungsart
            PayPal die vom Kunden zum Zeitpunkt der Bezahlung bei PayPal
            hinterlegte Lieferanschrift maßgeblich sein.
            <br /> <br/>
            4.2. Sendet das Transportunternehmen die versandte Ware an die PEZZ
            life GmbH zurück, da eine Zustellung beim Kunden nicht möglich
            war, trägt der Kunde die Kosten für den erfolglosen Versand. Dies
            gilt nicht, wenn der Kunde den Umstand, der zur Unmöglichkeit der
            Zustellung geführt hat, nicht selbst durch schwer fahrlässiges
            Handeln verursacht hat oder wenn er vorübergehend an der Annahme der
            angebotenen Leistung verhindert war, es sei denn, dass die PEZZ
            life GmbH ihm die Leistung eine angemessene Zeit vorher
            angekündigt hatte. Ferner gilt dies im Hinblick auf die Kosten für
            die Hinsendung nicht, wenn der Kunde sein Widerrufsrecht wirksam
            ausübt. Für die Rücksendekosten gilt bei wirksamer Ausübung des
            Widerrufsrechts durch den Kunden die in der Widerrufsbelehrung des
            Verkäufers hierzu getroffene Regelung. Das Produkt kann zusätzlich
            an allen Vertriebsstellen bezahlt und abgeholt werden.
            <br /> <br/>
            4.3. Der Standartversand ist im Produktpreis enthalten, Premium
            Versand kann durch Aufzahlung durch den Kunden erfolgen.
            <br /> <br/>
            4.4. Bei Lieferungen in Länder außerhalb der Europäischen Union
            können im Einzelfall weitere Kosten anfallen, die der Verkäufer
            nicht zu vertreten hat und die vom Kunden zu tragen sind. Hierzu
            zählen beispielsweise Kosten für die Geldübermittlung durch
            Kreditinstitute (z.B. Überweisungsgebühren, Wechselkursgebühren)
            oder einfuhrrechtliche Abgaben bzw. Steuern (z.B. Zölle). Solche
            Kosten können in Bezug auf die Geldübermittlung auch dann anfallen,
            wenn die Lieferung nicht in ein Land außerhalb der Europäischen
            Union erfolgt, der Kunde die Zahlung aber von einem Land außerhalb
            der Europäischen Union aus vornimmt.
          </p>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {" "}
            5. Warenlieferung
          </Typography>
          <p>
            5.1. Sämtliche gelieferten Waren bleiben bis zur vollständigen
            Erfüllung des Kaufpreises im Eigentum der PEZZ life GmbH. Die Waren
            dürfen nur benutzt oder verbraucht werden, wenn der Kaufpreis
            vollständig an die PEZZ life GmbH entrichtet worden ist.
            <br /> <br/>
            5.2. Nutzung und Gefahr gehen ab dem Zeitpunkt der Übergabe des
            vertraglich vereinbarten Lieferungsgutes auf den Kunden über.
          </p>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            6. Abonnements
          </Typography>
          <p>
            Schließt der Kunde ein Abonnement ab, erhält er ab der
            Erstbestellung im Intervall von drei Monaten jeweils einen Check.Im
            Abonnement stehen dem Kunden zwei Zahlungsmodelle zu Verfügung. Der
            Kunde hat nach Abschluss des Vertrags die Wahl jeden Check einzeln
            (vierteljährlich) oder vier Tests auf einmal zu erhalten. Der
            Vertrag endet jeweils mit Ablauf der Vertragsdauer und wird mangels
            Kündigung nach Ablauf der Vertragsdauer um ein Jahr verlängert. Der
            Vertrag ist jederzeit kündbar. Erfolgt die Kündigung bei der Zahlung
            von vier Tests nach Erhalt des ersten Tests, wird der Stückpreis
            vom Preis des Abonnements abgezogen und der Rest des eingezahlten
            Betrags refundiert. Erfolgt die Kündigung bei jährlicher Zahlung
            nach Erhalt des zweiten Check, werden die aus dem Abonnement-Vertrag
            verbleibenden zwei Tests zugesendet, folglich ohne Rückerstattung.
          </p>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            7. Datenschutz
          </Typography>
          <p>
            7.1. Der Kunde stimmt zu, dass seine persönlichen Daten, nämlich
            Name, Anschrift, Geburtsdatum und E-Mail-Adresse, sowie
            Informationen über bisherige Bestellvorgänge zum Zwecke der
            Optimierung auch künftiger Bestellvorgänge der PEZZ life GmbH
            verarbeitet und gespeichert werden. Der Kunde hat das Recht,
            jederzeit auf Antrag unentgeltlich Auskunft zu erhalten bezüglich
            der über ihn gespeicherten personenbezogenen Daten. Die Auskunft
            ist auf Verlangen des Kunden auch elektronisch zu erteilen. Ferner
            hat der Kunde ein Recht auf Berichtigung, Sperrung und Löschung
            seiner Daten im Rahmen der gesetzlichen Vorschriften.
            <br /> <br/>
            7.2. Die Verarbeitung der Daten erfolgt ausschließlich innerhalb des
            Unternehmens PEZZ life GmbH und durch ihre Dienstleister.Es wird
            ausdrücklich auf die auf der Website unter
            https://www.pezz.life/pages/regulations ersichtlichen
            Datenschutzbestimmungen verwiesen.
          </p>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            8. Force Majeure
          </Typography>
          <p>
            Die PEZZ life GmbH ist berechtigt, die Erfüllung ihrer vertraglichen
            Pflichten soweit einzustellen, wie diese Erfüllung durch Umstände
            unmöglich gemacht oder unangemessen erschwert wird, die nicht vom
            Willen der PEZZ life GmbH abhängig sind.
            <br /> <br/>
            Darunter fallen insbesondere Arbeitskonflikte und alle vom
            Parteiwillen unabhängigen Umstände, wie z.b. Brand, Krieg,
            allgemeines Mobilmachung, Aufstand usw. Dies gilt auch dann, wenn
            derartige unvorhergesehene Gründe bei Unterlieferanten eintreten.
            Die PEZZ life GmbH wird dem Vertragspartner unverzüglich eine
            Stellungnahme, über Beginn und Ursache sowie, so weit wie möglich,
            über die zu erwartenden Auswirkungen und Dauer der Verzögerung
            übermitteln.
          </p>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            9. Gewährleistungsausschluss
          </Typography>
          <p>
            9.1. Die Auswertung der Teststreifen sowie alle anderen
            Dienstleistungen, die in der mobilen App und durch Mitarbeiter der
            PEZZ life GmbH durchgeführt werden, gelten lediglich als Indikator
            und sind ohne Gewähr. Bei schlechten Lichtverhältnissen bzw.
            schlechter Bildauflösung sind die Teststreifen direkt durch
            Kontaktaufnahme durch den Kunden von Mitarbeitern der PEZZ life GmbH
            auszuwerten. Jegliche Haftung für fehlerhafte Testergebnisse und/
            oder Schäden am Gesundheitszustand der Hunde ist ausgeschlossen.
          </p>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            10. Rücktrittsrecht und Fernabsatz
          </Typography>
          <p>
            10.1. Der Verbraucher kann gemäß § 3 KSchG von einem
            Fernabsatzvertrag innerhalb der gesetzlichen Frist von 14 Tagen
            zurücktreten. Die Frist beginnt mit der Ausfolgung der Rechnung,
            die per E-Mail erfolgen kann und richtet sich nach dem KSchG und dem
            FAGG.
            <br /> <br/>
            10.2. Das Rücktrittsrecht nach § 3 KSchG steht dem Verbraucher nicht
            zu,
            <br />- wenn er selbst die geschäftliche Verbindung mit der PEZZ
            life GmbH oder deren Beauftragten zwecks Schließung dieses Vertrages
            angebahnt hat,
            <br />- wenn dem Zustandekommen des Vertrages keine Besprechungen
            zwischen den Beteiligten oder ihren Beauftragten vorangegangen sind,
            <br />-  bei Verträgen, die dem Fern- und Auswärtsgeschäfte-Gesetz
            unterliegen, oder
            <br />- bei Vertragserklärungen, die der Verbraucher in körperlicher
            Abwesenheit des Unternehmers abgegeben hat, es sei denn, dass er
            dazu vom Unternehmer gedrängt worden ist.
            <br /> <br/>
            Die Rücktrittserklärung kann formlos abgegeben werden. Sie gilt als
            rechtzeitig abgegeben, wenn sie innerhalb der Frist an die PEZZ
            life GmbH abgesendet wird.
          </p>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            11. Reklamation
          </Typography>
          <p>
            Alle Mängel der Waren müssen innerhalb von 14 Tagen nach Zustellung
            gerügt werden. Es ist das österreichische Gewährleistungsrecht
            anwendbar.
          </p>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            12. Alternative Streitbeilegung
          </Typography>
          <p>
            Es wird darauf hingewiesen, dass sich die PEZZ life GmbH freiwillig
            nach dem AStG der alternativen online Streitbeilegung, die zur
            Streitschlichten dienen soll, unterwerfen kann, sofern dies durch
            schriftliche Erklärung zum Ausdruck gebracht wird.
            <br /> <br/>
            Verbraucher haben gemäß § 19 AStG die Möglichkeit, Beschwerden an
            die Online- Streitbeilegungsplattform der EU zu richten:
            http://ec.europa.eu/odr.
          </p>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            13. Anwendbares Recht
          </Typography>
          <p>
            Der Gerichtsstand ist Wien Döbling. Es gilt ausschließlich
            österreichisches Recht. Bei Verbrauchern im Sinne des KSchG ist
            jenes Gericht zuständig, in dessen Sprengel der Wohnsitz,
            gewöhnliche Aufenthalt oder Ort der Beschäftigung des Kunden liegt.
            Wenn der Kunde seinen Wohnsitz, gewöhnlichen Aufenthalt oder Ort der
            Beschäftigung nach Vertragsabschluss ins Ausland verlegt, so bleibt
            das vorhin genannte Gericht weiterhin zuständig. Die Anwendbarkeit
            des UN-Kaufrechtes wird ausdrücklich ausgeschlossen.
          </p>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            14. Schlussbestimmungen
          </Typography>
          <p>
            Sollten einzelne Bestimmungen dieser AGB nichtig, undurchsetzbar
            und/oder ungültig sein oder werden, gilt, dass dies nicht die
            Nichtigkeit, Undurchsetzbarkeit und/oder Ungültigkeit der gesamten
            AGB bzw. des gesamten Vertrags zur Folge hat. Die Parteien
            verpflichten sich für diesen Fall, anstelle der nichtigen, undurch-
            setzbaren und/oder ungültigen Bestimmungen eine Regelung zu
            vereinbaren, die dem mit der nichtigen, undurchsetzbaren und/oder
            ungültigen Regelung verfolgten Zweck wirtschaftlich am nächsten
            kommt.
          </p>
        </p>
      </Grid>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {};
};

export const DataPageContainer = connect(mapStateToProps)(DataPage);
