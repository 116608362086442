import React, { Component } from "react";
import {
  Grid,
  IconButton,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

interface IOSDokuComponentProps {}

class IOSPage extends Component<IOSDokuComponentProps> {
  handleButtonClick() {
    window.location.replace("/dashboard/links");
  }

  render() {
    return (
      <Grid container direction="row" className="buttonContainer">
        <Grid item xs={1}>
            <IconButton
              onClick={() => this.handleButtonClick()}
              style={{ marginTop: "20px" }}>
              <ArrowBackIosRoundedIcon />
            </IconButton>
        </Grid>
        <Grid item xs={11}>
        <Typography variant="h3" style={{ marginTop: "30px" }}>
          Pezz iOS
        </Typography>
        <Typography variant="h4" style={{ marginTop: "30px" }}>
          iOS Dokumentation
        </Typography>
        <ExpansionPanel style={{ marginBottom: "25px" }}>
          <ExpansionPanelSummary>
            <Typography variant="h5" className="textStyle">
              XCode
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid direction="column">
              <a
                href="https://developer.apple.com/de/support/xcode/"
                target="_blank"
              >
                XCode Doku von Apple
              </a>

              <Typography
                variant="h6"
                className="textStyle"
                style={{ fontWeight: "bold" }}
              >
                User-Defined Settings
              </Typography>
              <p>
                You can use User-Defined Settings e.g. to use the same
                Info.plist for different targets with different display names.
                Add it in the build settings of your target (+ symbol to the
                left of the search bar), give it a name / value: DISPLAY_NAME /
                Appname for the live target DISPLAY_NAME / Appname DEV for the
                dev target You can now use $(DISPLAY_NAME) to retrieve this
                setting, if you set the CFBUNDLEDISPLAYNAME to $(DISPLAY_NAME)
                it changes automatically.
              </p>

              <Typography
                variant="h6"
                className="textStyle"
                style={{ fontWeight: "bold" }}
              >
                Info.plist preprocessing
              </Typography>
              <p>
                If you run into the problem that you need to use different tags
                (for values do this via User-Defined Settings) in the Info.plist
                you can do this via preprocessor definitions. Use Case: the live
                API is https but the provided dev API is not -> only enable
                NSExceptionAllowsInsecureHTTPLoads for the dev target.
                <ol>
                  <li>
                    Go to the build settings of your project (you need to
                    configure it for all target)
                  </li>
                  <li>Go to packaging</li>
                  <li>
                    Set your values in Info.plist Preprocessor Definitions like
                    SECURE = 1
                  </li>
                  <li>Set Preprocess Info.plist File to Yes</li>
                </ol>
                Attention: Xcode will give you this output in the overview of
                your project under Identity: Xcode failed to parse the contents
                of the Info.plist. However it builds and works.
              </p>
              <Typography
                variant="h6"
                className="textStyle"
                style={{ fontWeight: "bold" }}
              >
                Shortcuts
              </Typography>
              <p>
                Assuming you are using the default keybindings, this are some
                useful shortcuts
                <br />
                ⌥ ... Option (Alt) <br />
                ⌘ ... Command
                <br />
                ⇧ ... Shift
                <br />
                ⏎ ... Enter (Return)
                <br />
                ^ ... Ctrl
                <br />
                <br />
                Build: ⌘B
                <br />
                Run: ⌘R
                <br />
                Clean: ⌘⇧K
                <br />
                Clean Build Folder: ⌘⌥⇧K
                <br />
                Find: ⌘F
                <br />
                Find in Workspace / Project: ⌘⇧F
                <br />
                Open Quickly: ⌘⇧O (jump quickly to files, classes / structs /
                prototypes / methods / properties)
                <br />
                Show Assistant Editor: ⌘⌥⏎
                <br />
                Show Default Editor (hides assistant): ⌘⏎
                <br />
                Show, select Recently Used Files: ^2
                <br />
                Show, select project in workspace: ^4
                <br />
                Show, search, select file in current folder: ^5
                <br />
              </p>
              <Typography
                variant="h6"
                className="textStyle"
                style={{ fontWeight: "bold" }}
              >
                Docs
              </Typography>
              <p>
                Apple provides extensive Developer Documentation (Shortcut: ⌘⇧0)
                for their frameworks, use it!
                <br />
                For documenting your code: unfortunately, Apple has set the
                keybinding in Xcode to a keybinding that doesn't work on German
                keyboards. (⌘/, you have to use ⇧ to get a slash, therefore it
                does not work)
                <br />
                If you want to use shortcuts to add documentation comments you
                have to manually set them:
                <ul>
                  <li>open preferences</li>
                  <li>go to Key Binidngs</li>
                  <li>filter for Add Documentation</li>
                  <li>enter new keybindings, e.g. ^⌘7</li>
                </ul>
              </p>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel style={{ marginBottom: "25px" }}>
          <ExpansionPanelSummary>
            <Typography variant="h5" className="textStyle">
              Apple Developer
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid direction="column">
              <a href="https://developer.apple.com" target="_blank">
                Developer Support von Apple
              </a>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel style={{ marginBottom: "25px" }}>
          <ExpansionPanelSummary>
            <Typography variant="h5" className="textStyle">
              Swift
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid direction="column">
              <a href="https://developer.apple.com/swift/" target="_blank">
                Swift Doku von Apple
              </a>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel style={{ marginBottom: "25px" }}>
          <ExpansionPanelSummary>
            <Typography variant="h5" className="textStyle">
              Apple Doku
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid direction="column">
              <a
                href="https://developer.apple.com/documentation/"
                target="_blank"
              >
                Developer Doku von Apple
              </a>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel style={{ marginBottom: "25px" }}>
          <ExpansionPanelSummary>
            <Typography variant="h5" className="textStyle">
              Beta Testing
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid direction="column">
              <a href="https://developer.apple.com/testflight/" target="_blank">
                Testflight von Apple
              </a>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel style={{ marginBottom: "25px" }}>
          <ExpansionPanelSummary>
            <Typography variant="h5" className="textStyle">
              Push Notifications
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid direction="column">
              <p>
                You need APNs (Apple Push Notifications) certificates or an APNs
                key for push notifications.
              </p>
              <p>
                Many old projects use certificates, as keys were no option back
                then, but they need to be refreshed each year, APNs keys do not
                need to be changed!
                <br />
                --> use APNs keys if you do not want to create new ones yearly
              </p>
              <Typography variant="h6" className="textStyle">
                Creating an APNs keys
              </Typography>
              <p>
                You can create such a key on the{" "}
                <a href="https://developer.apple.com/account/ios/authkey/">
                  apple developer website
                </a>
                .
              </p>
              <Typography variant="h6" className="textStyle">
                Adding APNs key to firebase
              </Typography>
              <ul>
                <li>
                  Login to{" "}
                  <a href="https://console.firebase.google.com/">Firebase</a>{" "}
                </li>
                <li>select your project</li>
                <li>go to project settings -> Cloud Messaging</li>
                <li>
                  Add the APNs key, the key id can be seen on the{" "}
                  <a href="https://developer.apple.com/account/ios/authkey/">
                    apple developer website
                  </a>
                  , the prefix is basically the team id -> goto Identifiers ->
                  App IDs -> select one and copy the Prefix
                </li>
              </ul>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel style={{ marginBottom: "25px" }}>
          <ExpansionPanelSummary>
            <Typography variant="h5" className="textStyle">
              Swagger
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {" "}
            <Grid direction="column">
              <p>
                Swagger hilft das BAckend zu verstehen und die richtigen API
                anfragen abzusenden. <br />
                Es ist eine Dokumentation für alle API-Calls für die
                unterschiedlichen Bachendanbindungen:{" "}
              </p>
              <ul>
                <li>
                  <a href="https://pezz-dev.tailored-apps.com/api/v1/docs/swagger.yml">
                    DEV
                  </a>
                </li>
                <li>
                  <a href="https://pezz-stage.tailored-apps.com/api/v1/docs/swagger.yml">
                    STAGE
                  </a>
                </li>
                <li>
                  <a href="https://pezz.tailored-apps.com/api/v1/docs/swagger.yml">
                    LIVE
                  </a>
                </li>
              </ul>
              <p>
                Unter v1 findet man alle API Calls die für die App wichtig sind.
              </p>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel style={{ marginBottom: "25px" }}>
          <ExpansionPanelSummary>
            <Typography variant="h5" className="textStyle">
              Zeplin
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid direction="column">
              <p>
                In Zeplin wird das Design für die App dargestellt. Durch
                Codesnippets und Color variablen, kann man schnell und einfach
                Designvorlagen nachbauen. Damit jeder das Design einhaltet.
              </p>

              <a href="https://zpl.io/VQYYdQm">Unser iOS Design auf Zeplin</a>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        </Grid>
      </Grid>
    );
  }
}

export const IOSPageContainer = IOSPage;
