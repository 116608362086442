import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import blood from "../../assets/ic_blood.svg";
import leucozytes from "../../assets/ic_leucocytes.svg";
import ketone from "../../assets/ic_ketone.svg";
import glucose from "../../assets/ic_glucose.svg";
import spezifics from "../../assets/ic_specific_gravity.svg";
import ph from "../../assets/ic_ph.svg";
import protein from "../../assets/ic_protein.svg";
import "./style.css";
import {
  parameterArray,
  titleArray,
  analysisValueArray,
  normalValueArray
} from "../../data/Constants";

interface ParameterDetailComponentProps {
  parameterId: number;
  resultValue: string;
  resultColor: string; //1 = Green, 2 = Orange, 3 = Red
  resultNotWetted: boolean;
}

const icons: { [index: string]: React.ReactElement } = {
  0: <img src={leucozytes} className="resultIconSize" alt="leucozytes" />,
  1: <img src={spezifics} className="resultIconSize" alt="spezifics" />,
  2: <img src={ph} className="resultIconSize" alt="protein" />,
  3: <img src={protein} className="resultIconSize" alt="blood" />,
  4: <img src={glucose} className="resultIconSize" alt="ph" />,
  5: <img src={ketone} className="resultIconSize" alt="glucose" />,
  6: <img src={blood} className="resultIconSize" alt="ketone" />
};

class ParameterDetailComponent extends Component<
  ParameterDetailComponentProps
> {
  render() {
    let iconContainer;

    const greenIconContainer = (
      <Grid item xs={2} className="resultColorGreen">
        {icons[this.props.parameterId]}
      </Grid>
    );
    const orangeIconContainer = (
      <Grid item xs={2} className="resultColorOrange">
        {icons[this.props.parameterId]}
      </Grid>
    );
    const redIconContainer = (
      <Grid item xs={2} className="resultColorRed">
        {icons[this.props.parameterId]}
      </Grid>
    );
    const greyIconContainer = (
      <Grid item xs={2} className="resultColorGrey">
        {icons[this.props.parameterId]}
      </Grid>
    );

    if(this.props.resultNotWetted) iconContainer = greyIconContainer;
    else if (this.props.resultColor === "GREEN") iconContainer = greenIconContainer;
    else if (this.props.resultColor === "ORANGE") iconContainer = orangeIconContainer;
    else if (this.props.resultColor === "RED") iconContainer = redIconContainer;

    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className="resultInfoContainer"
      >
        {iconContainer}
        <Grid item xs={10} className="resultTextContainer">
          <span>
            <p className="textResult">
              <strong>{titleArray[this.props.parameterId]}</strong>
              {this.props.resultNotWetted ? "NICHT BENETZT" :
              <span>{this.props.resultValue} {parameterArray[this.props.parameterId]}</span>}
            </p>
            <p className="textSmallResult">
              <strong>Analysebereich: </strong>
              {analysisValueArray[this.props.parameterId]}
              <br />
              <strong>Normalbereich: </strong>
              {normalValueArray[this.props.parameterId]}
            </p>
          </span>
        </Grid>
      </Grid>
    );
  }
}

export default ParameterDetailComponent;
