import React, { Props, useEffect } from "react";
import { DevelopItem, CheckData } from "../data/Data";

function createData(
  id: number,
  dogId: number,
  petId: number,
  email: string,
  testPos: number,
  dogName: string,
  created: string,
  options: number,
  anamnesis: number,
  result: number
): CheckData {
  return { id, dogId, petId, email, testPos, dogName, created, options, anamnesis, result };
}

    export class Filter {
        getRows(
            listItems: DevelopItem[],
            checkBox,
            searchText: string,
            startDate,
            endDate,
            checkBoxColor,
            showResult: boolean
          ) {
            let rows: CheckData[] = [];
            let count = 0;
            listItems.map(item => {
              const rowItem = createData(
                item.info.id,
                item.info.dogId,
                item.info.petId,
                item.info.mail,
                item.info.position,
                item.info.dogName,
                item.info.created,
                3,
                item.info.anamnesisId,
                item.info.status
              );
        
              rows[count] = rowItem;
              count++;
            });
        
            //filter table for checked items from 0|1|2 Checkboxes
            if (checkBox.checked0 && checkBox.checked1 && checkBox.checked2) {
              //if all checkboxes are true return all rows
              rows = rows;
            } else if (checkBox.checked0 && !checkBox.checked1 && !checkBox.checked2) {
              //if only 0 is checked return rows with 0
              rows = rows.filter((item: CheckData) => item.testPos === 0);
            } else if (checkBox.checked0 && checkBox.checked1 && !checkBox.checked2) {
              //if 0 and 1 is checked return row with position 0 and 1
              rows = rows.filter(
                (item: CheckData) => item.testPos === 0 || item.testPos === 1
              );
            } else if (checkBox.checked0 && !checkBox.checked1 && checkBox.checked2) {
              //if 0 and 2 is checked return row with position 0 and 2
              rows = rows.filter(
                (item: CheckData) => item.testPos === 0 || item.testPos === 2
              );
            } else if (!checkBox.checked0 && checkBox.checked1 && !checkBox.checked2) {
              //if only 1 is checked return row with position 1
              rows = rows.filter((item: CheckData) => item.testPos === 1);
            } else if (!checkBox.checked0 && !checkBox.checked1 && checkBox.checked2) {
              //if only 2 is checked return row with position 2
              rows = rows.filter((item: CheckData) => item.testPos === 2);
            } else if (!checkBox.checked0 && checkBox.checked1 && checkBox.checked2) {
              //if 1 and 2 is checked return row with position 1 and 2
              rows = rows.filter(
                (item: CheckData) => item.testPos === 1 || item.testPos === 2
              );
            } else {
              rows = rows;
            }
        
            if (showResult) {
              //filter table for checked items from Green|Orange|Red Checkboxes
              if (
                checkBoxColor.checkedGreen &&
                checkBoxColor.checkedOrange &&
                checkBoxColor.checkedRed
              ) {
                //if all checkboxes are true return all rows
                rows = rows;
              } else if (
                checkBoxColor.checkedGreen &&
                !checkBoxColor.checkedOrange &&
                !checkBoxColor.checkedRed
              ) {
                //if only green is checked return rows with green
                rows = rows.filter((item: CheckData) => item.result === 1);
              } else if (
                checkBoxColor.checkedGreen &&
                checkBoxColor.checkedOrange &&
                !checkBoxColor.checkedRed
              ) {
                //if green and orange is checked return row with position green and orange
                rows = rows.filter(
                  (item: CheckData) => item.result === 1 || item.result === 2
                );
              } else if (
                checkBoxColor.checkedGreen &&
                !checkBoxColor.checkedOrange &&
                checkBoxColor.checkedRed
              ) {
                //if green and red is checked return row with position green and red
                rows = rows.filter(
                  (item: CheckData) => item.result === 1 || item.result === 3
                );
              } else if (
                !checkBoxColor.checkedGreen &&
                checkBoxColor.checkedOrange &&
                !checkBoxColor.checkedRed
              ) {
                //if only orange is checked return row with position orange
                rows = rows.filter((item: CheckData) => item.result === 2);
              } else if (
                !checkBoxColor.checkedGreen &&
                !checkBoxColor.checkedOrange &&
                checkBoxColor.checkedRed
              ) {
                //if only red is checked return row with position red
                rows = rows.filter((item: CheckData) => item.result === 3);
              } else if (
                !checkBoxColor.checkedGreen &&
                checkBoxColor.checkedOrange &&
                checkBoxColor.checkedRed
              ) {
                //if orange and red is checked return row with position orange and red
                rows = rows.filter(
                  (item: CheckData) => item.result === 2 || item.result === 3
                );
              } else {
                rows = rows;
              }
            }
        
            //filter for searchtext in mail, name, id
            if (searchText != null || searchText != "") {
              rows = rows.filter(
                (item: CheckData) =>
                  item.dogName.toLowerCase().includes(searchText.toLowerCase()) ||
                  item.email.toLowerCase().includes(searchText.toLowerCase()) ||
                  item.id.toString().includes(searchText.toLowerCase()) ||
                  item.dogId.toString().includes(searchText.toLowerCase()) ||
                  item.created.toString().includes(searchText.toLowerCase())
              );
            }
        
            return rows;
          }
    }



