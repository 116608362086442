import React, { Props, useEffect } from "react";
import { deleteItem } from "../../api";
import { Grid, InputBase, Typography } from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import emailjs from "emailjs-com";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Hidden from "@material-ui/core/Hidden";
import PDFDocumentComponent from "../PDFDocument/PDFDocument";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import CachedRoundedIcon from "@material-ui/icons/CachedRounded";
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import SearchIcon from "@material-ui/icons/Search";
import LoadComponent from "../CircleLoadingComponent/CircleLoadingComponent";
import Brightness1RoundedIcon from "@material-ui/icons/Brightness1Rounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { DevelopItem, CheckData } from "../../data/Data";
import * as FilterTable from "../../data/FilterTable";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import EmailSendIcon from "@material-ui/icons/EmailOutlined";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { Dialog, DialogActions, DialogTitle, Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import { grey } from "@material-ui/core/colors";
import one from "../../assets/TestTypeOne.svg";
import oneActive from "../../assets/TestTypeOneActive.svg";
import two from "../../assets/TestTypeTwo.svg";
import twoActive from "../../assets/TestTypeTwoActive.svg";
import zero from "../../assets/TestTypeZero.svg";
import zeroActive from "../../assets/TestTypeZeroActive.svg";
import cat from "../../assets/img_cat.svg";
import dog from "../../assets/img_dog.svg";

import "./style.css";

const CatIcon = <div>
  <img src={cat} width="50px" alt="Cat"/>
</div>

const DogIcon = <div>
  <img src={dog} width="50px" alt="Dog"/>
</div>

/*
 *
 * FILTER ROW STYLES
 *
 */

const TestCheckbox = withStyles({
  root: {
    padding: "3px",
    marginTop: "4px",
    marginBottom: "4px",
    color: grey[400],
    "&$checked": {
      color: "#40c4ff"
    }
  },
  checked: {}
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const TestCheckboxZero = withStyles({
  root: {
    padding: "3px",
    marginTop: "4px",
    marginBottom: "4px",
    color: grey[400],
    "&$checked": {
      color: "#ffffff"
    }
  },
  checked: {}
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const GreenCheckbox = withStyles({
  root: {
    padding: "3px",
    marginTop: "4px",
    marginBottom: "4px",
    color: "#a0c864"
  },
  checked: {}
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const OrangeCheckbox = withStyles({
  root: {
    padding: "3px",
    marginTop: "4px",
    marginBottom: "4px",
    color: "#f2c736"
  },
  checked: {}
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const RedCheckbox = withStyles({
  root: {
    padding: "3px",
    marginTop: "4px",
    marginBottom: "4px",
    color: "#ec6f6e"
  },
  checked: {}
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

/*
 *
 * HEADER FILTER FUNCTIONS FOR TABLE
 *
 */

function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

type Order = "asc" | "desc";

function getSorting<K extends keyof any>(
  order: Order,
  orderBy: K
): (
  a: { [key in K]: number | string },
  b: { [key in K]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

/*
 *
 * HEADER OF TABLE
 *
 */

interface HeadCell {
  id: keyof CheckData;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: "result", numeric: true, label: "Ergebnis" },
  { id: "anamnesis", numeric: true, label: "Anamnese" },
  { id: "id", numeric: true, label: "Test ID" },
  { id: "dogId", numeric: true, label: "Tier" },
  { id: "testPos", numeric: true, label: "Testposition" },
  { id: "email", numeric: false, label: "E-Mail-Adresse" },
  { id: "dogName", numeric: false, label: "Tiername" },
  { id: "created", numeric: true, label: "Datum" },
  { id: "options", numeric: true, label: "" }
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof CheckData
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  showResult: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof CheckData) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell =>
          props.showResult ? (
            <TableCell
              className="headerRow"
              key={headCell.id}
              align="center"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ) : headCell.id === "result" ? null : (
            <TableCell
              className="headerRow"
              key={headCell.id}
              align="center"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

/*
 *
 * TABLE WITH ROWS
 *
 */

interface TableProps {
  items: DevelopItem[];
  showResult: boolean;
  authToken: any;
  handleDeleteClicked: Function;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      borderRadius: "30px 30px 0 0"
    },
    tableWrapper: {
      overflowX: "auto"
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1
    }
  })
);

export default function EnhancedTable(props: TableProps) {
  //HOOKS for page
  const [showLoading, setLoading] = React.useState(false);
  //HOOKS for TableItemRows
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof CheckData>("id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [pdfReady, setPdfReady] = React.useState(false);
  const [openEmailDialog, setOpenEmailDialog] = React.useState(false);
  //HOOKS for FilterRow
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const maxDate: Date = new Date();
  const [checkBox, setCheckBox] = React.useState({
    checked0: false,
    checked1: false,
    checked2: false
  });
  const [checkBoxColor, setCheckBoxColor] = React.useState({
    checkedGreen: false,
    checkedOrange: false,
    checkedRed: false
  });
  const [searchText, setSearchText] = React.useState("");
  //HOOKS for email and delete
  const [emailItem, setEmailItem] = React.useState<DevelopItem | null>();
  const [emailAddress, setEmailAddress] = React.useState("");
  const [deleteData, setDeleteData] = React.useState({
    id: 0,
    dogName: ""
  });

  const icons: { [index: string]: React.ReactElement } = {
    0: <img src={one} width="25px" height="25px" alt="Icon One"/>,
    1: <img src={oneActive} width="25px" height="25px" alt="Icon One Active"/>,
    2: <img src={two} width="25px" height="25px" alt="Icon Two"/>,
    3: <img src={twoActive} width="25px" height="25px" alt="Icon Two Active"/>,
    4: <img src={zero} width="25px" height="25px" alt="Icon Three"/>,
    5: <img src={zeroActive} width="25px" height="25px"  alt="Icon Three Active"/>,
  };

  useEffect(() => {
    setPdfReady(false);
    setPdfReady(true);
    return () => setPdfReady(false);
  });

  const getSearchTextFromInput = e => {
    setSearchText(e.target.value);
  };

  const handleCheckBoxChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckBox({ ...checkBox, [name]: event.target.checked });
    setPage(0);
  };

  const handleCheckBoxColorChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckBoxColor({ ...checkBoxColor, [name]: event.target.checked });
    setPage(0);
  };

  //FUNCTIONS FOR ITEMROWS
  const handleSendEmailClicked = (event, item: DevelopItem, email: string) => {
    event.stopPropagation();
    setLoading(true);

    let templateParams = {
      from: "support@pezz.life",
      to: email,
      testID: item.info.id,
      leukozyten: item.result.leukocyte.status == 1 ? " negativ" : " positiv",
      leukozytenValue: item.result.leukocyte.value.toString(),
      specifics: item.result.specifics.status == 1 ? " negativ" : " positiv",
      specificsValue: item.result.specifics.value.toString(),
      ph: item.result.ph.status == 1 ? " negativ" : " positiv",
      phValue: item.result.ph.value.toString(),
      protein: item.result.protein.status == 1 ? " negativ" : " positiv",
      proteinValue: item.result.protein.value.toString(),
      glucose: item.result.glucose.status == 1 ? " negativ" : " positiv",
      glucoseValue: item.result.glucose.value.toString(),
      ketone: item.result.ketone.status == 1 ? " negativ" : " positiv",
      ketoneValue: item.result.ketone.value.toString(),
      hemoglobin: item.result.hemoglobin.status == 1 ? " negativ" : " positiv",
      hemoglobinValue: item.result.hemoglobin.value.toString(),
      dogName: item.info.dogName,
      subject: "Dein Pezz Testergebnis"
    };

    emailjs
      .send(
        "smtp_server",
        "template_a8D0yPAx",
        templateParams,
        "user_AEePfkSyX6TMZ82A7ljo2"
      )
      .then(
        response => {
          handleSendEmailDialogClose(event);
          setLoading(false);
        },
        err => {
          console.log("FAILED...", err);
        }
      );
  };

  const getDate = date => {
    const formattedDate = new Intl.DateTimeFormat("de-AT", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      second: "numeric"
    }).format(Date.parse(date));
    return formattedDate;
  };

  const getDateMobile = date => {
    const formattedDate = new Intl.DateTimeFormat("de-AT", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      second: "numeric"
    }).format(Date.parse(date));
    return formattedDate;
  };

  const handleDownloadClicked = (event, id) => {
    event.stopPropagation();
  };

  const handleDeleteClicked = (event, id: number) => {
    event.stopPropagation();
    deleteItem(id, props.authToken).then(() => {
      props.handleDeleteClicked();
    });

    setOpen(false);
  };

  const handleDialogOpen = (event, id, dogName) => {
    event.stopPropagation();
    setOpen(true);

    setDeleteData({ id, dogName });
  };

  const handleDialogClose = event => {
    event.stopPropagation();
    setOpen(false);
  };

  const handleSendEmailDialogClose = event => {
    event.stopPropagation();
    setOpenEmailDialog(false);
  };

  const handleSendEmailDialogOpen = (event, item: DevelopItem, email) => {
    event.stopPropagation();
    setOpenEmailDialog(true);

    setEmailItem(item);
    setEmailAddress(email);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof CheckData
  ) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleClick = (event, id: number) => {
    if (props.showResult) {
      //navigate to DETAIL PAGE
      window.location.replace("/dashboard/detail/" + id);
    } else {
      //navigate to EDIT PAGE
      window.location.replace("/dashboard/edit/" + id);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  var filter: FilterTable.Filter = new FilterTable.Filter();
  let rows: Array<CheckData> = filter.getRows(
    props.items,
    checkBox,
    searchText,
    startDate,
    endDate,
    checkBoxColor,
    props.showResult
  );

  return (
    <Grid className={classes.root}>
      <Hidden xsDown>
        <Paper className={classes.paper}>
          <div className={classes.tableWrapper}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              className="filter"
            >
              <Grid
                container
                item
                xs={2}
                direction="row"
                justify="flex-start"
                alignItems="center"
                className="checkBox"
              >
                {/* CHECKBOX FOR CHECK TYPE 0,1 or 2 */}
                <Typography>Testtyp: </Typography>
                <TestCheckboxZero
                  checked={checkBox.checked0}
                  icon={icons[4]}
                  checkedIcon={icons[5]}
                  onChange={handleCheckBoxChange("checked0")}
                  value="checked0"
                />
                <TestCheckbox
                  checked={checkBox.checked1}
                  icon={icons[0]}
                  checkedIcon={icons[1]}
                  onChange={handleCheckBoxChange("checked1")}
                  value="checked1"
                />
                <TestCheckbox
                  checked={checkBox.checked2}
                  icon={icons[2]}
                  checkedIcon={icons[3]}
                  onChange={handleCheckBoxChange("checked2")}
                  value="checked2"
                />
                {props.showResult ? (
                  <Typography style={{ marginLeft: "15px" }}>
                    Ergebnis:
                  </Typography>
                ) : null}
                {props.showResult ? (
                  <GreenCheckbox
                    checked={checkBoxColor.checkedGreen}
                    onChange={handleCheckBoxColorChange("checkedGreen")}
                    icon={<Brightness1RoundedIcon fontSize="large" />}
                    checkedIcon={<CancelRoundedIcon fontSize="large" />}
                    value="resultGreen"
                  />
                ) : null}
                {props.showResult ? (
                  <OrangeCheckbox
                    checked={checkBoxColor.checkedOrange}
                    onChange={handleCheckBoxColorChange("checkedOrange")}
                    icon={<Brightness1RoundedIcon fontSize="large" />}
                    checkedIcon={<CancelRoundedIcon fontSize="large" />}
                    value="resultOrange"
                  />
                ) : null}
                {props.showResult ? (
                  <RedCheckbox
                    checked={checkBoxColor.checkedRed}
                    onChange={handleCheckBoxColorChange("checkedRed")}
                    icon={<Brightness1RoundedIcon fontSize="large" />}
                    checkedIcon={<CancelRoundedIcon fontSize="large" />}
                    value="resultRed"
                  />
                ) : null}
              </Grid>
              <Grid
                container
                item
                xs={2}
                justify="space-evenly"
                className="datePickerContainer"
              ></Grid>
              <Grid
                container
                item
                xs={6}
                className="searchContainer"
                alignItems="center"
                justify="space-around"
              >
                <Grid className="searchIcon">
                  <SearchIcon />
                </Grid>
                <InputBase
                  placeholder="Suche nach Email, Hunde ID ect."
                  className="input"
                  inputProps={{ "aria-label": "search" }}
                  value={searchText}
                  onChange={getSearchTextFromInput}
                />
              </Grid>
            </Grid>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                showResult={props.showResult}
              />
              <TableBody>
                {rows.length != 0 ? (
                  stableSort(rows, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: CheckData, index: number) => {
                      const PdfItem = props.items.filter(
                        (item: DevelopItem) => item.info.id == row.id
                      );
                      return (
                        <TableRow
                          hover
                          className="row"
                          onClick={event => handleClick(event, row.id)}
                          key={row.id}
                        >
                          {/* check if results parameter are wetted */}
                          {props.showResult && row.result === 1 ? (
                            <TableCell
                              padding="none"
                              align="center"
                              className="testResultGreen"
                            ></TableCell> ) : 
                          props.showResult && row.result === 1 ? (
                            <TableCell
                              padding="none"
                              align="center"
                              className="testResultGreen"
                            ></TableCell>
                          ) : props.showResult && row.result === 2 ? (
                            <TableCell
                              padding="none"
                              align="center"
                              className="testResultOrange"
                            ></TableCell>
                          ) : props.showResult && row.result === 3 ? (
                            <TableCell
                              padding="none"
                              align="center"
                              className="testResultRed"
                            ></TableCell>
                          ) : null}
                          { row.anamnesis != null ? (
                            <TableCell
                              padding="none"
                              align="center"
                            ><CheckRoundedIcon
                              style={{
                                color: "#4769cc"
                              }}/></TableCell>
                          ) : <TableCell
                          padding="none"
                          align="center"
                        ><CloseRoundedIcon
                        style={{
                          color: "#FFF"
                        }}/></TableCell> }
                          <TableCell
                            padding="none"
                            align="center"
                            style={{ fontWeight: "bold" }}
                          > 
                            {row.id}
                          </TableCell>
                          <TableCell padding="none" align="center" style={{ fontWeight: "bold" }}>
                            {row.dogId}{row.petId == 1 ? CatIcon : DogIcon }
                          </TableCell>
                          {row.testPos === 0 ? (
                            <TableCell
                              padding="none"
                              align="center"
                              className="testPosCellZero"
                            >
                              {row.testPos}
                            </TableCell>
                          ) : null}
                          {row.testPos === 1 ? (
                            <TableCell
                              padding="none"
                              align="center"
                              className="testPosCellOne"
                            >
                              {row.testPos}
                            </TableCell>
                          ) : null}
                          {row.testPos === 2 ? (
                            <TableCell
                              padding="none"
                              align="center"
                              className="testPosCellTwo"
                            >
                              {row.testPos}
                            </TableCell>
                          ) : null}
                          <TableCell padding="none" align="center">
                            {row.email}
                          </TableCell>
                          <TableCell padding="none" align="center">
                            {row.dogName}
                          </TableCell>
                          <TableCell padding="none" align="center">
                            {getDate(row.created)}
                          </TableCell>
                          {props.showResult ? (
                            <TableCell padding="none" align="center">
                              <IconButton
                                aria-label="delete"
                                onClick={event =>
                                  handleSendEmailDialogOpen(
                                    event,
                                    PdfItem[0],
                                    row.email
                                  )
                                }
                              >
                                <EmailSendIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                onClick={event =>
                                  handleDownloadClicked(event, row.id)
                                }
                              >
                                {pdfReady ? (
                                  <PDFDownloadLink
                                    document={
                                      <PDFDocumentComponent item={PdfItem[0]} />
                                    }
                                    fileName={
                                      PdfItem[0].info.dogName.toLowerCase() +
                                      "_result_" +
                                      PdfItem[0].info.created.substring(0, 10) +
                                      ".pdf"
                                    }
                                  >
                                    {({ blob, url, loading, error }) =>
                                      loading ? (
                                        <CachedRoundedIcon
                                          style={{ color: "#b71c1c" }}
                                        />
                                      ) : error ? (
                                        <ErrorRoundedIcon
                                          style={{ color: "#b71c1c" }}
                                        />
                                      ) : (
                                        <GetAppRoundedIcon
                                          style={{
                                            color: "#4769cc"
                                          }}
                                        />
                                      )
                                    }
                                  </PDFDownloadLink>
                                ) : null}
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                onClick={event =>
                                  handleDialogOpen(event, row.id, row.dogName)
                                }
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          ) : (
                            <TableCell padding="none">
                              <IconButton
                                aria-label="delete"
                                onClick={event =>
                                  handleDialogOpen(event, row.id, row.dogName)
                                }
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell padding="none"></TableCell>
                    <TableCell padding="none"></TableCell>
                    <TableCell padding="none"></TableCell>
                    <TableCell padding="none">
                      <p className="noChecksFound">KEINE ERGEBNISSE</p>
                    </TableCell>
                    <TableCell padding="none"></TableCell>
                    <TableCell padding="none"></TableCell>
                    <TableCell padding="none"></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page"
            }}
            nextIconButtonProps={{
              "aria-label": "next page"
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
        {openEmailDialog && emailItem ? (
          <Dialog
            open={openEmailDialog}
            onClose={event => handleSendEmailDialogClose(event)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Möchtest du das Testergebnis wirklich an {emailAddress} senden?
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={event => handleSendEmailDialogClose(event)}
                color="primary"
              >
                NEIN
              </Button>
              <Button
                onClick={event =>
                  handleSendEmailClicked(event, emailItem, emailAddress)
                }
                color="primary"
              >
                <LoadComponent isLoading={showLoading} />
                JA, EMAIL SENDEN
              </Button>
            </DialogActions>
          </Dialog>
        ) : open ? (
          <Dialog
            open={true}
            onClose={event => handleDialogClose(event)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Möchtest du den Test mit der ID {deleteData.id} von{" "}
              {deleteData.dogName} wirklich löschen?
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={event => handleDialogClose(event)}
                color="primary"
              >
                NEIN
              </Button>
              <Button
                onClick={event => handleDeleteClicked(event, deleteData.id)}
                color="primary"
              >
                <LoadComponent isLoading={showLoading} />
                JA, löschen
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </Hidden>
      <Hidden mdUp>
        <Paper className={classes.paper}>
          <Grid>
            <Grid
              container
              item
              xs={12}
              className="searchContainer"
              alignItems="center"
              justify="space-around"
            >
              <Grid className="searchIcon">
                <SearchIcon />
              </Grid>
              <InputBase
                placeholder="Suche.."
                className="input"
                inputProps={{ "aria-label": "search" }}
                value={searchText}
                onChange={getSearchTextFromInput}
              />
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                {props.showResult ? (
                  <TableCell
                    padding="default"
                    align="center"
                    style={{ fontWeight: "bold" }}
                  >
                    Ergebnis
                  </TableCell>
                ) : <TableCell
                  padding="default"
                  align="center"
                  style={{ fontWeight: "bold" }}
                >
                  A
                </TableCell> }
                <TableCell
                  padding="default"
                  align="center"
                  style={{ fontWeight: "bold" }}
                >
                  ID
                </TableCell>
                <TableCell
                  padding="default"
                  align="center"
                  style={{ fontWeight: "bold" }}
                >
                  Datum
                </TableCell>
                <TableCell
                  padding="default"
                  align="center"
                  style={{ fontWeight: "bold" }}
                >
                  Email
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length != 0 ? (
                stableSort(rows, getSorting(order, orderBy))
                  .map((row: CheckData, index: number) => {
                    return (
                      <TableRow
                        hover
                        className="row"
                        onClick={event => handleClick(event, row.id)}
                        key={row.id}
                      >
                        {props.showResult && row.result === 1 ? (
                          <TableCell
                            padding="none"
                            align="center"
                            className="testResultGreen"
                          ></TableCell>
                        ) : props.showResult && row.result === 2 ? (
                          <TableCell
                            padding="none"
                            align="center"
                            className="testResultOrange"
                          ></TableCell>
                        ) : props.showResult && row.result === 3 ? (
                          <TableCell
                            padding="none"
                            align="center"
                            className="testResultRed"
                          ></TableCell>
                        ) :  row.anamnesis != null ?
                        <TableCell
                          padding="none"
                          align="center" >
                          <CheckRoundedIcon
                            style={{
                              color: "#4769cc"
                            }}/>
                        </TableCell> : 
                        <TableCell
                          padding="none"
                          align="center" >
                            <CloseRoundedIcon
                              style={{
                                color: "#FFF"
                              }}/>
                        </TableCell> }
                        <TableCell
                          padding="default"
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {row.petId == 1 ? "K" : "H"}{row.dogId} {row.id}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {getDateMobile(row.created)}
                        </TableCell>
                        <TableCell
                          padding="default"
                          align="right"
                          style={{ fontSize: "8px" }}
                        >
                          {row.email}
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                  <TableRow>
                    <TableCell padding="none"></TableCell>
                    <TableCell padding="none">
                      <p className="noChecksFound">KEINE ERGEBNISSE</p>
                    </TableCell>
                    <TableCell padding="none"></TableCell>
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </Hidden>
    </Grid>
  );
}
