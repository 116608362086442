import React, { Component } from "react";
import {
  Grid,
  IconButton,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

interface BASEDokuComponentProps {}

class BasePage extends Component<BASEDokuComponentProps> {
  handleButtonClick() {
    window.location.replace("/dashboard/links");
  }

  render() {
    return (
      <Grid container direction="row" className="buttonContainer">
        <Grid item xs={1}>
          <IconButton
            onClick={() => this.handleButtonClick()}
            style={{ marginTop: "20px" }}
          >
            <ArrowBackIosRoundedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11}>
          <Typography variant="h3" style={{ marginTop: "30px" }}>
            Pezz Base
          </Typography>
          <Typography variant="h4" style={{ marginTop: "30px" }}>
            React Dokumentation des Webprojekts Base
          </Typography>
          <p>
            Hier findest du alle wichtigen Informationen über React selbst und
            über das Projekt Base.
          </p>
          <p>
            Das Base Projekt verwendet folgende Technologien:
            <ul>
              <li>JS und Typescript als Programmiersprache</li>
              <li>React als UI Grundgerüst</li>
              <li>Redux</li>
              <li>Git als Versions-Kontroll-System</li>
              <li>npm als Node-Package-Manager</li>
              <li>Material.ui für die Grafische Unterstützung</li>
            </ul>
          </p>

          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5">Die Sprachen des Projekts</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid direction="column">
                <Typography variant="h6">JavaScript</Typography>
                <p>
                  JavaScript ist eine Skriptsprache, die ursprünglich 1995 von
                  Netscape für dynamisches HTML in Webbrowsern entwickelt wurde,
                  um Benutzerinteraktionen auszuwerten, Inhalte zu verändern,
                  nachzuladen oder zu generieren und so die Möglichkeiten von
                  HTML und CSS zu erweitern. Im Base Projekt dient sie als
                  Grundlage. Wenn man in Typescript programmieren möchte sollte
                  man JS beherschen!
                </p>
                Dokumentation:
                <ul>
                  <li>
                    <a href="https://developer.mozilla.org/de/docs/Web/JavaScript">
                      Microsoft MDN
                    </a>
                  </li>
                  <li>
                    <a href="https://developer.mozilla.org/de/docs/Learn/Getting_started_with_the_web/JavaScript_basis">
                      JS Grundlagen
                    </a>
                  </li>
                </ul>
                <Typography variant="h6">Typescript</Typography>
                <p>
                  TypeScript ist eine von Microsoft entwickelte
                  Programmiersprache, die auf den Vorschlägen zum
                  ECMAScript-6-Standard basiert. Sprachkonstrukte von
                  TypeScript, wie Klassen, Vererbung, Module und anonyme
                  Funktionen wurden auch in ECMAScript 6
                </p>
                Dokumentation:
                <ul>
                  <li>
                    <a href="https://www.typescriptlang.org/">Typescript.org</a>
                  </li>
                  <li>
                    <a href="https://www.typescriptlang.org/docs/handbook/typescript-in-5-minutes.html">
                      Typescript in 5 Minuten
                    </a>
                  </li>
                </ul>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5">React</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid direction="column">
                <p>
                  React ist eine JavaScript-Softwarebibliothek, die ein
                  Grundgerüst für die Ausgabe von User-Interface-Komponenten von
                  Webseiten zur Verfügung stellt. Komponenten werden in React
                  hierarchisch aufgebaut und können in dessen Syntax als selbst
                  definierte HTML-Tags repräsentiert werden.
                </p>
                React Dokumentation auf{" "}
                <a href="https://reactjs.org/docs/getting-started.html">
                  React.org
                </a>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5">Redux</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid direction="column">
                <p>
                  Redux ist eine quelloffene JavaScript-Bibliothek zur
                  Verwaltung von Zustandsinformationen in einer Webanwendung.
                  Sie wird in der Regel zusammen mit den Bibliotheken React oder
                  Angular für die Erstellung grafischer Benutzeroberflächen
                  verwendet.
                </p>
                Redux Dokumentation auf{" "}
                <a href="https://reactjs.org/docs/getting-started.html">
                  Redux.js.org
                </a>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5">Git</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid direction="column">
                Als Versions-Kontroll-System verwenden wir git.
                <ul>
                  <li>
                    <a href="https://git-scm.com/videos" target="_blank">
                      Video intro
                    </a>
                  </li>
                  <li>
                    <a href="https://git-scm.com/docs" target="_blank">
                      Reference docs
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://github.github.com/training-kit/downloads/github-git-cheat-sheet.pdf"
                      target="_blank"
                    >
                      Git cheat sheet
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://ndpsoftware.com/git-cheatsheet.html"
                      target="_blank"
                    >
                      Visual cheat sheet
                    </a>
                  </li>
                </ul>
                Reading material for Git
                <ul>
                  <li>Git flow</li>
                  <ul>
                    <li>
                      <a
                        href="https://www.atlassian.com/git/tutorials/comparing-workflows/gitflow-workflow"
                        target="_blank"
                      >
                        Workflow
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.innoq.com/de/blog/continuous-integration-contradicts-feature-branches/"
                        target="_blank"
                      >
                        Feature Branches
                      </a>
                    </li>
                  </ul>
                  <li>
                    Advanced
                    <ul>
                      <li>
                        <a
                          href="https://medium.com/retailmenot-engineering/better-code-review-part-one-ae3d4ff0494d"
                          target="_blank"
                        >
                          Better Code Review Part One
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://medium.com/retailmenot-engineering/better-code-review-part-two-92f17ee42c56"
                          target="_blank"
                        >
                          Better Code Review Part Two
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://medium.com/retailmenot-engineering/better-code-review-part-3-4efb568885"
                          target="_blank"
                        >
                          Better Code Review Part Three
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel style={{ marginBottom: "25px" }}>
            <ExpansionPanelSummary>
              <Typography variant="h5">Material.ui</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid direction="column">
                <p>
                  Material UI ist ein React Komponenten für schnellere und
                  einfachere Web-Entwicklung.
                </p>
                <p>Damit ist es möglich StyleComponenten einfach zu exportieren und im Projekt einzubinden.</p>
                <p>
                  Dokumentation
                  <ul>
                    <li>
                      <a
                        href="https://material-ui.com/de/getting-started/installation/"
                        target="_blank"
                      >
                        Installation
                      </a>
                    </li>
                    <li>
                      Components zb.{" "}
                      <a
                        href="https://material-ui.com/de/components/grid/"
                        target="_blank"
                      >
                        Grid
                      </a>
                    </li>
                  </ul>
                </p>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Grid>
    );
  }
}

export const BasePageContainer = BasePage;
