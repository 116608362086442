import { DevelopItem, TestValueItem } from "../data/Data";

const SET_DEVELOP_ITEMS = "SET_DEVELOP_ITEMS";
const REMOVE_DEVELOP_ITEM = "REMOVE_DEVELOP_ITEM";
const SET_TEST_VALUES = "SET_TEST_VALUES";
const SET_HISTORY_TEST_VALUES = "SET_HISTORY_TEST_VALUES";
const SET_UNFINISHED_ITEMS = "SET_UNFINISHED_ITEMS";
const REMOVE_UNFINISHED_ITEM = "REMOVE_UNFINISHED_ITEM";
const SET_TEST_VALUE_VALUE = "SET_TEST_VALUE_VALUE";
const SET_HISTORY_TEST_VALUE_VALUE = "SET_HISTORY_TEST_VALUE_VALUE";
const SET_USER_AUTHENTICATED = "SET_USER_AUTHENTICATED";

export const setDevelopItems = (items: any[]) => {
  return {
    type: SET_DEVELOP_ITEMS,
    developItems: items
  };
};

export const removeDevelopItem = (id: number) => {
  return {
    type: REMOVE_DEVELOP_ITEM,
    id
  };
};

export const setTestValue = (values: any[]) => {
  return {
    type: SET_TEST_VALUES,
    values
  };
};

export const setHistoryTestValue = (values: any[]) => {
  return {
    type: SET_HISTORY_TEST_VALUES,
    values
  };
};

export const removeUnfinisedItem = (id: number) => {
  return {
    type: REMOVE_UNFINISHED_ITEM,
    id
  };
};

export const setUnfinishedItems = (items: any[]) => {
  return {
    type: SET_UNFINISHED_ITEMS,
    unfinishedItems: items
  };
};

export const setTestValueValue = (id: number, name: string, value: string) => {
  return {
    type: SET_TEST_VALUE_VALUE,
    id,
    name,
    value
  };
};

export const setHistoryTestValueValue = (id: number, name: string, value: string) => {
  return {
    type: SET_HISTORY_TEST_VALUE_VALUE,
    id,
    name,
    value
  };
};

export const setUserAuthenticated = (
  isAuthenticated: boolean,
  authToken: any
) => {
  return {
    type: SET_USER_AUTHENTICATED,
    isAuthenticated,
    authToken
  };
};

export interface State {
  developItems: DevelopItem[];
  testValues: TestValueItem[];
  historyTestValues: TestValueItem[]
  unfinishedItems: DevelopItem[];
  testResults: Record<number, Record<string, string>>;
  historyTestResults: Record<number, Record<string, string>>;
  isAuthenticated: boolean;
  authToken: Object;
}

const initialState: State = {
  developItems: [],
  testValues: [],
  historyTestValues: [],
  unfinishedItems: [],
  testResults: {},
  historyTestResults: {},
  isAuthenticated: false,
  authToken: {}
};

export function appReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case SET_DEVELOP_ITEMS: {
      let historyTestResults: any = {};
      if(action.developItems != null){
      action.developItems.forEach((item: DevelopItem) => {
        historyTestResults[item.info.id] = {};
      });
      }
      return {
        ...state,
        developItems: action.developItems,
        historyTestResults
      };
    }
    case REMOVE_DEVELOP_ITEM: {
      return {
        ...state,
        developItems: state.developItems.filter(
          item => item.info.id !== action.id
        )
      };
    }
    case SET_TEST_VALUES: {
      return {
        ...state,
        testValues: action.values
      };
    }
    case SET_HISTORY_TEST_VALUES: {
      return {
        ...state,
        historyTestValues: action.values
      };
    }
    case SET_UNFINISHED_ITEMS: {
      let testResults: any = {};
      if(action.unfinishedItems != null) {
        action.unfinishedItems.forEach((item: DevelopItem) => {
          testResults[item.info.id] = {};
        });
      }

      return {
        ...state,
        unfinishedItems: action.unfinishedItems,
        testResults
      };
    }
    case REMOVE_UNFINISHED_ITEM: {
      return {
        ...state,
        unfinishedItems: state.unfinishedItems.filter(
          item => item.info.id !== action.id
        )
      };
    }
    case SET_TEST_VALUE_VALUE: {
      return {
        ...state,
        testResults: {
          ...state.testResults,
          [action.id]: {
            ...state.testResults[action.id],
            [action.name]: action.value
          }
        }
      };
    }
    case SET_HISTORY_TEST_VALUE_VALUE: {
      return {
        ...state,
        historyTestResults: {
          ...state.historyTestResults,
          [action.id]: {
            ...state.historyTestResults[action.id],
            [action.name]: action.value
          }
        }
      };
    }
    case SET_USER_AUTHENTICATED: {
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        authToken: action.authToken
      };
    }
    default:
      return state;
  }
}
