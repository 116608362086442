import React, { Component } from "react";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import { DogInfoComponent } from "../components/DogInfoComponent/DogInfoComponent";
import StopRoundedIcon from "@material-ui/icons/StopRounded";
import ParameterStepperComponent from "../components/ParameterStepperComponent/ParameterStepperComponent";
import EditPageSkeletonComponent from "../components/LoadComponents/EditPageSkeleton";
import { removeUnfinisedItem, State } from "../store";
import { connect } from "react-redux";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HelpIcon from '@material-ui/icons/Help';
import * as Helper from "../components/ParameterStepperComponent/helper";
import {
  deleteItem,
  fetchAnamnesisItems,
  fetchSpecificItem,
  fetchAIResultImage
} from "../api";
import { DevelopItem, TestValueItem, AnamnesisData, AIResult } from "../data/Data";
import {} from "../api";
import {
  Grid,
  Typography,
  Button,
  Hidden,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import AnamnesisInfoComponent from "../components/AnamnesisInfoComponent/AnamnesisInfoComponent";
import "./style.css";
import dogSmall from "../assets/img_dog_small.svg";
import catSmall from "../assets/img_cat_small.svg";
import { imageLibraryNames, titleArray, trashholdValue } from "../data/Constants";

interface EditPageProps {
  items: DevelopItem[];
  testValues: TestValueItem[];
  authToken: any;
  dispatch: any;
}

class EditPage extends Component<EditPageProps> {
  state = {
    id: 0,
    limit: 10000,
    offset: 0,
    itemToEdit: [],
    aiImage: [],
    aiResult: [],
    anamnesisData: {
      id: 0,
      created: "",
      updated: "",
      resultId: 1,
      changesBehavior: 1,
      drinksMore: 1,
      drinksLess: 1, 
      peesMoreOften: 1,
      peesMore: 1, 
      urineDarker: 1, 
      eatsLess: 1,
      losesWeight: 1,
      licksBellyBald: 1,
      anamnesisState: 1,
    }
  };

  getItemToEdit(id: number) {
    console.log('fetchSpecificItem');
    const helper = new Helper.Helper();
    fetchSpecificItem(id)
      .then(item => {

        this.setState({
          itemToEdit: item.items
        });

        imageLibraryNames.map((param, index) => {
          console.log(item.items[0].image);
          console.log(index);
          const croppedImageUrl = helper.getCroppedImageUrl(index, item.items[0].image);
          console.log(croppedImageUrl);
        
          fetchAIResultImage(croppedImageUrl, param)
            .then(response => response.json())
            .then(result => {
              // console.log(result);
              // console.log(croppedImageUrl);
              if(result.code !== "BadRequestImageUrl") {
                this.setState({
                  aiImage: [...this.state.aiImage, croppedImageUrl],
                  aiResult: [...this.state.aiResult, result]
                });
                console.log(this.state.aiResult);
                console.log(this.state.aiImage);
              } else {

              console.log("empty!!!!!!");
              }
            })
            .catch(error => {
              console.log('error', error);
            });
        })

        if(item.items[0].info.anamnesisId != null) {
          fetchAnamnesisItems(item.items[0].info.anamnesisId, item.items[0].info.petId)
            .then(anamnesis => {
              this.setState({
                anamnesisData: {
                  id: anamnesis.id,
                  created: anamnesis.created,
                  updated: anamnesis.updated,
                  resultId: anamnesis.resultId,
                  changesBehavior: anamnesis.changesBehavior, 
                  drinksMore: anamnesis.drinksMore, 
                  drinksLess: anamnesis.drinksLess, 
                  peesMoreOften: anamnesis.peesMoreOften, 
                  peesMore: anamnesis.peesMore, 
                  urineDarker: anamnesis.urineDarker, 
                  eatsLess: anamnesis.eatsLess, 
                  losesWeight: anamnesis.losesWeight, 
                  licksBellyBald: anamnesis.licksBellyBald,
                  anamnesisState: anamnesis.anamnesisState,
                }
              });
            })    
        }
      })
      .catch(error => {
        console.log("error: ", error);
      });
  }

  componentDidMount(): void {
    const splitPathname = window.location.pathname.split("/", 5);

    this.setState({
      id: splitPathname[3]
    });

    const id = parseInt(splitPathname[3]);
    this.getItemToEdit(id);
  }

  handleDeleteClicked = (id: number) => {
    const { dispatch, authToken } = this.props;
    deleteItem(id, authToken)
      .then(() => {
        dispatch(removeUnfinisedItem(id));
      })
      .then(() => {
        window.location.replace("/dashboard/unfinished");
      });
  };

  render() {
    const item: DevelopItem = this.state.itemToEdit[0];
    const anamnesis: AnamnesisData =  this.state.anamnesisData;
    const aiResult: AIResult[] = this.state.aiResult;
    console.log(aiResult);

    return (this.state.itemToEdit.length !== 0 ? (
      <Grid>
        <p>{this.props.children}</p>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={6} style={{flexDirection:"column"}}>
            <Typography component="p">
              <Hidden smDown>
                <span className="text">Test ID: {item.info.id}</span> <br />
              {item.info.anamnesisId !== null ? <span className="text">Anamnesis ID: {item.info.anamnesisId}</span>: null }
                <span className="detailText">
                  Noch nicht manuell ausgewertet
                </span><br/>
                <span className="text">Phone: {item.result.device}</span><br/>
                <span className="text">OS: {item.result.os}</span>
              </Hidden>
              <Hidden mdUp>
                <Grid
                  container
                  direction="row" >
                  <Typography component="p">
                    <span className="text">Test ID: {item.info.id}</span><br/>
                    <span className="text">Anamnesis ID: {item.info.anamnesisId}</span><br/>
                    <span className="text">Phone: {item.result.device}</span><br/>
                    <span className="text">OS: {item.result.os}</span>
                  </Typography>
                </Grid>
              </Hidden>
            </Typography>
          </Grid>
          <Hidden smDown>
            <Grid
              item
              xs={4}
              style={{flexDirection:"row", justifyContent:"flex-end", alignItems:"baseline"}}
            >
              <span>Ergebnis: </span>
              <StopRoundedIcon
                style={{ color: "lightgray", padding: "3px" }}
                className="resultNeutral"
              />
              <span className="italicStyleText">nicht ausgewertet</span>
            </Grid>
          </Hidden>
          <Grid item xs={2} style={{flexDirection:"row", justifyContent:"flex-end"}}>
            {item.info.petId === 1 ? <img width="50px" height="50px" src={catSmall} alt="Cat"/> : <img width="50px" height="50px" src={dogSmall} alt="Dog"/> }
            <Button
              size="small"
              onClick={() => {
                this.handleDeleteClicked(item.info.id);
              }}
            >
              <DeleteIcon />
            </Button>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <DogInfoComponent info={item.info} />
              {item.info.anamnesisId !== null ? 
                <Grid container direction="row">
                  <Grid item xs={5} style={{ margin: "20px" }}>
                    <ExpansionPanel className="infoCard">
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        {/* check for status of anamnesis */}
                        {anamnesis.anamnesisState === 1 ? 
                          <Grid 
                            className="anamnesisDott" 
                            style={{backgroundColor: "#a0c864"}}></Grid> :
                          <Grid 
                            className="anamnesisDott" 
                            style={{backgroundColor: "#ec6f6e"}}></Grid>}
                        
                        <Typography className="boldTextInfos">
                          Anamnesedaten über {item.info.dogName}
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        {/* get real anamnesis data */}
                        <AnamnesisInfoComponent
                          anamnesis={anamnesis}
                          petId={item.info.petId} />
                      </ExpansionPanelDetails>
                    </ExpansionPanel> 
                  </Grid> 
                  <Grid item xs={5}>
                    <Grid>
                      <img
                        src={item.image}
                        alt="Check Result"
                        width="100%"
                        style={{ margin: "20px" }}
                      />
                    </Grid> 
                  </Grid> 
                </Grid> 
                :  
                <Grid container direction="row">
                  <Grid item xs={5} style={{marginTop: "20px", marginBottom: "20px"}}>
                    <ErrorRoundedIcon style={{ color: "#b71c1c" }} />
                    <strong>keine Anamnese Daten!</strong>
                  </Grid>  
                  <Grid item xs={5}>
                    <Grid>
                      <img
                        src={item.image}
                        alt="Check Result"
                        width="100%"
                        style={{ margin: "20px" }}
                      />
                    </Grid> 
                  </Grid> 
                </Grid>
              }
            <ParameterStepperComponent
              props={this.props}
              preResults={item.result}
              unfinishedItem={item.info.develop === "9"}
              id={item.info.id}
              image={item.image}
              onDeleteClick={() => {
                this.handleDeleteClicked(item.info.id);
              }}
            />
          </Grid>
          <Grid container style={{display:"flex", flexDirection:"row", justifyContent:"center", margin: "10px" }}>
          {this.state.aiImage.length !== 0 ? this.state.aiImage.map( (image, i) => {
            const value = i.toString().concat(".jpeg");
            const nextImage = (element) => element.includes(value);
              return (
                <Grid container style={{display:"flex", flexDirection:"column", justifyContent:"center", width:"14%"}}>
                  <Typography>{titleArray[i]}</Typography>
                  <img
                    src={this.state.aiImage[this.state.aiImage.findIndex(nextImage)]}
                    alt="AI Result"
                    width="100%"
                    style={{ margin: "0px", width:"30px", height:"30px" }}
                  />
                  <Grid style={{width:"30%", justifyContent:"center", textAlign:"center"}}>
                    {aiResult[i].predictions[0].tagName.includes('pos') ? <Typography style={{color: "red", fontWeight: 600}}>Positiv</Typography> : <Typography  style={{color: "green", fontWeight: 600}}>Negativ</Typography>}
                    <Typography>{(aiResult[i].predictions[0].probability * 100).toLocaleString(undefined, {maximumFractionDigits:4})}%</Typography>
                    {aiResult[i].predictions[0].probability >= trashholdValue[i] ? <CheckCircleIcon style={{ color: "green", padding: "3px" }} /> : <HelpIcon style={{ color: "grey", padding: "3px" }} /> }
                  </Grid>
                </Grid>
              )
          }) : <Grid item xs={5} style={{marginTop: "20px", marginBottom: "20px"}}>
          <ErrorRoundedIcon style={{ color: "#b71c1c" }} />
          <strong>KEINE AUTOMATISIERTE AUSWERTUNG VORHANDEN!</strong>
        </Grid>   }
              </Grid>
        </Hidden>
        <Hidden mdUp>
          <ExpansionPanel className="infoCard">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className="boldTextInfos">
                Alle Infos über {item.info.dogName}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <DogInfoComponent info={item.info} />
            </ExpansionPanelDetails>
          </ExpansionPanel>

          { typeof anamnesis !== 'undefined' && item.info.anamnesisId != null ? 
            <ExpansionPanel className="infoCard">
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {/* check for status of anamnesis */}
                {anamnesis.anamnesisState === 1 ? 
                  <Grid 
                    className="anamnesisDott" 
                    style={{backgroundColor: "#a0c864"}}></Grid> :
                  <Grid 
                    className="anamnesisDott" 
                    style={{backgroundColor: "#ec6f6e"}}></Grid>}
                
                <Typography className="boldTextInfos">
                  Anamnesedaten über {item.info.dogName}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {/* get real anamnesis data */}
                <AnamnesisInfoComponent
                  anamnesis={this.state.anamnesisData}
                  petId={item.info.petId} />
              </ExpansionPanelDetails>
            </ExpansionPanel> 
          : null }

          <Grid item xs={12}>
            <img
              src={item.image}
              alt="Check Result"
              width="100%" />
          </Grid>

          <Grid item xs={12}>
            <ParameterStepperComponent
              props={this.props}
              preResults={item.result}
              unfinishedItem={item.info.develop === "9"}
              id={item.info.id}
              image={item.image}
              onDeleteClick={() => this.handleDeleteClicked(item.info.id)}
            />
          </Grid>
        </Hidden>
      </Grid>
    ) : (
      <EditPageSkeletonComponent />
    ));
  }
}

const mapStateToProps = (state: State) => {
  return {
    items: state.unfinishedItems,
    testValues: state.testValues,
    authToken: state.authToken
  };
};

export const EditPageContainer = connect(mapStateToProps)(EditPage);
