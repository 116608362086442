import React, { Component } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import team from "../../../assets/team.jpeg";
import charles01 from "../../../assets/charles01.jpg";
import charles02 from "../../../assets/charles02.jpg";
import charles03 from "../../../assets/charles03.jpg";
import charles04 from "../../../assets/charles04.jpg";
import charles05 from "../../../assets/charles05.jpg";
import charles06 from "../../../assets/charles06.jpg";
import charles07 from "../../../assets/charles07.jpg";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import "./style.css";

interface CompanyPageComponentProps {}

const StyledButton = withStyles({
  root: {
    width: "45%",
    background: "#4769cc",
    borderRadius: "20px",
    border: 0,
    margin: "10px",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "50px",
    boxShadow: "0px 20px 20px 0px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      background: "#FFF",
      color: "#4769cc"
    },
    "&:focus": {
      background: "#FFF",
      color: "#4769cc"
    }
  },
  label: {
    textTransform: "capitalize"
  }
})(Button);

class CompanyPage extends Component<CompanyPageComponentProps> {
  state = {
    showIntro: true,
    showTeam: false,
    showHistory: false
  };

  handleButtonClick(links, intro, team, history) {
    if (links) window.location.replace("/dashboard/links");
    else {
      this.setState({
        showIntro: intro,
        showTeam: team,
        showHistory: history
      });
    }
  }

  render() {
    return (
      <Grid container direction="row" className="buttonContainer">
        {this.state.showIntro ? (
          <Grid container>
            <Grid item xs={1}>
              <IconButton
                onClick={() =>
                  this.handleButtonClick(true, false, false, false)
                }
              >
                <ArrowBackIosRoundedIcon />
              </IconButton>
            </Grid>
            <Grid item xs={11}>
              <Typography variant="h3" className="textStyle" gutterBottom>
                Pezz life GmbH
              </Typography>
              <img src={team} alt="Pezz Team" />
              <Typography variant="h5" className="textStyle" gutterBottom>
                Worüber möchtest du gerne mehr erfahren?
              </Typography>
              <Grid
                direction="row"
                justify="center"
                alignItems="center"
                className="buttonContainer"
              >
                <StyledButton
                  onClick={() =>
                    this.handleButtonClick(false, false, true, false)
                  }
                >
                  Pezz Team
                </StyledButton>
                <StyledButton
                  onClick={() =>
                    this.handleButtonClick(false, false, false, true)
                  }
                >
                  Pezz Geschichte
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
        ) : this.state.showTeam ? (
          <Grid container>
            <Grid item xs={1}>
              <IconButton
                onClick={() =>
                  this.handleButtonClick(false, true, false, false)
                }
              >
                <ArrowBackIosRoundedIcon />
              </IconButton>
            </Grid>
            <Grid item xs={11}>
              <Grid container direction="column">
                <Grid
                  container
                  direction="row"
                  justify="space-around"
                  alignItems="stretch"
                >
                  <Card className="teamCard">
                    <CardContent>
                      <Grid direction="column" style={{ width: "100%" }}>
                        <img src={charles01} width="100%" height="80%" alt="Markus"/>
                        <Typography variant="h6">Markus Zengerer</Typography>
                        <ul>
                          <li>CEO von Pezz</li>
                          <li>hat Pezz 2017 gegründet</li>
                          <li>ist für Operative und Management zuständig</li>
                        </ul>
                      </Grid>
                    </CardContent>
                  </Card>

                  <Card className="teamCard">
                    <CardContent>
                      <Grid direction="column" style={{ width: "100%" }}>
                        <img src={charles07} width="100%" height="80%" alt="Lukas"/>
                        <Typography variant="h6">Lukas Gisch</Typography>
                        <ul>
                          <li>ist auf Sonja allergisch</li>
                          <li>ist für den Verkauf zuständig</li>
                        </ul>
                      </Grid>
                    </CardContent>
                  </Card>

                  <Card className="teamCard">
                    <CardContent>
                      <Grid direction="column" style={{ width: "100%" }}>
                        <img src={charles02} width="100%" height="80%" alt="Marlene"/>
                        <Typography variant="h6">Marlene Strasser</Typography>
                        <ul>
                          <li>ist für iOS und derzeit fürs Backend zuständig</li>
                          <li>Katzenliebhaberin</li>
                        </ul>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="space-around"
                  alignItems="stretch"
                >
                  <Card className="teamCard">
                    <CardContent>
                      <Grid direction="column" style={{ width: "100%" }}>
                        <img src={charles06} width="100%" height="80%" alt="Anna"/>
                        <Typography variant="h6">Anna Kutschka</Typography>
                        <ul>
                          <li>ist für Android und Web zuständig</li>
                          <li>Hundeliebhaberin</li>
                        </ul>
                      </Grid>
                    </CardContent>
                  </Card>

                  <Card className="teamCard">
                    <CardContent>
                      <Grid direction="column" style={{ width: "100%" }}>
                        <img src={charles04} width="100%" height="80%" alt="Marcel"/>
                        <Typography variant="h6">Marcel Arthner</Typography>
                        <ul>
                          <li>ist für das Marketing zuständig</li>
                          <li>Hundeliebhaber</li>
                          <li>Hundepapa von Sonja</li>
                        </ul>
                      </Grid>
                    </CardContent>
                  </Card>

                  <Card className="teamCard">
                    <CardContent>
                      <Grid direction="column" style={{ width: "100%" }}>
                        <img src={charles05} width="100%" height="80%" alt="Nina"/>
                        <Typography variant="h6">Janina Rauch</Typography>
                        <ul>
                          <li>ist die Vet beautragte bei Pezz</li>
                          <li>Hundeliebhaberin</li>
                          <li>Hundemama von happy Hippy</li>
                        </ul>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="space-around"
                  alignItems="stretch"
                >
                  <Card className="teamCard">
                    <CardContent>
                      <Grid direction="column" style={{ width: "100%" }}>
                        <img src={charles03} width="100%" height="80%" alt="Marketing"/>
                        <Typography variant="h6"></Typography>
                        <ul>
                          <li> ist Marketingbeauftragter bei Pezz </li>
                        </ul>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : this.state.showHistory ? (
          <Grid container>
            <Grid item xs={1}>
              <IconButton
                onClick={() =>
                  this.handleButtonClick(false, true, false, false)
                }
              >
                <ArrowBackIosRoundedIcon />
              </IconButton>
            </Grid>
            <Grid item xs={11}>
              <Typography variant="h3" className="textStyle" gutterBottom>
                Die Geschichte von Pezz
              </Typography>
              <Typography variant="h5" className="textStyle" gutterBottom>
                Alles begann im Jahre 2017..
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    );
  }
}

export const CompanyPageContainer = CompanyPage;
