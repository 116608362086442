import React, { Component } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import { connect } from "react-redux";
import { RouterProps } from "react-router";
import { Formik } from "formik";
import * as Yup from "yup";
import { authenticateUser } from "../api";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Constants } from "../data/Constants";
import firebase from "firebase";
import "./styles.css";

interface LoginPageProps {
  dispatch: any;
}

class LoginPage extends Component<LoginPageProps & RouterProps> {
  firebaseLogin = (email, password) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        this.props.history.push("/");
        window.location.href = "/dashboard/unfinished";
      })
      .catch(function(error) {
        // Handle Errors here.
        var errorMessage = error.message;
        console.log("error sign in with firebase: ", errorMessage);
      });
  };

  onSubmit = (values, { setSubmitting, setFieldError }) => {
    authenticateUser(values.username, values.password)
      .then(value => {
        localStorage.setItem(
          Constants.STORAGE_AUTH_TOKEN_ACCESS,
          value.access_token
        );
        localStorage.setItem(
          Constants.STORAGE_AUTH_TOKEN_REFRESH,
          value.refresh_token
        );
        this.firebaseLogin(values.username, values.password);
      })
      .catch((error: string) => {
        setFieldError("password", error);
      });
    setSubmitting(false);
  };

  render() {
    return (
      <Grid container direction="row" justify="center">
        <Grid item xs={10}>
          <Card className="loginCard">
            <CardContent>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item xs>
                  <Typography variant="h5">Sign In</Typography>
                </Grid>
                <Formik
                  initialValues={{ username: "", password: "" }}
                  onSubmit={this.onSubmit}
                  validateOnBlur={false}
                  validateOnChange={false}
                  validationSchema={Yup.object().shape({
                    username: Yup.string().required("Required"),
                    password: Yup.string().required("Required")
                  })}
                >
                  {props => {
                    const {
                      values,
                      errors,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Grid item xs={12}>
                          <TextField
                            id="username"
                            value={values.username}
                            label="Username"
                            name="username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.username}
                            margin="normal"
                            variant="outlined"
                            fullWidth={true}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="password"
                            label="Password"
                            value={values.password}
                            type="password"
                            name="password"
                            autoComplete="current-password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.password}
                            margin="normal"
                            variant="outlined"
                            fullWidth={true}
                          />
                          {errors.password &&
                            errors.password !== "Required" && (
                              <FormHelperText id="error-text">
                                Passwort stimmt nicht 
                                {/* {errors.password} */}
                              </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className="button-login-margin"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Sign in
                          </Button>
                        </Grid>
                      </form>
                    );
                  }}
                </Formik>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export const LoginPageContainer = connect(mapStateToProps)(LoginPage);
