import React, { Component } from "react";
import { Grid, Hidden } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

interface EditPageSkeletonComponentProps {}

class EditPageSkeletonComponent extends Component<
  EditPageSkeletonComponentProps
> {
  render() {
    return (
      <Grid>
        <Hidden smDown>
          <Grid container style={{ width: "100%" }}>
            <Skeleton
              variant={"rect"}
              width="100%"
              height={60}
              style={{ marginBottom: 10, marginTop: 20 }}
            />

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ marginBottom: 30 }}
            >
              <Grid item xs={3}>
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
              </Grid>
            </Grid>

            <Skeleton
              variant={"rect"}
              width="100%"
              height={70}
              style={{ marginBottom: 20 }}
            />

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ marginBottom: 20 }}
            >
              <Skeleton
                variant={"rect"}
                width="80%"
                height={300}
                style={{ marginBottom: 10 }}
              />
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    );
  }
}

export default EditPageSkeletonComponent;
